import { MetricName } from "@cur8/measurements";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { queryMetrics } from "lib/api/metrics";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";

function queryKey(metric: (typeof metrics)[number]) {
  return ["report-metrics", metric];
}

const metrics = [
  "heartRate",
  "weight",
  "height",
  "brachialPressure",
  "glucose",
  "hba1c",
  "crp",
  "basophils",
  "eosinophils",
  "neutrophils",
  "haemoglobin",
  "whiteBloodCellsCount",
  "lymphocytes",
  "cholesterol",
  "triglycerides",
  "hdl",
  "nonHdl",
  "eyePressure",
  "gripStrength",
  "bloodOxygen",
  "anklePressure",
  "ldl",
  "skinLesionCount",
  "smoking",
] as const;

function fetchMetric<T extends MetricName>({
  metricName,
  api,
  patientId,
  signal,
}: {
  metricName: T;
  api: APIClient;
  patientId: string;
  signal?: AbortSignal;
}) {
  return queryMetrics(api.measurement, { metricName, patientId }, { signal });
}

export function useHeartRateMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("heartRate"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "cardio.heart_rate",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useWeightMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("weight"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "body.weight",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useHeightMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("height"),
    queryFn: ({ signal }) =>
      fetchMetric({ metricName: "body.height", api, patientId, signal }),
    staleTime: Infinity,
  });
}

export function useBrachialPressureMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("brachialPressure"),
    queryFn: async ({ signal }) => {
      const left = await fetchMetric({
        metricName: "cardio.brachial_pressure.left",
        api,
        patientId,
        signal,
      });
      const right = await fetchMetric({
        metricName: "cardio.brachial_pressure.right",
        api,
        patientId,
        signal,
      });
      return {
        left,
        right,
      };
    },
    staleTime: Infinity,
  });
}

export function useGlucoseMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("glucose"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.glucose",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useHba1cMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("hba1c"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.hba1c",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useCrpMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("crp"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.crp",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useBasophilsMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("basophils"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.white_blood_cells.basophils",
        api,
        patientId,
        signal,
      }),

    staleTime: Infinity,
  });
}

export function useEosinophilsMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("eosinophils"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.white_blood_cells.eosinophils",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useNeutrophilsMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("neutrophils"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.white_blood_cells.neutrophils",
        api,
        patientId,
        signal,
      }),

    staleTime: Infinity,
  });
}

export function useHaemoglobinMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("haemoglobin"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.haemoglobin",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useWhiteBloodCellsCountMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("whiteBloodCellsCount"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.white_blood_cells.total",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useLymphocytesMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("lymphocytes"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.white_blood_cells.lymphocytes",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useCholesterolMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("cholesterol"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.cholesterol",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useTriglyceridesMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("triglycerides"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.triglycerides",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useHdlMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("hdl"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.hdl",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useNonHdlMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("nonHdl"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.non_hdl",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useEyePressureMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("eyePressure"),
    queryFn: async ({ signal }) => {
      const left = await fetchMetric({
        metricName: "risk_assessment.eye_pressure.left",
        api,
        patientId,
        signal,
      });
      const right = await fetchMetric({
        metricName: "risk_assessment.eye_pressure.right",
        api,
        patientId,
        signal,
      });
      return {
        left,
        right,
      };
    },
    staleTime: Infinity,
  });
}

export function useGripStrengthMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("gripStrength"),
    queryFn: async ({ signal }) => {
      const left = await fetchMetric({
        metricName: "body.grip_strength.left",
        api,
        patientId,
        signal,
      });
      const right = await fetchMetric({
        metricName: "body.grip_strength.right",
        api,
        patientId,
        signal,
      });
      return {
        left,
        right,
      };
    },
    staleTime: Infinity,
  });
}

export function useBloodOxygenMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("bloodOxygen"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "cardio.oxygen_saturation",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useAnklePressureMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("anklePressure"),
    queryFn: async ({ signal }) => {
      const left = await fetchMetric({
        metricName: "cardio.ankle_pressure.left",
        api,
        patientId,
        signal,
      });
      const right = await fetchMetric({
        metricName: "cardio.ankle_pressure.right",
        api,
        patientId,
        signal,
      });
      return { left, right };
    },
    staleTime: Infinity,
  });
}

export function useLdlMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("ldl"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "bloodwork.ldl",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}

export function useSkinLesionCountMetric({
  scanId,
  scanVersion,
}: {
  scanId?: string;
  scanVersion?: string;
}) {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("skinLesionCount"),
    queryFn:
      scanId && scanVersion
        ? ({ signal }) => {
            const req = api.detectedMarkings.getCount({
              patientId,
              scanId: scanId,
              scanVersion: scanVersion,
            });

            signal?.addEventListener("abort", () => req.abandon());

            return req.result;
          }
        : skipToken,
    staleTime: Infinity,
  });
}

export function useSmokingMetric() {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery({
    queryKey: queryKey("smoking"),
    queryFn: ({ signal }) =>
      fetchMetric({
        metricName: "lifestyle.smoking-habit",
        api,
        patientId,
        signal,
      }),
    staleTime: Infinity,
  });
}
