import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import { BloodPressure } from "lib/questionnaire/question-flows/onboarding/questions/bloodPressure";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function BloodPressureStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(BloodPressure);
  const label = TransQuestions.BloodPressure.Question();
  const { englishLabel } = TransQuestions.BloodPressure.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={BloodPressure}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(BloodPressure);
      }}
      explanation={{
        openText: <Trans.General.WhatDoesThisMean />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.BloodPressureExplanation />,
      }}
    >
      <ChoiceInput
        question={BloodPressure}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(BloodPressure, {
            answer,
            questionAnswer: {
              questionId: "bloodPressure",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: BloodPressure.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[]) {
  steps.push(BloodPressureStep);
}
