import { localize } from "render/context/InternationalizationContext";

/**
 * Shared translations for studies
 */

const Cardio = {
  Title: localize({
    en_GB: <>Cardio Alpha</>,
    sv_SE: <>Cardio Alpha</>,
  }),
  Description: localize({
    en_GB: (
      <>
        This clinical trial is to evaluate the suitability of a new laser-radar
        based technology to assess arterial stiffness and its correlation to
        cardiovascular risk and disease.
      </>
    ),
    sv_SE: (
      <>
        Denna kliniska prövning skapades för att utvärdera lämpligheten av en ny
        laser-radarbaserad teknologi för att bedöma arteriell stelhet och dess
        korrelation till kardiovaskulär risk och sjukdom.
      </>
    ),
  }),
};

const DermaFlow = {
  Title: localize({
    en_GB: <>DermaFlow Alpha</>,
    sv_SE: <>DermaFlow Alpha</>,
  }),
  Description: localize({
    en_GB: (
      <>
        This clinical trial is about evaluating the suitability of multi-modal
        imaging technology that virtually covers the entire body, to assess the
        potential of screening and early detection of disease.
      </>
    ),
    sv_SE: (
      <>
        Denna kliniska prövning handlar om att utvärdera lämpligheten av
        multimodal bildteknik som praktiskt taget täcker hela kroppen, för att
        bedöma potentialen för screening och tidig upptäckt av sjukdom.{" "}
      </>
    ),
  }),
};

const Spectrum = {
  Title: localize({
    en_GB: <>Spectrum 1</>,
    sv_SE: <>Spectrum 1</>,
  }),
  Description: localize({
    en_GB: (
      <>
        This clinical trial will evaluate a trial device that uses a projector
        to depict patterns on the skin, which the skin absorbs, reflects, and
        spreads depending on skin composition and structure.
      </>
    ),
    sv_SE: (
      <>
        Denna kliniska prövning kommer att utvärdera en testenhet som använder
        en projektor för att avbilda mönster på huden, som huden absorberar,
        reflekterar och sprider beroende på hudens sammansättning och struktur.
      </>
    ),
  }),
};

export const TransStudies = {
  Cardio,
  DermaFlow,
  Spectrum,
};
