import { type ReactNode } from "react";
import { usePopup } from "render/context/PopupContext";
import styles from "./styles.module.sass";

type PopupBackdropProps = {
  children?: ReactNode;
  kind?: "dialog" | "toast";
  onClose?: () => void;
};

export function PopupBackdrop({ children, kind, onClose }: PopupBackdropProps) {
  const { clear } = usePopup();
  const close = onClose ?? clear;

  return (
    <div
      className={styles.backdrop}
      data-kind={kind}
      onClick={(event) => {
        // dismiss only if clicking the backdrop/overlay, not its children
        if (event.target === event.currentTarget) {
          close();
        }
      }}
    >
      {children}
    </div>
  );
}
