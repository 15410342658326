export function DataDot({
  color,
  value,
  delay,
}: {
  value: number;
  color: string;
  delay?: string;
}) {
  return (
    <circle cx="50%" cy={`${value}%`} fill={color}>
      <animate
        attributeName="r"
        from={"0px"}
        to={"2.5px"}
        begin={delay ? delay : "0s"}
        dur={`0.15s`}
        repeatCount="1"
        fill="freeze"
      />
    </circle>
  );
}
