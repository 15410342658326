import { PropsWithChildren } from "react";
import { ErrorBoundary } from "render/ui/format/ErrorBoundary";
import { AppCrashErrorView } from "./components/AppCrashScreen";

export function ErrorBoundaryContext({ children }: PropsWithChildren<{}>) {
  return (
    <ErrorBoundary
      fallback={({ message }) => <AppCrashErrorView message={message} />}
    >
      {children}
    </ErrorBoundary>
  );
}
