import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { useAPIClient } from "render/context/APIContext";
import { QueryOptions } from "typings/query";

// There is a bug in the backend that if we send them ISO format, with a time zone
// it returns slots that are in the past
const TIME_FORMAT_FOR_BACKEND = "yyyy-LL-dd'T'HH:mm:ss";

export function queryFn(
  api: APIClient,
  siteIds: string[],
  {
    pageSize,
    start,
    end,
  }: { pageSize?: number; start?: DateTime; end?: DateTime }
) {
  return api.bookingV2
    .querySlots({
      siteIds,
      pageSize,
      startTime: {
        start: start?.toFormat(TIME_FORMAT_FOR_BACKEND),
        end: end?.toFormat(TIME_FORMAT_FOR_BACKEND),
      },
    })
    .result.then((response) => {
      return response.items.map(fromAPI.toBookableSlot);
    });
}
function queryKey(
  slotIds: string[],
  pageSize: number,
  start: DateTime | undefined,
  end: DateTime | undefined
) {
  return [
    "slots",
    slotIds.join(","),
    pageSize,
    start?.toFormat(TIME_FORMAT_FOR_BACKEND) ?? "no-start-time",
    end?.toFormat(TIME_FORMAT_FOR_BACKEND) ?? "no-end-time",
  ];
}

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useSiteSlotsQuery<T = Body>(
  {
    siteIds,
    end,
    start,
    pageSize,
  }: { siteIds: string[]; start?: DateTime; end?: DateTime; pageSize: number },
  options: QueryOptions<Body, Key, T> = {}
) {
  const api = useAPIClient();

  return useQuery({
    ...options,
    queryFn: () => queryFn(api, siteIds, { start, end, pageSize }),
    queryKey: queryKey(siteIds, pageSize, start, end),
  });
}
