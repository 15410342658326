import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import React, { ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
  appInsights: ApplicationInsights;
  fallback: (props: { message: string | undefined }) => JSX.Element;
}
interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage?: string;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: { hasError: boolean; message?: string };
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, message: error?.message };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.appInsights.trackException({
      error: error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: errorInfo,
    });
  }
  render() {
    if (this.state.hasError) {
      return this.props.fallback({ message: this.state.message });
    }
    return this.props.children;
  }
}
