import { localize } from "render/context/InternationalizationContext";

const DayTimeAvg = localize({
  en_GB: <>Daytime avg</>,
  sv_SE: <>Genomsnitt dagtid</>,
});

const NightTimeAvg = localize({
  en_GB: <>Nighttime avg</>,
  sv_SE: <>Genomsnitt nattetid</>,
});

export const Trans = { DayTimeAvg, NightTimeAvg };
