import { glsl } from "lib/glsl";

export const vert = glsl`
struct PointMix {
  float position;
  float scatter;
  float front;
  float back;
  float heart;
};

struct Points {
  float size;
  PointMix mix;
};

struct Scan {
  float origin;
  float width;
};

uniform float time;
uniform Scan scan;
uniform Points points;

varying vec2 vUv;
varying vec3 vColor;
varying vec3 vPosition;
varying vec3 vPos_Scatter;
varying vec3 vPos_Front;
varying vec3 vPos_Back;
varying vec3 vPos_Heart;

varying float bump;

attribute float deviation;
attribute vec3 scatter;
attribute vec3 front;
attribute vec3 back;
attribute vec3 heart;

float cubicInOut(float t) {
  return t < 0.5
    ? 4.0 * t * t * t
    : 0.5 * pow(2.0 * t - 2.0, 3.0) + 1.0;
}

void main() {
  vUv = uv;
  vColor = color;
  vPos_Front = front;
  vPos_Back = back;
  vPos_Heart = heart;
  vPos_Scatter = scatter;

  vec3 pos_scatter = vec3(scatter);
  vec3 pos_front = vec3(front);
  vec3 pos_back = vec3(back);
  vec3 pos_heart = vec3(heart);

  bump = cubicInOut(1.0 - min(scan.width, abs(scan.origin - pos_front.z)) / scan.width);

  pos_scatter.x += sin((deviation * 100.0) + time * 0.05) * deviation * 1000.0;
  pos_scatter.y += cos((deviation * 100.0) + time * 0.05) * deviation * 1000.0;

  // Points not accounted for is set to 0.
  // If points are exactly on zero, prefer
  // scatter position
  if (pos_front != vec3(0.0)) {
    pos_front.y += bump * 12.0;
    pos_front.x *= 1.0 + bump * -0.01;
  } else {
    pos_front = pos_scatter;
  }

  if (pos_back != vec3(0.0)) {
    pos_back.y += bump * 12.0;
    pos_back.x *= 1.0 + bump * -0.01;
  } else {
    pos_back = pos_scatter;
  }

  if (pos_heart == vec3(0.0)) {
    pos_heart = pos_scatter;
  }

  vec3 mixed = position * points.mix.position +
    pos_scatter * points.mix.scatter +
    pos_front * points.mix.front +
    pos_back * points.mix.back +
    pos_heart * points.mix.heart;

  vPosition = mixed;

  vec4 mvPosition = vec4(mixed, 1.0);
  mvPosition = modelViewMatrix * mvPosition;
  gl_Position = projectionMatrix * mvPosition;

  float sizeAttenuation = distance(gl_Position, mvPosition) * 0.0001;
  gl_PointSize = points.size * (1.0 / sizeAttenuation);
}
`;
