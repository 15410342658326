import { PropsWithChildren, forwardRef } from "react";
import styles from "./styles.module.sass";

interface FullScreenPageLayoutProps {
  className?: string;
  disableBackground?: boolean;
}

export const FullScreenPageLayout = forwardRef<
  HTMLDivElement,
  PropsWithChildren<FullScreenPageLayoutProps>
>(({ children, className, disableBackground, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      style={{ background: disableBackground ? "transparent" : undefined }}
      className={[className, styles.FullScreenPageLayout]
        .filter(Boolean)
        .join(" ")}
    >
      {children}
    </div>
  );
});
