import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as CrossIcon } from "assets/icons/24x24/24x24_close.svg";
import { ReactNode, useCallback, useEffect } from "react";
import { paths } from "render/routes/paths";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { ActionButton } from "render/ui/trigger/ActionButton/ActionButton";
import { CheckInStepExplanationLayout } from "../__shared/CheckInStepExplanationLayout";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface CheckInFailViewProps {
  Header: ReactNode;
  Text: ReactNode;
  onActive?: () => void;
}

export function CheckInFailView({
  Header,
  Text,
  onActive,
}: CheckInFailViewProps) {
  useEffect(() => {
    onActive?.();
  }, [onActive]);

  const nav = {
    root: useNav(paths.root),
  };

  const handleGoHome = useCallback(() => {
    nav.root.go({});
  }, [nav.root]);

  return (
    <CheckInStepExplanationLayout
      Header={
        <div className={styles.pageHeader}>
          <div className={styles.closeButton} onClick={handleGoHome}>
            <CrossIcon />
          </div>
          <LogoHeader />
        </div>
      }
      Content={
        <div className={styles.text}>
          <h1 className={styles.header}>{Header}</h1>
          <p className={styles.subHeader}>{Text}</p>
        </div>
      }
      Actions={
        <ActionButton onClick={handleGoHome}>
          <Trans.Action />
        </ActionButton>
      }
    />
  );
}
