import { localize } from "render/context/InternationalizationContext";

const Product = {
  Name: localize({
    en_GB: "Neko Body Scan",
    sv_SE: "Neko Body Scan",
  }),
  Total: localize({
    en_GB: "Total:",
    sv_SE: "Summa:",
  }),
};

const Code = {
  Label: localize({
    en_GB: "Have an invite code ?",
    sv_SE: "Har du en inbjudningskod?",
  }),
  Code: localize({
    en_GB: "Code:",
    sv_SE: "Kod:",
  }),
};

export const Trans = { Product, Code };
