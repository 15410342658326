import { Typography } from "@cur8/maneki";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import styles from "./styles.module.sass";

export function AppCrashErrorView({ message }: { message?: string }) {
  return (
    <FullScreenPageLayout>
      <div className={styles.content}>
        <Typography variant="display-s">
          The application has experienced an unexpected error
        </Typography>
        {message && (
          <Typography variant="title-l" color="subtle">
            Error: {message}
          </Typography>
        )}
      </div>
    </FullScreenPageLayout>
  );
}
