import { QuestionnaireResponse } from "@cur8/api-client/dist/api/cur8/generated-types/v2/Api";
import { APIClient } from "lib/api/client";
import { v4 } from "uuid";

export function saveOnboarding({
  apiClient,
  patientId,
  questionnaireResponse,
}: {
  questionnaireResponse: QuestionnaireResponse;
  apiClient: APIClient;
  patientId: string;
}) {
  return apiClient.questionnaire.createOrUpdateQuestionnaireResponse(
    {
      patientId,
      responseId: questionnaireResponse.responseId ?? v4(),
    },
    questionnaireResponse
  );
}
