import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { PropsWithChildren, useEffect, useState } from "react";
import { usePopup } from "render/context/PopupContext";
import styles from "./styles.module.sass";

export function PopupFrame({ children }: PropsWithChildren<{}>) {
  const { popups } = usePopup();

  const popup = popups.at(-1);
  const content = popup?.content;

  useEffect(() => {
    setOverscrollBehavior(content ? "none" : "auto");
    return () => {
      setOverscrollBehavior("auto");
    };
  }, [content]);

  // we need to defer this value so the content's CSS has time to apply.
  // useDeferredValue works but is inconsistent
  const active = useDelayedValue(!!content);

  return (
    <div className={styles.PopupFrame} data-active={active}>
      <ViewStack>
        <div className={styles.context}>{children}</div>
        <Sticky>{content}</Sticky>
      </ViewStack>
    </div>
  );
}

function useDelayedValue<T>(value: T) {
  const [current, setCurrent] = useState<T>();

  useEffect(() => {
    setTimeout(() => requestAnimationFrame(() => setCurrent(value)));
  }, [value]);

  return current;
}

function setOverscrollBehavior(behavior: "none" | "auto") {
  window.document.documentElement.style.setProperty(
    "overscroll-behavior",
    behavior
  );
}
