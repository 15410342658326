import { localize } from "render/context/InternationalizationContext";

const TabHeader = {
  General: localize({
    en_GB: <>General</>,
    sv_SE: <>Allmänt</>,
  }),
  DataAndPrivacy: localize({
    en_GB: <>Data & Privacy</>,
    sv_SE: <>Data & Integritet</>,
  }),
  Discounts: localize({
    en_GB: <>Invite</>,
    sv_SE: <>Bjud in</>,
  }),
  Cross: localize({
    en_GB: "close",
    sv_SE: "stänga",
  }),
};

export const Trans = { TabHeader };
