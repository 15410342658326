import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Blood oxygen</>,
  sv_SE: <>Syremättnad</>,
});

const MetricUnit = localize({
  en_GB: <>%</>,
  sv_SE: <>%</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        We measure amount of oxygen in the blood using a device called a pulse
        oximeter. This tells us how much of your haemoglobin is carrying oxygen.
        Your blood oxygen saturation is determined by how well your lungs are
        functioning and how effectively oxygen is being transported to the
        tissues of your body. It is a useful tool in diagnosing and monitoring
        respiratory and cardiovascular conditions. In healthy individuals, it
        can also be a marker of overall respiratory health.
      </>
    ),
    sv_SE: (
      <>
        Vi mäter mängden syre i blodet med hjälp av en pulsoximeter. Detta ger
        en uppfattning om hur din lungfunktion står till och hur väl du
        syresätter dina röda blodkroppar.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        The most your result can be is 100%, this is considered perfectly normal
        and healthy, and is a sign that your blood is working efficiently to
        carry oxygen around the body.
      </>
    ),
    sv_SE: (
      <>
        Den högsta syresättningen man kan ha är 100% vilket är ett normalt värde
        och ett tecken för normal syresättning.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low blood oxygen saturation level (below 95%) can be a sign that your
        lungs are struggling to provide enough oxygen to your blood, or that the
        blood is carrying oxygen inefficiently. This can cause inadequate oxygen
        supply to the body's tissues and organs. Low levels of oxygen in the
        blood happens in conditions such as chronic obstructive pulmonary
        disease (COPD), asthma, pneumonia, and heart failure. Persistent low
        levels require medical attention, as they might indicate serious
        underlying health issues.
      </>
    ),
    sv_SE: (
      <>
        En låg syremättnadsnivå i blodet (under 95 %) kan vara ett tecken på att
        dina lungor kämpar för att ge tillräckligt med syre till ditt blod,
        eller att blodet transporterar syre ineffektivt. Den vanligaste orsaken
        kan vara kalla fingrar som påverkar undersökningskvalitén. Andra orsaker
        innefattar astma, hjärtsvikt, andra lungsjukdomar och rökning.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Monitoring trends in your blood oxygen saturation over time can help you
        to understand your level of respiratory health. A decreasing trend might
        indicate worsening lung function or the development of a respiratory
        condition. You can improve your blood oxygen saturation by making
        lifestyle changes such as doing more aerobic exercise and stopping
        smoking.
      </>
    ),
    sv_SE: (
      <>
        En minskande trend kan tyda på försämrad lungfunktion eller utveckling
        av lungsjukdom. Genom regelbunden träning och framför allt
        konditionsträning förbättrar du din syresättning.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        For most people a blood oxygen saturation level between 95% and 100% is
        ideal. People with chronic obstructive pulmonary disease tend to have
        slightly lower levels, but if their body has adapted to this then it
        might be considered normal.
      </>
    ),
    sv_SE: (
      <>
        För de flesta människor är en syremättnadsnivå i blodet mellan 95 % och
        100% och vad som anses normalt.
      </>
    ),
  }),
};

export const Trans = { MetricName, MetricUnit, FAQ };
