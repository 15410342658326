import { ChoiceQuestion, TextQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum DietEnum {
  Vegan = "vegan",
  Vegetarian = "vegetarian",
  Pescatarian = "pescatarian",
  Keto = "ketogenic",
  Paleo = "paleo",
  LowCarb = "low carbohydrates",
  LowMeat = "low meat",
  Other = "other",
  More = "more",
  LowCarbHighFat = "low carbohydrates, high fat (LCHF)",
}

export const DietOptions = new ChoiceQuestion(<Trans.Diet.Specific />, [
  [<Trans.Diet.None />, undefined],
  [<Trans.Diet.LowMeat />, DietEnum.LowMeat],
  [<Trans.Diet.Pescatarian />, DietEnum.Pescatarian],
  [<Trans.Diet.Vegetarian />, DietEnum.Vegetarian],
  [<Trans.Diet.More />, DietEnum.More],
]);

export const MoreDietOptions = new ChoiceQuestion(<Trans.Diet.Specific />, [
  [<Trans.Diet.Keto />, DietEnum.Keto],
  [<Trans.Diet.LowCarb />, DietEnum.LowCarb],
  [<Trans.Diet.LowCarbHighFat />, DietEnum.LowCarbHighFat],
  [<Trans.Diet.Paleo />, DietEnum.Paleo],
  [<Trans.Diet.Vegan />, DietEnum.Vegan],
  [<Trans.Diet.Other />, DietEnum.Other],
]);

export const OtherDietOption = new TextQuestion(<Trans.Diet.Question />);
