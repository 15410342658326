import { localize } from "render/context/InternationalizationContext";

const NextScan = localize({
  en_GB: <>Next scan</>,
  sv_SE: <>Nästa scan</>,
});

const FollowUpIn = localize<{ date: string; months: number }>({
  en_GB: ({ date, months }) => (
    <>
      Based on your results, we suggest that you follow up in&nbsp;
      {date}, {months} months after your scan. If you haven't booked a new scan,
      we will send you a reminder when it's time.
    </>
  ),
  sv_SE: ({ date, months }) => (
    <>
      Baserat på dina resultat, rekommenderar vi att du följer upp i&nbsp;
      {date}, {months} månader efter denna scan. Om du inte har bokat en ny scan
      skickar vi dig en påminnelse när det är dags.
    </>
  ),
});
const FollowUpDue = localize<{ date: string }>({
  en_GB: ({ date }) => (
    <>
      Based on your results, we suggest that you follow up in&nbsp;
      {date}. If you haven't booked a new scan, please contact us.
    </>
  ),
  sv_SE: ({ date }) => (
    <>
      Baserat på dina resultat, rekommenderar vi att du följer upp i&nbsp;
      {date}. Om du inte har bokat en ny scan, hör av dig till oss.
    </>
  ),
});

export const Trans = { FollowUpDue, NextScan, FollowUpIn };
