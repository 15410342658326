import { useState, useEffect, useMemo } from "react";
import { DateTime, DateTimeUnit } from "luxon";

export function useLiveTime(unit: DateTimeUnit): DateTime {
  const initial = useMemo(() => DateTime.now(), []);

  const [time, setTime] = useState<DateTime>(initial);

  useEffect(() => {
    const delay = time
      .startOf(unit)
      .plus({ [unit]: 1 })
      .diff(time, "milliseconds");

    const timer = setTimeout(() => {
      const nextTime = DateTime.now()
        .startOf(unit)
        .plus({ [unit]: 1 });
      setTime(nextTime);
    }, delay.milliseconds);

    return () => clearTimeout(timer);
  }, [unit, time]);

  return time;
}
