import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

const queryKey = (patientId: string, slotId?: string) =>
  ["patient-slot", patientId, slotId] as const;

const queryFn = (apiClient: APIClient, slotId: string) =>
  apiClient.bookingV2.getSlot({ slotId }).result.then(fromAPI.toSlot);

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useAppointmentQuery<T = Body>(
  { slotId }: { slotId: string | undefined },
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  const result = useMemo(() => {
    if (slotId == null) {
      return {
        queryFn: undefined,
        queryKey: queryKey(patientId),
      };
    }
    return {
      queryKey: queryKey(patientId, slotId),
      queryFn: () => queryFn(apiClient, slotId),
    };
  }, [apiClient, patientId, slotId]);

  return useQuery({
    queryFn: result.queryFn,
    queryKey: result.queryKey,
    ...options,
  });
}
