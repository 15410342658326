import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Non-HDL</>,
  sv_SE: <>Non-HDL</>,
});

const MetricUnit = localize({
  en_GB: <>mmol/L</>,
  sv_SE: <>mmol/L</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        This measures how much of your cholesterol is one of the unhealthy types
        that can build up in your blood vessels. It includes the three main
        types of cholesterol that are potentially harmful: VLDL, IDL, and LDL.
      </>
    ),
    sv_SE: (
      <>
        Non-HDL-kolesterol visar hur mycket av ditt kolesterol som är av den
        ohälsosamma typen som kan byggas upp i dina blodkärl. Det inkluderar de
        tre huvudtyperna av kolesterol som är potentiellt skadliga: VLDL, IDL
        och LDL.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        A high result means that you have a high amount of potentially harmful
        cholesterol in your blood. If you have high levels for many years it can
        travel into the walls of your arteries and form cholesterol deposits
        called atheroma. This can decrease the blood supply to organs such as
        the heart, brain, and kidneys, causing problems like angina, dementia,
        and chronic kidney disease. When it completely blocks a blood vessel it
        can cause a heart attack or stroke.
      </>
    ),
    sv_SE: (
      <>
        Ett högt resultat betyder att du har en hög mängd potentiellt skadligt
        kolesterol i blodet. Om du har höga nivåer under många år kan dessa
        bilda kolesterolavlagringar i kärlväggarna. Detta kan i sin tur minska
        blodtillförseln till organ som hjärtat, hjärnan och njurarna, vilket kan
        orsaka problem som kärlkramp, demens och kronisk njursjukdom. Dessa
        förträngningar kan leda till proppbildning och akuta tillstånd såsom en
        hjärtinfarkt eller stroke.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        There is no lower limit of normal for this biomarker, so you won’t see a
        result that has been flagged as low. Aiming to keep your non-HDL
        cholesterol as low as you can will minimise your risk of developing
        cardiovascular disease. This will help to protect your heart and blood
        vessels.
      </>
    ),
    sv_SE: (
      <>
        Det finns ingen nedre normalgräns för denna biomarkör, så du kommer inte
        att se ett resultat som har flaggats som lågt. Om du strävar efter att
        hålla ditt non-HDL-kolesterol lågt, kan du minimera risken för att
        utveckla hjärt-kärlsjukdom. Detta kommer att hjälpa till att skydda ditt
        hjärta och blodkärl.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        You can control your non-HDL cholesterol by changing your lifestyle,
        e.g. by substituting unhealthy saturated fats such as red meat, with
        healthy poly-unsaturated fats and low glycaemic index carbohydrates, or
        by increasing your exercise levels. Most positive changes that you make
        to your lifestyle will appear in your cholesterol results within three
        months.
      </>
    ),
    sv_SE: (
      <>
        Genom att följa non-HDL-kolesterol kan vi utvärdera din risk för hjärt-
        och kärlsjukdomar. Du kan själv påverka ditt non-HDL-kolesterol genom
        att ändra din livsstil, t.ex. genom att ersätta ohälsosamma mättade
        fetter som rött kött, med hälsosamma fleromättade fetter och kolhydrater
        med lågt glykemiskt index, eller genom att öka din träningsnivå. De
        flesta positiva förändringar som du gör i din livsstil kommer att visas
        i dina kolesterolresultat inom tre månader.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        It is best if you aim to keep your non-HDL cholesterol as low as
        possible. If you’ve already had a heart problem or stroke, then your
        doctor might set an even lower target for this. It is important to
        remember that this is one of several markers that tell us your risk of
        developing cardiovascular problems, it is important to look at all of
        these and ensure that they are in the healthy range.
      </>
    ),
    sv_SE: (
      <>
        Det är bäst om du strävar efter att hålla ditt non-HDL-kolesterol så
        lågt som möjligt. Om du redan har haft hjärtproblem eller stroke kan din
        läkare sätta ett ännu lägre mål för detta. Det är viktigt att komma ihåg
        att detta är en av flera markörer som talar om för oss din risk att
        utveckla hjärt-kärlproblem. Det är viktigt att titta på alla dessa och
        se till att de ligger inom det hälsosamma intervallet.
      </>
    ),
  }),
};

export const Trans = { MetricName, MetricUnit, FAQ };
