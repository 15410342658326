import { Answers } from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import {
  SkinCancer,
  SkinCancerEnum,
  SkinCancerMelanoma,
  SkinCancerMelanomaWho,
} from "lib/questionnaire/question-flows/onboarding/questions/skinCancer";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function SkinCancerStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(SkinCancer);
  const label = TransQuestions.SkinCancer.Question();
  const { englishLabel } = TransQuestions.SkinCancer.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={SkinCancer}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(SkinCancer);
      }}
      explanation={{
        openText: <Trans.General.WhatDoesThisMean />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SkinCancerExplanation />,
      }}
    >
      <ChoiceInput
        question={SkinCancer}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(SkinCancer, {
            answer,
            questionAnswer: {
              questionId: "skinCancer",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: SkinCancer.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function SkinCancerMelanomaStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(SkinCancerMelanoma);
  const label = TransQuestions.SkinCancer.MelanomaQuestion();
  const { englishLabel } = TransQuestions.SkinCancer.MelanomaQuestion;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={SkinCancerMelanoma}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(SkinCancerMelanoma);
      }}
      explanation={{
        openText: <Trans.General.WhatDoesThisMean />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SkinCancerMelanomaExplanation />,
      }}
    >
      <ChoiceInput
        question={SkinCancerMelanoma}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(SkinCancerMelanoma, {
            answer,
            questionAnswer: {
              questionId: "skinCancer.melanoma",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: SkinCancerMelanoma.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function SkinCancerMelanomaWhoStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(SkinCancerMelanomaWho);
  const label = TransQuestions.SkinCancer.MelanomaWhoQuestion();
  const { englishLabel } = TransQuestions.SkinCancer.MelanomaWhoQuestion;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={SkinCancerMelanomaWho}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(SkinCancerMelanomaWho);
      }}
      explanation={{
        openText: <Trans.General.WhatDoesThisMean />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SkinCancerMelanomaExplanation />,
      }}
    >
      <ChoiceInput
        question={SkinCancerMelanomaWho}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(SkinCancerMelanomaWho, {
            answer,
            questionAnswer: {
              questionId: "skinCancer.melanoma.who",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: SkinCancerMelanomaWho.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(SkinCancerStep);

  if (answers.lookup(SkinCancer) === SkinCancerEnum.Yes) {
    steps.push(SkinCancerMelanomaStep);
  }
  if (answers.lookup(SkinCancerMelanoma) === SkinCancerEnum.Yes) {
    steps.push(SkinCancerMelanomaWhoStep);
  }
}
