import { useHistory } from "@pomle/react-router-paths";
import { useEffect } from "react";

function RedirectTo(props: { target: string; replace: boolean }) {
  const { replace, push } = useHistory();

  useEffect(() => {
    if (props.replace) {
      replace(props.target);
    } else {
      push(props.target);
    }
  }, [replace, props.replace, props.target, push]);

  return null;
}

export function redirectTo<T extends {}>({
  to,
  replace = false,
}: {
  to: (match: { params: T; exact: boolean }) => string;
  replace?: boolean;
}) {
  return function render(match: { params: T; exact: boolean } | null) {
    if (match && match.exact) {
      const target = to(match);

      return <RedirectTo target={target} replace={replace} />;
    }

    return null;
  };
}
