import { DataDot } from "../DataDot";
import { DataRange } from "../DataRange/DataRange";

interface DataDisplayProps {
  min: number;
  max: number;
  color: string;
  delay?: string;
  duration: number;
}

export function DataDisplay({
  min,
  max,
  color,
  delay,
  duration,
}: DataDisplayProps) {
  if (min === max) {
    return <DataDot value={max} color={color} delay={delay} />;
  }

  return (
    <DataRange
      color={color}
      max={max}
      min={min}
      delay={delay}
      duration={duration}
    />
  );
}
