import { useLocation } from "@pomle/react-router-paths";
import { useEffect } from "react";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { RedirectView } from "render/views/auth/RedirectView";
import { PostScanShareContext } from "render/context/PostScanShareContext";

import { SessionRoutes } from "./routes/SessionRoutes";
import { isDirectMatch } from "render/routes/lib/isDirectMatch";

export function Router() {
  const { pathname } = useLocation();
  const { trackPageView } = useTracking();

  useEffect(() => {
    trackPageView();
  }, [trackPageView, pathname]);

  if (isDirectMatch(paths.auth.redirect, pathname)) {
    return <RedirectView />;
  }

  return (
    <PostScanShareContext>
      <SessionRoutes />
    </PostScanShareContext>
  );
}
