import { localize } from "render/context/InternationalizationContext";

const Elevated = localize({
  en_GB: <>Elevated</>,
  sv_SE: <>Förhöjt</>,
});

const Diabetes = localize({
  en_GB: <>Diabetes</>,
  sv_SE: <>Diabetes</>,
});

const High = localize({
  en_GB: <>High</>,
  sv_SE: <>Högt</>,
});

const Low = localize({
  en_GB: <>Low</>,
  sv_SE: <>Lågt</>,
});

const Mild = localize({
  en_GB: <>Mild</>,
  sv_SE: <>Mild</>,
});

const Moderate = localize({
  en_GB: <>Moderate</>,
  sv_SE: <>Måttligt</>,
});

const Normal = localize({
  en_GB: <>Normal</>,
  sv_SE: <>Normalt</>,
});

const Optimal = localize({
  en_GB: <>Optimal</>,
  sv_SE: <>Optimalt</>,
});

const PreDiabetes = localize({
  en_GB: <>Pre-Diabetes</>,
  sv_SE: <>Prediabetes</>,
});

const VeryHigh = localize({
  en_GB: <>Very high</>,
  sv_SE: <>Väldigt högt</>,
});

const VeryLow = localize({
  en_GB: <>Very low</>,
  sv_SE: <>Väldigt lågt</>,
});

export const Trans = {
  Elevated,
  Diabetes,
  High,
  Low,
  Mild,
  Moderate,
  Normal,
  Optimal,
  PreDiabetes,
  VeryHigh,
  VeryLow,
};
