import { localize } from "render/context/InternationalizationContext";

export const Agreed = localize<{ date: string }>({
  en_GB: ({ date }) => <>Agreed on {date}</>,
  sv_SE: ({ date }) => <>Signerat {date}</>,
});

export const Expired = localize<{ date: string }>({
  en_GB: ({ date }) => <>Expired on {date}</>,
  sv_SE: ({ date }) => <>Löpte ut {date}</>,
});

export const Revoked = localize<{ date: string }>({
  en_GB: ({ date }) => <>Revoked on {date}</>,
  sv_SE: ({ date }) => <>återkallades {date}</>,
});

export const OptedOut = localize<{ date: string }>({
  en_GB: ({ date }) => <>Opted out on {date}</>,
  sv_SE: ({ date }) => <>Valt bort {date}</>,
});
