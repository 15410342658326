import { listen } from "lib/listen";
import { useLayoutEffect, useState } from "react";

export function useMediaQuery(mediaQuery: string) {
  const [matches, setMatches] = useState(() => matchMedia(mediaQuery).matches);

  useLayoutEffect(() => {
    const mediaQueryList = matchMedia(mediaQuery);
    return listen(mediaQueryList, "change", (e) => setMatches(e.matches));
  }, [mediaQuery]);

  return matches;
}
