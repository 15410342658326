import { useMemo } from "react";

interface PriceNumberProps {
  price: number;
  currency: string;
}

export default function PriceNumber({ price, currency }: PriceNumberProps) {
  const config: { locale: string; options: Intl.NumberFormatOptions } | null =
    useMemo(() => {
      switch (currency) {
        case "SEK":
          return {
            locale: "sv-SE",
            options: {
              currency: "SEK",
              minimumFractionDigits: 0,
            },
          };
        case "GBP":
          return {
            locale: "en-GB",
            options: {
              currency: "GBP",
              minimumFractionDigits: 0,
            },
          };
        default:
          console.warn("Unsupported currency for product price", currency);
          return null;
      }
    }, [currency]);

  if (!config) {
    return null;
  }

  return (
    <>
      {Intl.NumberFormat(config.locale, {
        ...config.options,
        style: "currency",
      }).format(price)}
    </>
  );
}
