import { Typography } from "@cur8/maneki";
import { useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { FadeIn } from "render/views/HomeView/components/_shared/FadeIn";
import { LinkTo } from "render/views/HomeView/components/_shared/LinkTo/LinkTo";
import { Trans } from "./trans";

export function GiveConsentStage() {
  const nav = {
    consent: useNav(paths.consent),
  };
  return (
    <>
      <FadeIn offset={112}>
        <Typography variant="display-s">
          <Trans.Greeting />
        </Typography>
      </FadeIn>
      <FadeIn offset={176}>
        <LinkTo
          href={nav.consent.to({})}
          head={<Trans.GiveConsentAction />}
          variant="square"
        />
      </FadeIn>
    </>
  );
}
