import { Action, Event } from "render/hooks/useTracking";

const category = "Report";

export const reportEvent = {
  clickPrevSummary: (value: "open" | "close"): Event => ({
    label: "older-summary",
    category,
    action: Action.Click,
    value: value,
  }),
  navigate: (tab: "biomarkers" | "summary"): Event => ({
    label: "navigation",
    category,
    action: Action.Click,
    value: tab,
  }),
};
