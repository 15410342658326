import { localizeQuestion } from "lib/questionnaire/question-flows/onboarding/localizeQuestion";
import { localize } from "render/context/InternationalizationContext";

const AdditionalWorries = {
  Question: localizeQuestion({
    en_GB:
      "Is there something related to your health that you are worried about or would like us to address?",
    sv_SE: "Har du haft några problem med hälsan det senaste året?",
  }),
};

const Allergies = {
  Question: localizeQuestion({
    en_GB: "Do you have any allergies?",
    sv_SE: "Har du några allergier?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  AllergiesListQuestion: localizeQuestion({
    en_GB: "What allergies do you have?",
    sv_SE: "Vilka allergier har du?",
  }),
  Dairy: localize({
    en_GB: <>Dairy</>,
    sv_SE: <>Mejeriprodukter</>,
  }),
  InsectSting: localize({
    en_GB: <>Insect Sting</>,
    sv_SE: <>Insektsbett</>,
  }),
  Latex: localize({
    en_GB: <>Latex</>,
    sv_SE: <>Latex</>,
  }),
  Morphine: localize({
    en_GB: <>Morphine</>,
    sv_SE: <>Morfin</>,
  }),
  Nuts: localize({
    en_GB: <>Nuts</>,
    sv_SE: <>Nötter</>,
  }),
  Pets: localize({
    en_GB: "Pets",
    sv_SE: "Pälsdjur",
  }),
  Pollen: localize({
    en_GB: "Pollen",
    sv_SE: "Pollen",
  }),
  Wheat: localize({
    en_GB: <>Wheat</>,
    sv_SE: <>Vete</>,
  }),
  SomethingElse: localize({
    en_GB: "Something else",
    sv_SE: "Annat",
  }),
  OtherAllergiesQuestion: localizeQuestion({
    en_GB: "Please tell us about your allergies",
    sv_SE: "Berätta mer om dina allergier",
  }),
};

const Choice = {
  LessThanSixMonths: localize({
    en_GB: "Less than 6 months ago",
    sv_SE: "Mindre än 6 månader sedan",
  }),
  SixMonths: localize({
    en_GB: "More than 6 months ago",
    sv_SE: "Mer än 6 månader sedan",
  }),
};

const Diet = {
  Question: localizeQuestion({
    en_GB: "What do your eating habits look like?",
    sv_SE: "Hur ser dina kostvanor ut?",
  }),
  Specific: localize({
    en_GB: <>To start, do you have any specific dietary habits?</>,
    sv_SE: <>Första frågan, har du några specifika kostvanor?</>,
  }),
  Vegan: localize({
    en_GB: <>Vegan</>,
    sv_SE: <>Vegan</>,
  }),
  Vegetarian: localize({
    en_GB: <>Vegetarian</>,
    sv_SE: <>Vegetarian</>,
  }),
  Pescatarian: localize({
    en_GB: <>Pescatarian</>,
    sv_SE: <>Pescatarian</>,
  }),
  Keto: localize({
    en_GB: <>Keto</>,
    sv_SE: <>Keto</>,
  }),
  Paleo: localize({
    en_GB: <>Paleo</>,
    sv_SE: <>Paleo</>,
  }),
  LowCarb: localize({
    en_GB: <>Low carb</>,
    sv_SE: <>Lågkalori</>,
  }),
  LowMeat: localize({
    en_GB: <>Low meat</>,
    sv_SE: <>Lågt kött intag</>,
  }),
  Other: localize({
    en_GB: <>Other</>,
    sv_SE: <>Annat</>,
  }),
  None: localize({
    en_GB: "None",
    sv_SE: "Nej",
  }),
  More: localize({
    en_GB: "More options",
    sv_SE: "Fler alternativ",
  }),
  LowCarbHighFat: localize({
    en_GB: "Low carb, High fat [LCHF]",
    sv_SE: "LCHF",
  }),
};

const Drinking = {
  Question: localizeQuestion({
    en_GB: "How many glasses of alcohol do you drink a week?",
    sv_SE: "Hur många glas dricker du i veckan?",
  }),
  DoYouDrinkAlcohol: localizeQuestion({
    en_GB: "Do you drink alcohol?",
    sv_SE: "Dricker du någon alkohol?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  GlassesLessThanOne: localize({
    en_GB: "Less than 1 glass",
    sv_SE: "Mindre än ett glas",
  }),
  GlassesOneToTwo: localize({
    en_GB: "1-2 glasses",
    sv_SE: "1-2 glas",
  }),
  GlassesThreeToFour: localize({
    en_GB: "3-4 glasses",
    sv_SE: "3-4 glas",
  }),
  GlassesFiveToSix: localize({
    en_GB: "5-6 glasses",
    sv_SE: "5-6 glas",
  }),
  GlassesSevenToEight: localize({
    en_GB: "7-8 glasses",
    sv_SE: "7-8 glas",
  }),
  GlassesMoreThanNine: localize({
    en_GB: "More than 9 glasses",
    sv_SE: "Mer än 9 glas",
  }),
};

const HoursPerWeek = localize<{ hours: string }>({
  en_GB: ({ hours }) => `${hours} hours`,
  sv_SE: ({ hours }) => `${hours} timmar`,
});

const MoreThanPerWeek = localize<{ qty: string }>({
  en_GB: ({ qty }) => `More than ${qty} hours`,
  sv_SE: ({ qty }) => `Mer än ${qty} timmar`,
});

const StrengthExercise = {
  Question: localizeQuestion({
    en_GB: "How much strength exercise do you get per week?",
    sv_SE: "Hur ofta styrketränar du per vecka?",
  }),
  MoreThanPerWeek,
  HoursPerWeek,
};

const CardioExercise = {
  Question: localizeQuestion({
    en_GB: "How much cardio exercise do you get per week?",
    sv_SE: "Hur ofta konditionstränar du per vecka?",
  }),
  MoreThanPerWeek,
  HoursPerWeek,
};

const FamilyConditions = {
  Question: localize({
    en_GB: "Do you have any diseases in the family?",
    sv_SE: "Har du några sjukdomar i släkten?",
  }),
};

const Heritage = {
  Question: localizeQuestion({
    en_GB: "What's your heritage?",
    sv_SE: "Var kommer din familj ifrån?",
  }),
};

const WorldRegion = {
  Europe: localize({
    en_GB: <>Europe</>,
    sv_SE: <>Europa</>,
  }),
  NorthAmerica: localize({
    en_GB: <>North America</>,
    sv_SE: <>Nordamerika</>,
  }),
  CentralAmerica: localize({
    en_GB: <>Central America</>,
    sv_SE: <>Centralamerika</>,
  }),
  SouthAmerica: localize({
    en_GB: <>South America</>,
    sv_SE: <>Sydamerika</>,
  }),
  NorthAsia: localize({
    en_GB: <>North Asia</>,
    sv_SE: <>Norra Asien</>,
  }),
  MiddleEast: localize({
    en_GB: <>Middle East</>,
    sv_SE: <>Mellanöstern</>,
  }),
  EastAsia: localize({
    en_GB: <>East Asia</>,
    sv_SE: <>Östra Asien</>,
  }),
  SouthAsia: localize({
    en_GB: <>South Asia</>,
    sv_SE: <>Södra Asien</>,
  }),
  SouthEastAsia: localize({
    en_GB: <>Southeast Asia</>,
    sv_SE: <>Sydöstra Asien</>,
  }),
  Oceania: localize({
    en_GB: <>Oceania</>,
    sv_SE: <>Oceanien</>,
  }),
  NorthAfrica: localize({
    en_GB: <>North Africa</>,
    sv_SE: <>Nordafrika</>,
  }),
  WestAfrica: localize({
    en_GB: <>West Africa</>,
    sv_SE: <>Västafrika</>,
  }),
  EastAfrica: localize({
    en_GB: <>East Africa</>,
    sv_SE: <>Östafrika</>,
  }),
  SouthAfrica: localize({
    en_GB: <>South Africa</>,
    sv_SE: <>Sydafrika</>,
  }),
};

const Medication = {
  Question: localizeQuestion({
    en_GB: "Do you take any medications?",
    sv_SE: "Tar du några mediciner?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  TypeQuestion: localizeQuestion({
    en_GB: "What type of medications do you take?",
    sv_SE: "Vilka typer av mediciner tar du?",
  }),
  AntiDiabetic: localize({
    en_GB: "Anti-diabetic",
    sv_SE: "Diabetesmedicin",
  }),
  Cholesterol: localize({
    en_GB: "Cholesterol",
    sv_SE: "Blodfettssänkande",
  }),
  BloodPressure: localize({
    en_GB: "Blood pressure",
    sv_SE: "Blodtrycksmedicin",
  }),
  SomethingElse: localize({
    en_GB: "Something else",
    sv_SE: "Något annat",
  }),
  SomethingElseQuestion: localizeQuestion({
    en_GB: "What is the name and dosage of your medications?",
    sv_SE: "Vad är det för namn och dos på din medicin?",
  }),
};

const PersonalConditions = {
  Question: localize({
    en_GB: "Have you had any health problems in the past year?",
    sv_SE: "Har du haft några problem med hälsan det senaste året?",
  }),
};

const Smoking = {
  Question: localizeQuestion({
    en_GB: "Do you smoke cigarettes?",
    sv_SE: "Röker du cigaretter?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  Quit: localize({
    en_GB: "No, I quit",
    sv_SE: "Nej, jag har slutat",
  }),
  DailyCigaretteCount: localizeQuestion({
    en_GB: "How much do you smoke a day?",
    sv_SE: "Hur mycket röker du per dag?",
  }),
  CigaretteCountLessThanOne: localize({
    en_GB: "Less than 1 cigarette",
    sv_SE: "Mindre än en cigarett",
  }),
  CigaretteCountLessThanHalfAPack: localize({
    en_GB: "Less than half a pack",
    sv_SE: "Mindre än ett halvt paket",
  }),
  CigaretteCountHalfAPackOrMore: localize({
    en_GB: "Half a pack or more",
    sv_SE: "Ett halvt paket eller mer",
  }),
  CigaretteCountOnePackOrMore: localize({
    en_GB: "One pack or more",
    sv_SE: "Ett paket eller mer",
  }),
  QuitSmokingRecency: localizeQuestion({
    en_GB: "How long ago did you quit?",
    sv_SE: "Hur längesen slutade du?",
  }),
};

const Snus = {
  Question: localizeQuestion({
    en_GB: "Do you snus?",
    sv_SE: "Snusar du?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  Quit: localize({
    en_GB: "No, I quit",
    sv_SE: "Nej, jag har slutat",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  HowOften: localizeQuestion({
    en_GB: "How often do you snus?",
    sv_SE: "Hur ofta snusar du?",
  }),
  QuitSnusingRecency: localizeQuestion({
    en_GB: "How long ago did you quit?",
    sv_SE: "Hur längesen slutade du?",
  }),
  SnusFrequencyAFewTimesAMonth: localize({
    en_GB: "A few times per month",
    sv_SE: "Några gånger per månad",
  }),
  SnusFrequencyOnWeekends: localize({
    en_GB: "On weekends",
    sv_SE: "Bara på helger",
  }),
  SnusFrequencyEveryOtherDay: localize({
    en_GB: "Every other day",
    sv_SE: "Varannan dag",
  }),
  SnusFrequencyEveryDay: localize({
    en_GB: "Every day",
    sv_SE: "Varje dag",
  }),
};

const Sunburn = {
  Question: localizeQuestion({
    en_GB: "Have you been sunburnt in the past year?",
    sv_SE: "Har du bränt dig i solen senaste året?",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  FrequencyOneToTwo: localize({
    en_GB: "1-2 times",
    sv_SE: "1-2 gånger",
  }),
  FrequencyThreeToFive: localize({
    en_GB: "3-5 times",
    sv_SE: "3-5 gånger",
  }),
  FrequencySixToEight: localize({
    en_GB: "6-10 times",
    sv_SE: "6-10 gånger",
  }),
  FrequencyMoreThanTen: localize({
    en_GB: "More than 10 times",
    sv_SE: "Mer än 10 gånger",
  }),
};

const Cardiovascular = {
  Question: localizeQuestion({
    en_GB: "Has anyone in your family had a cardiovascular disease?",
    sv_SE: "Har någon i din familj drabbats av en kardiovaskulär sjukdom?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  Details: localizeQuestion({
    en_GB: "Who and what type of cardiovascular disease?",
    sv_SE: "Vem och vad för typ av kardiovaskulär sjukdom?",
  }),
};

const SkinCancer = {
  Question: localizeQuestion({
    en_GB: "Has anyone in your family had skin cancer?",
    sv_SE: "Har någon i din familj haft hudcancer?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  NotSure: localize({
    en_GB: "I am not sure",
    sv_SE: "Jag är osäker",
  }),
  MelanomaQuestion: localizeQuestion({
    en_GB: "Was your relative diagnosed with melanoma?",
    sv_SE: "Var din familjemedlem diagnosticerad med melanom?",
  }),
  MelanomaWhoQuestion: localizeQuestion({
    en_GB: "Who was diagnosed with melanoma?",
    sv_SE: "Vem var diagnosticerad med melanom?",
  }),
  Child: localize({
    en_GB: "Child",
    sv_SE: "Barn",
  }),
  Parent: localize({
    en_GB: "Parent",
    sv_SE: "Förälder",
  }),
  Sibling: localize({
    en_GB: "Sibling",
    sv_SE: "Syskon",
  }),
  OtherRelative: localize({
    en_GB: "Other relative",
    sv_SE: "Annan släkting",
  }),
};

const Diabetes = {
  Question: localizeQuestion({
    en_GB: "Has anyone in your family had diabetes",
    sv_SE: "Har någon i din familj haft diabetes?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  Details: localizeQuestion({
    en_GB: "Who and what type of diabetes?",
    sv_SE: "Vem och vilken typ av diabetes?",
  }),
};

const RelevantConditions = {
  Question: localizeQuestion({
    en_GB: "Does your family have a history of other conditions?",
    sv_SE: "Finns det några andra sjukdomar i familjen?",
  }),
  Autoimmune: localize({
    en_GB: "Autoimmune disease",
    sv_SE: "Autoimmuna sjukdomar",
  }),
  Cancer: localize({
    en_GB: "Cancer",
    sv_SE: "Cancer",
  }),
  Neurological: localize({
    en_GB: "Neurological disease",
    sv_SE: "Neurologiska sjukdomar",
  }),
  SomethingElse: localize({
    en_GB: "Something else",
    sv_SE: "Annat",
  }),
  AutoimmuneDetails: localizeQuestion({
    en_GB: "Who and which autoimmune disease?",
    sv_SE: "Vem och vilka autoimmuna sjukdomar?",
  }),
  CancerDetails: localizeQuestion({
    en_GB: "Who and what type of cancer?",
    sv_SE: "Vem och vilken typ av cancer?",
  }),
  SomethingElseDetails: localizeQuestion({
    en_GB: "Who and which health conditions?",
    sv_SE: "Vem och vilka typer av sjukdomar?",
  }),
};

const Pregnant = {
  Question: localizeQuestion({
    en_GB: "Are you pregnant?",
    sv_SE: "Är du gravid?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
};

const SkinConditions = {
  Question: localizeQuestion({
    en_GB: "Do you have a skin condition?",
    sv_SE: "Har du en hudsjukdom?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  TypeQuestion: localizeQuestion({
    en_GB: "Which skin condition were you diagnosed with?",
    sv_SE: "Vilken hudsjukdom diagnosticerades du med?",
  }),
  Acne: localize({
    en_GB: "Acne",
    sv_SE: "Akne",
  }),
  AcneDescription: localize({
    en_GB: "This condition occurs when hair follicles in the skin are clogged.",
    sv_SE: "Akne är utslag som beror på inflammerade talgkörtlar.",
  }),
  Eczema: localize({
    en_GB: "Eczema",
    sv_SE: "Eksem",
  }),
  EczemaDescription: localize({
    en_GB: "This condition causes dry, itchy and inflamed skin.",
    sv_SE:
      "Eksem är en inflammation i huden som gör att den blir torr och kliar.",
  }),
  Psoriasis: localize({
    en_GB: "Psoriasis",
    sv_SE: "Psoriasis",
  }),
  PsoriasisDescription: localize({
    en_GB:
      "This condition causes itchy, scaly patches, usually on knees and elbows.",
    sv_SE:
      "En sjukdom som ger kliande utslag och plaque på huden. Oftast på knän och armbågar.",
  }),
  Rosacea: localize({
    en_GB: "Rosacea",
    sv_SE: "Rosacea",
  }),
  RosaceaDescription: localize({
    en_GB: "This condition causes long-term redness and bumps on the face.",
    sv_SE: "En åkomma som framförallt orsakar rodnad och utslag i ansiktet.",
  }),
  SkinCancer: localize({
    en_GB: "Skin cancer",
    sv_SE: "Hudcancer",
  }),
  SkinCancerDescription: localize({
    en_GB:
      "This condition occurs when abnormal cells in the skin grow out of control.",
    sv_SE: "Abnormal tillväxt av celler i huden. ",
  }),
  SomethingElse: localize({
    en_GB: "Something else",
    sv_SE: "Annat",
  }),
  SkinCancerQuestion: localizeQuestion({
    en_GB: "What type of skin cancer?",
    sv_SE: "Vilken typ av hudcancer?",
  }),
  ActinicKeratosis: localize({
    en_GB: "Actinic Keratosis",
    sv_SE: "Aktinisk keratos",
  }),
  ActinicKeratosisDescription: localize({
    en_GB:
      "This is a pre-cancerous condition where scaly or crusty bumps form.",
    sv_SE:
      "En prekursor till cancer. Kronisk solskada i huden med fjällande utseende.",
  }),
  BCC: localize({
    en_GB: "Basal Cell Cercinoma / Basalioma (BCC)",
    sv_SE: "Basalcellscarcinom/Basaliom (BCC)",
  }),
  BCCDescription: localize({
    en_GB: "This type of skin cancer starts in the basal cell layer.",
    sv_SE: "En hudcancer som börjar i basalcells-lagret i huden.",
  }),
  Melanoma: localize({
    en_GB: "Melanoma",
    sv_SE: "Melanoma",
  }),
  MelanomaDescription: localize({
    en_GB:
      "This type of skin cancer starts in the pigment-making cells of the skin.",
    sv_SE: "En hudcancer som börjar från pigmentcellerna i huden. ",
  }),
  SCC: localize({
    en_GB: "Squamous Cell Carcinoma (SCC)",
    sv_SE: "Skivepitelscancer (SCC)",
  }),
  SCCDescription: localize({
    en_GB: "This type of skin cancer starts in the squamous cell layer.",
    sv_SE: "En hudcancer som börjar i skivepitel-lagret i huden.",
  }),
  NotSure: localize({
    en_GB: "I am not sure",
    sv_SE: "Jag är osäker",
  }),
};

const MedicalHistoryDiabetes = {
  Question: localizeQuestion({
    en_GB: "Do you have diabetes?",
    sv_SE: "Har du diabetes?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  TypeQuestion: localizeQuestion({
    en_GB: "Which type were you diagnosed with?",
    sv_SE: "Vilken typ har du blivit diagnosticerad med?",
  }),
  TypeOne: localize({
    en_GB: "Type 1 diabetes",
    sv_SE: "Typ 1 diabetes",
  }),
  TypeTwo: localize({
    en_GB: "Type 2 diabetes",
    sv_SE: "Typ 2 diabetes",
  }),
  TypeOneDescription: localize({
    en_GB:
      "This occurs when the body cannot produce insulin due to a problem in the immune system.",
    sv_SE:
      "Kroppen kan inte producera eget insulin pga en autoimmun process i kroppen.",
  }),
  TypeTwoDescription: localize({
    en_GB:
      "This occurs when the body is resistant to insulin due to hereditary or lifestyle factors.",
    sv_SE:
      "This occurs when the body is resistant to insulin due to hereditary or lifestyle factors.",
  }),
  ComplicationsQuestion: localizeQuestion({
    en_GB: "Were you diagnosed with any complications from diabetes?",
    sv_SE:
      "Har du blivit diagnosticerad med några komplikationer från din diabetes?",
  }),
  EyeProblems: localize({
    en_GB: "Eye problems (Retinopathy)",
    sv_SE: "Ögonproblem (Retinopathy)",
  }),
  FootProblems: localize({
    en_GB: "Foot problems (Neuropathy)",
    sv_SE: "Problem med fötterna (Neuropathy)",
  }),
  HeartProblems: localize({
    en_GB: "Heart or vessel problems (Atherosclerosis)",
    sv_SE: "Hjärt- och kärlproblem (Atherosclerosis)",
  }),
  KidneyProblems: localize({
    en_GB: "Kidney problems (Nephropathy)",
    sv_SE: "Njurproblem (Nephropathy)",
  }),
  EyeProblemsDescription: localize({
    en_GB: "This is when there is vision loss and potential blindness.",
    sv_SE: "Påverkan på synen och/eller de små kärlen i ögat.",
  }),
  FootProblemsDescription: localize({
    en_GB:
      "This is when the nerves are damaged, causing pain or numbness in the feet.",
    sv_SE:
      "Påverkan på nerverna, oftast i fötterna, som orsakar smärta och avdomningar i fötterna.",
  }),
  HeartProblemsDescription: localize({
    en_GB:
      "This is when the arteries harden, thus decreasing blood flow to the heart.",
    sv_SE:
      "Stela kärl pga förkalkningar som leder till minskat blodflöde och potentiellt påverkan på hjärtat. ",
  }),
  KidneyProblemsDescription: localize({
    en_GB:
      "This is when the kidneys lose their function, and potential kidney failure.",
    sv_SE: "Njursvikt eller minskad njurfunktion till följd av diabetesen.",
  }),
};

const BloodPressure = {
  Question: localizeQuestion({
    en_GB: "Have you been diagnosed with high blood pressure?",
    sv_SE: "Har du blivit diagnosticerad med högt blodtryck?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
};

const CardioConditions = {
  Question: localizeQuestion({
    en_GB: "Have you ever had any of these cardiovascular conditions?",
    sv_SE: "Har du haft några av dessa kardiovaskulära sjukdomar?",
  }),
  Angina: localize({
    en_GB: "Angina",
    sv_SE: "Angina",
  }),
  AtrialFibrillation: localize({
    en_GB: "Atrial fibrillation",
    sv_SE: "Förmaksflimmer",
  }),
  CongestiveHeartFailure: localize({
    en_GB: "Congestive heart failure",
    sv_SE: "Hjärtsvikt",
  }),
  FamilialHypercholesterolemia: localize({
    en_GB: "Familial hypercholesterolemia",
    sv_SE: "Familjär hyperkolesterolemi",
  }),
  HeartAttack: localize({
    en_GB: "Heart attack",
    sv_SE: "Hjärtattack",
  }),
  Stroke: localize({
    en_GB: "Stroke",
    sv_SE: "Stroke",
  }),
  ValvularDisease: localize({
    en_GB: "Valvular disease",
    sv_SE: "Klaffsjukdomar",
  }),
  AnginaDescription: localize({
    en_GB:
      "Chest pain caused by reduced heart blood flow, often triggered by activity or stress.",
    sv_SE:
      "Bröstsmärta orsakad av minskat blodflöde till hjärtat, ofta utlöst av fysisk aktivitet eller stress.",
  }),
  AtrialFibrillationDescription: localize({
    en_GB:
      "Irregular and rapid heart rhythms that causes the heart to beat abnormally.",
    sv_SE: "Oregelbunden och snabb hjärtrytm.",
  }),
  CongestiveHeartFailureDescription: localize({
    en_GB:
      "This is when the heart can't supply enough blood for the body's needs.",
    sv_SE:
      "Hjärtats pumpförmåga är nedsatt och kan inte längre tillgodose kroppens cirkulatoriska behov.",
  }),
  FamilialHypercholesterolemiaDescription: localize({
    en_GB:
      "A genetic condition which causes very high cholesterol levels in the blood.",
    sv_SE:
      "En genetisk sjukdom som leder till extremt höga kolesterol-värden i blodet.",
  }),
  HeartAttackDescription: localize({
    en_GB:
      "Blood flow to the heart is suddenly blocked, damaging the heart muscle.",
    sv_SE:
      "Otillräcklig blodtillförsel till hjärtat som leder till muskelskada på hjärtat.",
  }),
  StrokeDescription: localize({
    en_GB:
      "Blood flow to the brain is blocked, or there is sudden bleeding in the brain.",
    sv_SE:
      "Blodflödet till hjärnan är blockerat eller en spontan blödning i hjärnan.",
  }),
  ValvularDiseaseDescription: localize({
    en_GB:
      "A condition where any of the heart’s  valves do not open or close properly. ",
    sv_SE: "Någon av hjärtats klaffar öppnas och/eller stängs inte ordentligt.",
  }),
  ValvularDiseaseQuestion: localizeQuestion({
    en_GB: "What type of valvular disease?",
    sv_SE: "Vilka typer av klaffsjukdomar?",
  }),
  AorticValveInsufficiency: localize({
    en_GB: "Aortic valve insufficiency",
    sv_SE: "Aortainsufficiens",
  }),
  AorticValveStenosis: localize({
    en_GB: "Aortic valve stenosis",
    sv_SE: "Aortastenos",
  }),
  MitralValveInsufficiency: localize({
    en_GB: "Mitral valve insufficiency",
    sv_SE: "Mitralisinsufficiens",
  }),
  MitralValveStenosis: localize({
    en_GB: "Mitral valve stenosis",
    sv_SE: "Mitralisstenos",
  }),
  NotSure: localize({
    en_GB: "I am not sure",
    sv_SE: "Jag är osäker",
  }),
  AorticValveInsufficiencyDescription: localize({
    en_GB:
      "Happens when the aortic valve does not close properly, leaking blood back.",
    sv_SE:
      "Aortaklaffen kan inte stänga sig ordentligt och leder till att blodet flödar tillbaka i hjärtat.",
  }),
  AorticValveStenosisDescription: localize({
    en_GB:
      "Happens when the aortic valve does not close properly, leaking blood back.",
    sv_SE:
      "Aortaklaffen är blockerad. Hjärtat måste pumpa hårdare för att få ut blodet ur kammaren.",
  }),
  MitralValveInsufficiencyDescription: localize({
    en_GB: "A condition where the mitral valve does not work properly.",
    sv_SE:
      "Mitralisklaffen kan inte stänga sig ordentligt och leder till att blodet flödar tillbaka i hjärtat.",
  }),
  MitralValveStenosisDescription: localize({
    en_GB:
      "Happens when the mitral valve does not close properly, leaking blood back.",
    sv_SE:
      "Mitralisklaffen är blockerad. Hjärtat måste pumpa hårdare för att få ut blodet ur kammaren.",
  }),
};

const HealthConditions = {
  Question: localizeQuestion({
    en_GB: "Have you ever had any of these conditions?",
    sv_SE: "Har du haft några av dessa sjukdomar?",
  }),
  Arthritis: localize({
    en_GB: "Arthritis",
    sv_SE: "Artrit",
  }),
  KidneyDisease: localize({
    en_GB: "Kidney disease",
    sv_SE: "Njursjukdom",
  }),
  PeripheralVascularDisease: localize({
    en_GB: "Peripheral vascular disease",
    sv_SE: "Perifer artärsjukdom",
  }),
  RaynaudsSyndrome: localize({
    en_GB: "Raynaud’s syndrome",
    sv_SE: "Raynaud’s Syndrom",
  }),
  ArthritisDescription: localize({
    en_GB: "This is also known as joint pain or joint disease.",
    sv_SE: "Inflammation i lederna.",
  }),
  KidneyDiseaseDescription: localize({
    en_GB:
      "A condition where the kidneys are damaged and cannot filter blood well.",
    sv_SE:
      "Icke välfungerade njurar pga skador på njuren eller problem med filtreringen.",
  }),
  PeripheralVascularDiseaseDescription: localize({
    en_GB:
      "Blood flow to the limbs is reduced due to blockages in the blood vessels.",
    sv_SE: "Blodflödet till ben och armar är reducerat pga tränga kärl.",
  }),
  RaynaudsSyndromeDescription: localize({
    en_GB:
      "Causes fingers and toes to temporarily turn white, usually due to the cold.",
    sv_SE:
      "Leder till att fingrar och tår ibland kan bli vita under några sekunder. Oftast pga köld.",
  }),
};

const OtherHealthConditions = {
  Question: localizeQuestion({
    en_GB: "Any other diagnoses or health conditions?",
    sv_SE: "Några andra diagnoser eller åkommor?",
  }),
};

const HealthProblems = {
  Question: localizeQuestion({
    en_GB: "Have you had any health problems in the past year?",
    sv_SE: "Har du haft några problem med hälsan det senaste året?",
  }),
};

export const Trans = {
  AdditionalWorries,
  Allergies,
  Choice,
  Diet,
  Drinking,
  StrengthExercise,
  CardioExercise,
  FamilyConditions,
  Heritage,
  Medication,
  PersonalConditions,
  Smoking,
  Snus,
  WorldRegion,
  Sunburn,
  Cardiovascular,
  SkinCancer,
  Diabetes,
  RelevantConditions,
  Pregnant,
  SkinConditions,
  MedicalHistoryDiabetes,
  BloodPressure,
  CardioConditions,
  HealthConditions,
  OtherHealthConditions,
  HealthProblems,
};
