import { localize } from "render/context/InternationalizationContext";

const StudyInfo = localize({
  en_GB: (
    <>
      Always consult with your medical team before taking action or if you have
      questions.
    </>
  ),
  sv_SE: (
    <>
      Rådgör alltid med ditt medicinska team innan du vidtar åtgärder eller om
      du har frågor.
    </>
  ),
});

const StudyInfoSweden = localize({
  en_GB: (
    <>
      Biomarkers marked with the Neko symbol are part of our clinical studies.
      Always consult with your medical team before taking action or if you have
      questions.
    </>
  ),
  sv_SE: (
    <>
      Biomarkörer som är markerade med Neko symbolen är en del av våra kliniska
      studier. Rådgör alltid med ditt medicinska team innan du vidtar åtgärder
      eller om du har frågor.
    </>
  ),
});

const Title = localize({
  en_GB: <>Scan Data</>,
  sv_SE: <>Scan Data</>,
});

const LastUpdated = localize({
  en_GB: <>Last updated on</>,
  sv_SE: <>Senast uppdaterad den</>,
});

export const Trans = { Title, StudyInfo, StudyInfoSweden, LastUpdated };
