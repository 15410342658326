import { ChoiceQuestion } from "@cur8/questionnaire";
import { RecencyChoices } from "./choice";
import { Trans } from "./trans";

export enum IsSmokingEnum {
  No = "No",
  Quit = "Quit",
  Yes = "Yes",
}

export const IsSmoker = new ChoiceQuestion(<Trans.Smoking.Question />, [
  [<Trans.Smoking.No />, IsSmokingEnum.No],
  [<Trans.Smoking.Quit />, IsSmokingEnum.Quit],
  [<Trans.Smoking.Yes />, IsSmokingEnum.Yes],
]);

export enum CigaretteCountEnum {
  LessThanOne = "Less than one",
  LessThanHalfAPack = "Less than half a pack",
  HalfAPackOrMore = "Half a pack or more",
  OnePackOrMore = "One pack or more",
}

const CigaretteCountChoices: [string | JSX.Element, CigaretteCountEnum][] = [
  [<Trans.Smoking.CigaretteCountLessThanOne />, CigaretteCountEnum.LessThanOne],
  [
    <Trans.Smoking.CigaretteCountLessThanHalfAPack />,
    CigaretteCountEnum.LessThanHalfAPack,
  ],
  [
    <Trans.Smoking.CigaretteCountHalfAPackOrMore />,
    CigaretteCountEnum.HalfAPackOrMore,
  ],
  [
    <Trans.Smoking.CigaretteCountOnePackOrMore />,
    CigaretteCountEnum.OnePackOrMore,
  ],
];

export const DailyCigaretteCount = new ChoiceQuestion(
  <Trans.Smoking.DailyCigaretteCount />,
  CigaretteCountChoices
);

export const QuitSmokingRecency = new ChoiceQuestion(
  <Trans.Smoking.QuitSmokingRecency />,
  RecencyChoices
);
