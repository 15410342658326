import { useCallback } from "react";
import { usePopup } from "render/context/PopupContext";
import { Toast } from "render/ui/presentation/Toast";
import { ContactUsPopup } from "render/views/_shared/ContactUsPopup";

export function useContactUsPopup() {
  const { create } = usePopup();

  const emit = useCallback(() => {
    const actions = create();

    actions.emit(
      <Toast>
        <ContactUsPopup onClose={actions.close} />
      </Toast>
    );
  }, [create]);

  return { emit };
}
