import { localize } from "render/context/InternationalizationContext";

const CTA = localize({
  en_GB: <>Navigate to home screen</>,
  sv_SE: <>Navigate to home screen</>,
});

export const Trans = {
  CTA,
};
