import { Metric } from "lib/api/metrics";

export function getHighestBloodPressureValue(
  left?: Metric<"cardio.brachial_pressure.left">,
  right?: Metric<"cardio.brachial_pressure.right">
) {
  if (!left || !right) {
    return undefined;
  }

  if (left.unit.systolic > right.unit.systolic) {
    return left;
  }
  if (left.unit.systolic < right.unit.systolic) {
    return right;
  }

  if (left.unit.diastolic >= right.unit.diastolic) {
    return left;
  }

  return right;
}
