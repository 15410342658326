export function clamp(value: number, min: number, max: number) {
  if (value > max) {
    return max;
  }
  if (value < min) {
    return min;
  }
  return value;
}

export function withDecimals(value: number, decimals: number) {
  const pow = Math.pow(10, decimals);
  return Math.trunc(value * pow) / pow;
}
