export function mapRange<T, R>(
  items: T[],
  start: number,
  end: number,
  fn: (item: T, index: number) => R
): R[] {
  const output: R[] = [];

  const low = Math.max(0, start);
  const high = Math.min(items.length - 1, end);

  for (let i = low; i <= high; i++) {
    output.push(fn(items[i], i));
  }
  return output;
}

export function range<R>(
  start: number,
  end: number,
  fn: (index: number) => R
): R[] {
  const output: R[] = [];

  for (let i = start; i <= end; i++) {
    output.push(fn(i));
  }
  return output;
}
