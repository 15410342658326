import { PropsWithChildren } from "react";
import styles from "./styles.module.sass";

export function Expandable({
  isOpen,
  children,
}: PropsWithChildren<{ isOpen: boolean }>) {
  return (
    <div className={styles.Expandable} data-open={isOpen}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
