import { localize } from "render/context/InternationalizationContext";

const PaymentFailed = localize({
  en_GB: <>Payment failed</>,
  sv_SE: <>Betalningen misslyckades</>,
});

const CantProcessPayment = localize({
  en_GB: <>Unfortunately we were not able to proceed with your payment</>,
  sv_SE: <>Tyvärr så lyckades vi inte genomföra din betalning</>,
});
const TryAgain = localize({
  en_GB: <>Try again</>,
  sv_SE: <>Försök igen</>,
});

export const Trans = {
  CantProcessPayment,
  PaymentFailed,
  TryAgain,
};
