export type SexIndex = "m" | "f";
export type BloodPressureIndex = "100" | "120" | "140" | "160";
export type CholesterolIndex = "3" | "4" | "5" | "6";
export type SmokingIndex = "y" | "n";
export type AgeIndex =
  | "40"
  | "45"
  | "50"
  | "55"
  | "60"
  | "65"
  | "70"
  | "75"
  | "80"
  | "85";

export type LookUpTable = {
  [key in SexIndex]: {
    [key in AgeIndex]: {
      [key in BloodPressureIndex]: {
        [key in CholesterolIndex]: {
          [key in SmokingIndex]: number;
        };
      };
    };
  };
};

export const score2LookupMap: LookUpTable = {
  f: {
    "40": {
      "100": {
        "3": {
          n: 1,
          y: 2,
        },
        "4": {
          n: 1,
          y: 2,
        },
        "5": {
          n: 1,
          y: 2,
        },
        "6": {
          n: 1,
          y: 3,
        },
      },
      "120": {
        "3": {
          n: 1,
          y: 3,
        },
        "4": {
          n: 1,
          y: 3,
        },
        "5": {
          n: 1,
          y: 3,
        },
        "6": {
          n: 2,
          y: 4,
        },
      },
      "140": {
        "3": {
          n: 1,
          y: 3,
        },
        "4": {
          n: 2,
          y: 4,
        },
        "5": {
          n: 2,
          y: 5,
        },
        "6": {
          n: 2,
          y: 5,
        },
      },
      "160": {
        "3": {
          n: 2,
          y: 5,
        },
        "4": {
          n: 2,
          y: 5,
        },
        "5": {
          n: 3,
          y: 6,
        },
        "6": {
          n: 3,
          y: 7,
        },
      },
    },
    "45": {
      "100": {
        "3": {
          n: 1,
          y: 3,
        },
        "4": {
          n: 1,
          y: 3,
        },
        "5": {
          n: 1,
          y: 3,
        },
        "6": {
          n: 2,
          y: 4,
        },
      },
      "120": {
        "3": {
          n: 2,
          y: 3,
        },
        "4": {
          n: 2,
          y: 4,
        },
        "5": {
          n: 2,
          y: 4,
        },
        "6": {
          n: 2,
          y: 5,
        },
      },
      "140": {
        "3": {
          n: 2,
          y: 5,
        },
        "4": {
          n: 2,
          y: 5,
        },
        "5": {
          n: 3,
          y: 6,
        },
        "6": {
          n: 3,
          y: 6,
        },
      },
      "160": {
        "3": {
          n: 3,
          y: 6,
        },
        "4": {
          n: 3,
          y: 7,
        },
        "5": {
          n: 3,
          y: 8,
        },
        "6": {
          n: 4,
          y: 9,
        },
      },
    },
    "50": {
      "100": {
        "3": {
          n: 2,
          y: 3,
        },
        "4": {
          n: 2,
          y: 4,
        },
        "5": {
          n: 2,
          y: 4,
        },
        "6": {
          n: 2,
          y: 5,
        },
      },
      "120": {
        "3": {
          n: 2,
          y: 5,
        },
        "4": {
          n: 2,
          y: 5,
        },
        "5": {
          n: 3,
          y: 6,
        },
        "6": {
          n: 3,
          y: 6,
        },
      },
      "140": {
        "3": {
          n: 3,
          y: 6,
        },
        "4": {
          n: 3,
          y: 6,
        },
        "5": {
          n: 4,
          y: 7,
        },
        "6": {
          n: 4,
          y: 8,
        },
      },
      "160": {
        "3": {
          n: 4,
          y: 8,
        },
        "4": {
          n: 4,
          y: 8,
        },
        "5": {
          n: 5,
          y: 9,
        },
        "6": {
          n: 5,
          y: 10,
        },
      },
    },
    "55": {
      "100": {
        "3": {
          n: 3,
          y: 5,
        },
        "4": {
          n: 3,
          y: 5,
        },
        "5": {
          n: 3,
          y: 6,
        },
        "6": {
          n: 3,
          y: 6,
        },
      },
      "120": {
        "3": {
          n: 3,
          y: 6,
        },
        "4": {
          n: 3,
          y: 7,
        },
        "5": {
          n: 4,
          y: 7,
        },
        "6": {
          n: 4,
          y: 8,
        },
      },
      "140": {
        "3": {
          n: 4,
          y: 8,
        },
        "4": {
          n: 4,
          y: 8,
        },
        "5": {
          n: 5,
          y: 9,
        },
        "6": {
          n: 5,
          y: 10,
        },
      },
      "160": {
        "3": {
          n: 5,
          y: 10,
        },
        "4": {
          n: 6,
          y: 11,
        },
        "5": {
          n: 6,
          y: 11,
        },
        "6": {
          n: 7,
          y: 12,
        },
      },
    },
    "60": {
      "100": {
        "3": {
          n: 4,
          y: 6,
        },
        "4": {
          n: 4,
          y: 7,
        },
        "5": {
          n: 4,
          y: 7,
        },
        "6": {
          n: 5,
          y: 8,
        },
      },
      "120": {
        "3": {
          n: 5,
          y: 8,
        },
        "4": {
          n: 5,
          y: 8,
        },
        "5": {
          n: 5,
          y: 9,
        },
        "6": {
          n: 6,
          y: 10,
        },
      },
      "140": {
        "3": {
          n: 6,
          y: 10,
        },
        "4": {
          n: 6,
          y: 11,
        },
        "5": {
          n: 7,
          y: 11,
        },
        "6": {
          n: 7,
          y: 12,
        },
      },
      "160": {
        "3": {
          n: 7,
          y: 12,
        },
        "4": {
          n: 8,
          y: 13,
        },
        "5": {
          n: 8,
          y: 14,
        },
        "6": {
          n: 9,
          y: 15,
        },
      },
    },
    "65": {
      "100": {
        "3": {
          n: 5,
          y: 9,
        },
        "4": {
          n: 6,
          y: 9,
        },
        "5": {
          n: 6,
          y: 9,
        },
        "6": {
          n: 6,
          y: 10,
        },
      },
      "120": {
        "3": {
          n: 7,
          y: 10,
        },
        "4": {
          n: 7,
          y: 11,
        },
        "5": {
          n: 7,
          y: 12,
        },
        "6": {
          n: 8,
          y: 12,
        },
      },
      "140": {
        "3": {
          n: 8,
          y: 13,
        },
        "4": {
          n: 9,
          y: 13,
        },
        "5": {
          n: 9,
          y: 14,
        },
        "6": {
          n: 9,
          y: 15,
        },
      },
      "160": {
        "3": {
          n: 10,
          y: 15,
        },
        "4": {
          n: 10,
          y: 16,
        },
        "5": {
          n: 11,
          y: 17,
        },
        "6": {
          n: 12,
          y: 18,
        },
      },
    },
    "70": {
      "100": {
        "3": {
          n: 7,
          y: 12,
        },
        "4": {
          n: 7,
          y: 13,
        },
        "5": {
          n: 8,
          y: 13,
        },
        "6": {
          n: 8,
          y: 14,
        },
      },
      "120": {
        "3": {
          n: 9,
          y: 15,
        },
        "4": {
          n: 9,
          y: 16,
        },
        "5": {
          n: 10,
          y: 17,
        },
        "6": {
          n: 11,
          y: 18,
        },
      },
      "140": {
        "3": {
          n: 11,
          y: 18,
        },
        "4": {
          n: 11,
          y: 19,
        },
        "5": {
          n: 12,
          y: 20,
        },
        "6": {
          n: 13,
          y: 22,
        },
      },
      "160": {
        "3": {
          n: 13,
          y: 22,
        },
        "4": {
          n: 14,
          y: 23,
        },
        "5": {
          n: 15,
          y: 25,
        },
        "6": {
          n: 16,
          y: 26,
        },
      },
    },
    "75": {
      "100": {
        "3": {
          n: 12,
          y: 17,
        },
        "4": {
          n: 12,
          y: 18,
        },
        "5": {
          n: 13,
          y: 19,
        },
        "6": {
          n: 14,
          y: 20,
        },
      },
      "120": {
        "3": {
          n: 14,
          y: 20,
        },
        "4": {
          n: 15,
          y: 21,
        },
        "5": {
          n: 15,
          y: 22,
        },
        "6": {
          n: 16,
          y: 24,
        },
      },
      "140": {
        "3": {
          n: 16,
          y: 24,
        },
        "4": {
          n: 17,
          y: 25,
        },
        "5": {
          n: 18,
          y: 26,
        },
        "6": {
          n: 19,
          y: 28,
        },
      },
      "160": {
        "3": {
          n: 19,
          y: 27,
        },
        "4": {
          n: 20,
          y: 29,
        },
        "5": {
          n: 21,
          y: 30,
        },
        "6": {
          n: 23,
          y: 32,
        },
      },
    },
    "80": {
      "100": {
        "3": {
          n: 19,
          y: 24,
        },
        "4": {
          n: 20,
          y: 25,
        },
        "5": {
          n: 21,
          y: 27,
        },
        "6": {
          n: 22,
          y: 28,
        },
      },
      "120": {
        "3": {
          n: 21,
          y: 27,
        },
        "4": {
          n: 22,
          y: 28,
        },
        "5": {
          n: 24,
          y: 30,
        },
        "6": {
          n: 25,
          y: 31,
        },
      },
      "140": {
        "3": {
          n: 24,
          y: 30,
        },
        "4": {
          n: 25,
          y: 32,
        },
        "5": {
          n: 27,
          y: 33,
        },
        "6": {
          n: 28,
          y: 35,
        },
      },
      "160": {
        "3": {
          n: 27,
          y: 34,
        },
        "4": {
          n: 28,
          y: 35,
        },
        "5": {
          n: 30,
          y: 37,
        },
        "6": {
          n: 31,
          y: 38,
        },
      },
    },
    "85": {
      "100": {
        "3": {
          n: 30,
          y: 34,
        },
        "4": {
          n: 32,
          y: 35,
        },
        "5": {
          n: 33,
          y: 37,
        },
        "6": {
          n: 34,
          y: 38,
        },
      },
      "120": {
        "3": {
          n: 32,
          y: 36,
        },
        "4": {
          n: 34,
          y: 38,
        },
        "5": {
          n: 35,
          y: 39,
        },
        "6": {
          n: 37,
          y: 41,
        },
      },
      "140": {
        "3": {
          n: 35,
          y: 39,
        },
        "4": {
          n: 36,
          y: 40,
        },
        "5": {
          n: 38,
          y: 42,
        },
        "6": {
          n: 39,
          y: 43,
        },
      },
      "160": {
        "3": {
          n: 37,
          y: 41,
        },
        "4": {
          n: 39,
          y: 43,
        },
        "5": {
          n: 40,
          y: 44,
        },
        "6": {
          n: 42,
          y: 46,
        },
      },
    },
  },
  m: {
    "40": {
      "100": {
        "3": {
          n: 2,
          y: 3,
        },
        "4": {
          n: 2,
          y: 4,
        },
        "5": {
          n: 2,
          y: 5,
        },
        "6": {
          n: 3,
          y: 6,
        },
      },
      "120": {
        "3": {
          n: 2,
          y: 4,
        },
        "4": {
          n: 3,
          y: 5,
        },
        "5": {
          n: 3,
          y: 6,
        },
        "6": {
          n: 4,
          y: 8,
        },
      },
      "140": {
        "3": {
          n: 3,
          y: 6,
        },
        "4": {
          n: 4,
          y: 7,
        },
        "5": {
          n: 4,
          y: 8,
        },
        "6": {
          n: 5,
          y: 10,
        },
      },
      "160": {
        "3": {
          n: 4,
          y: 8,
        },
        "4": {
          n: 5,
          y: 9,
        },
        "5": {
          n: 6,
          y: 11,
        },
        "6": {
          n: 7,
          y: 13,
        },
      },
    },
    "45": {
      "100": {
        "3": {
          n: 2,
          y: 4,
        },
        "4": {
          n: 3,
          y: 5,
        },
        "5": {
          n: 3,
          y: 6,
        },
        "6": {
          n: 4,
          y: 7,
        },
      },
      "120": {
        "3": {
          n: 3,
          y: 5,
        },
        "4": {
          n: 4,
          y: 7,
        },
        "5": {
          n: 4,
          y: 8,
        },
        "6": {
          n: 5,
          y: 9,
        },
      },
      "140": {
        "3": {
          n: 4,
          y: 7,
        },
        "4": {
          n: 5,
          y: 8,
        },
        "5": {
          n: 5,
          y: 10,
        },
        "6": {
          n: 6,
          y: 12,
        },
      },
      "160": {
        "3": {
          n: 5,
          y: 9,
        },
        "4": {
          n: 6,
          y: 11,
        },
        "5": {
          n: 7,
          y: 13,
        },
        "6": {
          n: 8,
          y: 15,
        },
      },
    },
    "50": {
      "100": {
        "3": {
          n: 3,
          y: 5,
        },
        "4": {
          n: 4,
          y: 6,
        },
        "5": {
          n: 4,
          y: 7,
        },
        "6": {
          n: 5,
          y: 8,
        },
      },
      "120": {
        "3": {
          n: 4,
          y: 7,
        },
        "4": {
          n: 5,
          y: 8,
        },
        "5": {
          n: 5,
          y: 9,
        },
        "6": {
          n: 6,
          y: 11,
        },
      },
      "140": {
        "3": {
          n: 5,
          y: 9,
        },
        "4": {
          n: 6,
          y: 10,
        },
        "5": {
          n: 7,
          y: 12,
        },
        "6": {
          n: 8,
          y: 14,
        },
      },
      "160": {
        "3": {
          n: 7,
          y: 11,
        },
        "4": {
          n: 8,
          y: 13,
        },
        "5": {
          n: 9,
          y: 15,
        },
        "6": {
          n: 10,
          y: 17,
        },
      },
    },
    "55": {
      "100": {
        "3": {
          n: 4,
          y: 7,
        },
        "4": {
          n: 5,
          y: 8,
        },
        "5": {
          n: 6,
          y: 9,
        },
        "6": {
          n: 6,
          y: 10,
        },
      },
      "120": {
        "3": {
          n: 5,
          y: 9,
        },
        "4": {
          n: 6,
          y: 10,
        },
        "5": {
          n: 7,
          y: 11,
        },
        "6": {
          n: 8,
          y: 13,
        },
      },
      "140": {
        "3": {
          n: 7,
          y: 11,
        },
        "4": {
          n: 8,
          y: 13,
        },
        "5": {
          n: 9,
          y: 14,
        },
        "6": {
          n: 10,
          y: 16,
        },
      },
      "160": {
        "3": {
          n: 9,
          y: 14,
        },
        "4": {
          n: 10,
          y: 16,
        },
        "5": {
          n: 11,
          y: 17,
        },
        "6": {
          n: 12,
          y: 20,
        },
      },
    },
    "60": {
      "100": {
        "3": {
          n: 6,
          y: 9,
        },
        "4": {
          n: 7,
          y: 10,
        },
        "5": {
          n: 7,
          y: 11,
        },
        "6": {
          n: 8,
          y: 12,
        },
      },
      "120": {
        "3": {
          n: 7,
          y: 11,
        },
        "4": {
          n: 8,
          y: 13,
        },
        "5": {
          n: 9,
          y: 14,
        },
        "6": {
          n: 10,
          y: 15,
        },
      },
      "140": {
        "3": {
          n: 9,
          y: 14,
        },
        "4": {
          n: 10,
          y: 15,
        },
        "5": {
          n: 11,
          y: 17,
        },
        "6": {
          n: 12,
          y: 18,
        },
      },
      "160": {
        "3": {
          n: 11,
          y: 17,
        },
        "4": {
          n: 12,
          y: 18,
        },
        "5": {
          n: 13,
          y: 20,
        },
        "6": {
          n: 15,
          y: 22,
        },
      },
    },
    "65": {
      "100": {
        "3": {
          n: 8,
          y: 12,
        },
        "4": {
          n: 9,
          y: 13,
        },
        "5": {
          n: 10,
          y: 14,
        },
        "6": {
          n: 10,
          y: 15,
        },
      },
      "120": {
        "3": {
          n: 10,
          y: 14,
        },
        "4": {
          n: 11,
          y: 15,
        },
        "5": {
          n: 12,
          y: 17,
        },
        "6": {
          n: 13,
          y: 18,
        },
      },
      "140": {
        "3": {
          n: 12,
          y: 17,
        },
        "4": {
          n: 13,
          y: 18,
        },
        "5": {
          n: 14,
          y: 20,
        },
        "6": {
          n: 15,
          y: 21,
        },
      },
      "160": {
        "3": {
          n: 14,
          y: 20,
        },
        "4": {
          n: 15,
          y: 22,
        },
        "5": {
          n: 17,
          y: 23,
        },
        "6": {
          n: 18,
          y: 25,
        },
      },
    },
    "70": {
      "100": {
        "3": {
          n: 10,
          y: 15,
        },
        "4": {
          n: 11,
          y: 16,
        },
        "5": {
          n: 12,
          y: 18,
        },
        "6": {
          n: 13,
          y: 20,
        },
      },
      "120": {
        "3": {
          n: 12,
          y: 19,
        },
        "4": {
          n: 13,
          y: 20,
        },
        "5": {
          n: 15,
          y: 22,
        },
        "6": {
          n: 16,
          y: 24,
        },
      },
      "140": {
        "3": {
          n: 15,
          y: 23,
        },
        "4": {
          n: 17,
          y: 25,
        },
        "5": {
          n: 18,
          y: 28,
        },
        "6": {
          n: 20,
          y: 30,
        },
      },
      "160": {
        "3": {
          n: 19,
          y: 28,
        },
        "4": {
          n: 21,
          y: 31,
        },
        "5": {
          n: 23,
          y: 34,
        },
        "6": {
          n: 25,
          y: 36,
        },
      },
    },
    "75": {
      "100": {
        "3": {
          n: 15,
          y: 19,
        },
        "4": {
          n: 17,
          y: 22,
        },
        "5": {
          n: 19,
          y: 25,
        },
        "6": {
          n: 22,
          y: 29,
        },
      },
      "120": {
        "3": {
          n: 17,
          y: 23,
        },
        "4": {
          n: 20,
          y: 26,
        },
        "5": {
          n: 23,
          y: 29,
        },
        "6": {
          n: 26,
          y: 33,
        },
      },
      "140": {
        "3": {
          n: 21,
          y: 27,
        },
        "4": {
          n: 23,
          y: 30,
        },
        "5": {
          n: 27,
          y: 34,
        },
        "6": {
          n: 30,
          y: 38,
        },
      },
      "160": {
        "3": {
          n: 24,
          y: 31,
        },
        "4": {
          n: 27,
          y: 35,
        },
        "5": {
          n: 31,
          y: 39,
        },
        "6": {
          n: 35,
          y: 44,
        },
      },
    },
    "80": {
      "100": {
        "3": {
          n: 22,
          y: 25,
        },
        "4": {
          n: 26,
          y: 30,
        },
        "5": {
          n: 31,
          y: 35,
        },
        "6": {
          n: 36,
          y: 40,
        },
      },
      "120": {
        "3": {
          n: 25,
          y: 28,
        },
        "4": {
          n: 29,
          y: 33,
        },
        "5": {
          n: 34,
          y: 38,
        },
        "6": {
          n: 40,
          y: 44,
        },
      },
      "140": {
        "3": {
          n: 27,
          y: 31,
        },
        "4": {
          n: 32,
          y: 36,
        },
        "5": {
          n: 37,
          y: 42,
        },
        "6": {
          n: 43,
          y: 48,
        },
      },
      "160": {
        "3": {
          n: 30,
          y: 34,
        },
        "4": {
          n: 35,
          y: 40,
        },
        "5": {
          n: 41,
          y: 46,
        },
        "6": {
          n: 47,
          y: 53,
        },
      },
    },
    "85": {
      "100": {
        "3": {
          n: 32,
          y: 32,
        },
        "4": {
          n: 39,
          y: 39,
        },
        "5": {
          n: 47,
          y: 46,
        },
        "6": {
          n: 55,
          y: 55,
        },
      },
      "120": {
        "3": {
          n: 34,
          y: 34,
        },
        "4": {
          n: 41,
          y: 41,
        },
        "5": {
          n: 49,
          y: 48,
        },
        "6": {
          n: 57,
          y: 57,
        },
      },
      "140": {
        "3": {
          n: 36,
          y: 35,
        },
        "4": {
          n: 43,
          y: 35,
        },
        "5": {
          n: 51,
          y: 51,
        },
        "6": {
          n: 59,
          y: 19,
        },
      },
      "160": {
        "3": {
          n: 37,
          y: 37,
        },
        "4": {
          n: 45,
          y: 45,
        },
        "5": {
          n: 53,
          y: 53,
        },
        "6": {
          n: 62,
          y: 61,
        },
      },
    },
  },
};
