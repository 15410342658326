import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  Title: localize({
    en_GB: "Almost done",
    sv_SE: "Nästan där",
  }),
  ContentOne: localize({
    en_GB: (
      <>
        Before we finish, we have some questions about your own medical history.
      </>
    ),
    sv_SE: (
      <>Innan vi är klara har vi några frågor om din egna sjukdomshistoria.</>
    ),
  }),
  ContentTwo: localize({
    en_GB: (
      <>
        Knowing your medical history will help us better understand your overall
        health.
      </>
    ),
    sv_SE: (
      <>
        Din sjukdomshistoria hjälper oss att få en bättre överblick av din
        generella hälsa.
      </>
    ),
  }),
  Button: localize({
    en_GB: "OK, continue",
    sv_SE: "OK, fortsätt",
  }),
};
