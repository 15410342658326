import { classNames } from "@pomle/classnames";
import { Stage } from "../../utils";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface AssignmentProgressProps {
  stage: Stage;
  completed: number;
  minRequired: number;
}

export function AssignmentProgress({
  completed,
  minRequired,
  stage,
}: AssignmentProgressProps) {
  return (
    <div
      className={classNames(styles.AssignmentProgress, {
        [styles.gap]: stage === Stage.Starting,
      })}
    >
      <div className={styles.header}>
        <Trans.Title />
      </div>
      <div className={styles.progress}>
        {stage === Stage.Starting && <Trans.Notes.Starting />}
        {stage !== Stage.Starting && (
          <Trans.Notes.DaysTracked total={minRequired} tracked={completed} />
        )}
      </div>
    </div>
  );
}
