import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Skin markings</>,
  sv_SE: <>Hudförändringar</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        We monitor changes of your birthmarks, moles and skin spots through
        high-resolution images captured by the Neko Body Scan. These images help
        our doctors with the early detection of skin conditions, including skin
        cancer.
      </>
    ),
    sv_SE: (
      <>
        Vi kontrollerar dina födelsemärken, leverfläckar och hudfläckar genom
        högupplösta bilder från din Neko Body Scan. Dessa bilder hjälper våra
        läkare att tidigt upptäcka hudförändringar, inklusive hudcancer.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        By comparing photographs from different scans, our doctors can identify
        changes in your existing birthmarks and identify new ones compared to
        your last visit. With a comprehensive view of your skin health, you can
        take measures and receive treatment if needed before you notice any
        symptoms.
      </>
    ),
    sv_SE: (
      <>
        Genom att jämföra bilder från olika tillfällen kan våra läkare
        identifiera hudförändringar för dina befintliga födelsemärken, samt
        identifiera om du har fått några nya sedan ditt senaste besök. Med en
        helhetsbild av din hudhälsa kan du vidta åtgärder och få behandling om
        det behövs innan du märker några symtom.
      </>
    ),
  }),
};

export const Trans = { MetricName, FAQ };
