import { Typography } from "@cur8/maneki";
import { ReactElement, useCallback } from "react";
import { Locale } from "render/context/InternationalizationContext";
import { RadioButtons } from "render/ui/form/RadioButtons";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

type Props = {
  locale?: Locale;
  onSetLanguage: (locale: Locale) => void;
  separator?: boolean;
};

export function LanguageSelector({
  locale,
  onSetLanguage,
  separator = false,
}: Props) {
  const supportedLanguages: { value: Locale; content: ReactElement }[] = [
    {
      value: "en_GB",
      content: <Trans.EnGB />,
    },
    {
      value: "sv_SE",
      content: <Trans.SvSE />,
    },
  ];
  const setLangHandler = useCallback(
    (locale: Locale) => {
      onSetLanguage(locale);
    },
    [onSetLanguage]
  );

  return (
    <div className={styles.LanguageSelector} data-separator={separator}>
      <div className={styles.label}>
        <Typography variant="eyebrow-m" color="subtle">
          <Trans.Label />
        </Typography>
      </div>
      <RadioButtons
        entries={supportedLanguages}
        onChange={setLangHandler}
        value={locale}
      />
    </div>
  );
}
