import { useEffect, type ReactNode } from "react";
import { PopupBackdrop } from "render/ui/presentation/PopupBackdrop";
import styles from "./styles.module.sass";

type DialogProps = {
  children?: ReactNode;
  onClose?: () => void;
};

export function Dialog({ children, onClose }: DialogProps) {
  useEffect(() => {
    if (!onClose) {
      return;
    }
    const escape = (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        onClose();
      }
    };
    document.body.addEventListener("keydown", escape);
    return () => document.body.removeEventListener("keydown", escape);
  }, [onClose]);

  return (
    <PopupBackdrop kind="dialog" onClose={onClose}>
      <dialog className={styles.dialog} open>
        {children}
      </dialog>
    </PopupBackdrop>
  );
}
