import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>White blood cells</>,
  sv_SE: <>Vita blodkroppar</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        White blood cells are one of the main components of your immune system.
        They come in various types, each with a specific function in the immune
        response. We measure your white blood cell count to assess your immune
        system's health and its ability to respond to health threats. An
        abnormal white blood cell count can indicate a wide range of conditions,
        from infections and inflammatory diseases to immune system disorders and
        bone marrow problems.
      </>
    ),
    sv_SE: (
      <>
        De vita blodkropparna är några av huvudkomponenterna i ditt
        immunförsvar. De finns i olika typer, var och en med en specifik
        funktion i immunsvaret. Vi mäter ditt antal av vita blodkroppar för att
        bedöma ditt immunsystems hälsa och dess förmåga att reagera på
        infektion. Ett avvikande antal vita blodkroppar kan indikera ett brett
        spektrum av tillstånd, från infektioner och inflammatoriska sjukdomar
        till störningar i immunsystemet och benmärgsproblem.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        A high white blood cell count is commonly caused by infections,
        inflammation, injuries, or stress. If your white blood cell count is
        elevated, it suggests that your body is fighting off something. The type
        of white blood cell that is elevated can also give us clues about the
        nature of the problem, such as whether it's bacterial, viral, or an
        allergic reaction.
      </>
    ),
    sv_SE: (
      <>
        Ett högt antal vita blodkroppar orsakas vanligtvis av infektioner,
        inflammation, skador eller stress. Om ditt antal vita blodkroppar är
        förhöjt, tyder det på att din kropp kämpar mot något. Vilken typ av vita
        blodkroppar som är förhöjda kan också ge oss ledtrådar om problemets
        natur, till exempel om det är en bakteriell, viral eller en allergisk
        reaktion.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low white blood cell count can happen when your body is recovering
        from fighting an infection. If it is very low, then it can increase the
        risk of developing an infection. More rarely it can be a sign of a bone
        marrow problem, or other conditions like autoimmune diseases or liver
        problems. It can also be a side effect of medications.
      </>
    ),
    sv_SE: (
      <>
        Ett lågt antal vita blodkroppar kan inträffa när din kropp återhämtar
        sig från att bekämpa en infektion. Om det är mycket lågt kan det öka
        risken för att utveckla en infektion. Mer sällan kan det vara ett tecken
        på benmärgsproblem eller andra tillstånd som autoimmuna sjukdomar eller
        leverproblem. Det kan också vara en biverkning av mediciner.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Observing the trend in your white blood cell count can help with
        understanding your health. A rising trend can be caused by a wide range
        of issues, from long term infection to allergies to stress. A decreasing
        trend could suggest recovery or, if the count gets too low, a weakening
        immune system. It is important to consider these trends in conjunction
        with other symptoms and diagnostic information.
      </>
    ),
    sv_SE: (
      <>
        Att observera trenden i ditt antal vita blodkroppar kan hjälpa dig att
        förstå din hälsa. En stigande trend kan orsakas av ett brett spektrum av
        problem, från långvariga infektioner till allergier till stress. En
        sjunkande trend kan tyda på återhämtning eller, om antalet blir för
        lågt, ett försvagat immunförsvar. Det är viktigt att överväga dessa
        trender i samband med andra symtom och diagnostisk information.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        Different people can have markedly different white blood cell counts,
        there isn’t an optimal range to aim for. If your white blood cell result
        is outside of the normal range then our doctors will look at the
        individual white blood cell results, such as neutrophils and
        lymphocytes, to understand it further.
      </>
    ),
    sv_SE: (
      <>
        Antalet vita blodkroppar kan variera markant hos olika människor. Det
        finns inte ett optimalt intervall att sikta på. Om resultatet av dina
        vita blodkroppar ligger utanför det normala intervallet kommer våra
        läkare att titta på de individuella vita blodkroppsresultaten för att
        förstå det ytterligare.
      </>
    ),
  }),
};

export const Trans = { MetricName, FAQ };
