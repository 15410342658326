import { Typography } from "@cur8/maneki";
import { ReactComponent as ChevronRight } from "assets/icons/16x16/16x16_chevron-left.svg";
import { ReactComponent as DownloadFilled } from "assets/icons/16x16/16x16_download.svg";
import styles from "./styles.module.sass";

type ListLinkProps = JSX.IntrinsicElements["a"] & {
  variant?: "download" | "link";
};

export function ListLink({ children, variant, ...props }: ListLinkProps) {
  return (
    <a {...props} className={styles.container}>
      <Typography variant="body-m" color="default">
        {children}
      </Typography>
      <Icon variant={variant} />
    </a>
  );
}

type IconProps = {
  variant?: "download" | "link";
};

function Icon({ variant }: IconProps) {
  switch (variant) {
    case "download":
      return <DownloadFilled aria-label="download icon" />;
    case "link":
    default:
      return <ChevronRight aria-label="open link icon" />;
  }
}
