import { Sex } from "@cur8/rich-entity";
import { Metric } from "lib/api/metrics";
import { clamp } from "lib/math";
import {
  AgeIndex,
  BloodPressureIndex,
  CholesterolIndex,
  SexIndex,
  SmokingIndex,
  score2LookupMap,
} from "./score2LookUpTable";

export const SCORE2_MAX_VALUE = 25;

export function calculateScore2(
  sex: Sex,
  age: number,
  systolicBloodPressure: number,
  nonHDLCholesterol: number,
  isSmoker: boolean
): number | null {
  if (sex === Sex.Unknown) {
    return null;
  }

  if (isNaN(age)) {
    return null;
  }

  if (age < 40 || age >= 90) {
    return null;
  }

  const sexIndex: SexIndex = sex === Sex.Male ? "m" : "f";
  const lookUpTable = score2LookupMap;

  const ageIndex = (age - (age % 5)).toString() as AgeIndex;

  if (systolicBloodPressure < 100 || systolicBloodPressure > 179) {
    return null;
  }

  const systolicBloodPressureIndex = (
    systolicBloodPressure -
    (systolicBloodPressure % 20)
  ).toString() as BloodPressureIndex;

  if (nonHDLCholesterol < 3 || nonHDLCholesterol > 6.9) {
    return null;
  }

  const nonHDLCholesterolIndex = Math.floor(
    nonHDLCholesterol
  ).toString() as CholesterolIndex;
  const smokingIndex: SmokingIndex = isSmoker ? "y" : "n";

  return lookUpTable[sexIndex][ageIndex][systolicBloodPressureIndex][
    nonHDLCholesterolIndex
  ][smokingIndex];
}

export function isScore2HighRisk(age: number, score2Value?: number) {
  if (score2Value === undefined) {
    return undefined;
  }

  if (age < 50) {
    return score2Value >= 7.5;
  }

  if (age < 69) {
    return score2Value >= 10;
  }

  if (age < 90) {
    return score2Value >= 15;
  }

  return false;
}

export function patientScore2({
  age,
  sex,
  metric: { nonHDL, smoking, brachialPressure },
}: {
  age: number;
  sex: Sex;
  metric: {
    nonHDL: Metric<"bloodwork.non_hdl">;
    smoking: Metric<"lifestyle.smoking-habit">;
    brachialPressure:
      | Metric<"cardio.brachial_pressure.left">
      | Metric<"cardio.brachial_pressure.right">;
  };
}) {
  const sys = brachialPressure.unit.systolic.mmHg;
  const non = clamp(nonHDL.unit["mmol/L"], 3, 6);
  const smoker = (smoking.unit.dailyCigarettes ?? 0) > 0;

  return calculateScore2(sex, age, sys, non, smoker);
}
