import { Stripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import { Cart } from "render/views/checkout/_CheckoutContext/hooks/useUpdateCartMutation/useUpdateCartMutation";
import {
  getPaymentRequest,
  getWallet,
} from "render/views/checkout/_CheckoutContext/lib/stripe";

export function usePaymentRequestQuery({
  cart,
  stripe,
}: {
  stripe: Stripe | null;
  cart: Cart | undefined;
}) {
  return useQuery({
    queryFn: async () => {
      if (stripe == null || cart == null) {
        throw Error();
      }
      const paymentRequest = getPaymentRequest(stripe, cart);
      const wallet = await getWallet(paymentRequest);

      return {
        paymentRequest,
        wallet,
      };
    },
    queryKey: ["payment-request", cart, stripe != null],
    enabled: stripe != null && cart != null,
  });
}
