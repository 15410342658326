import { BMI } from "@cur8/health-risks-calc";
import { calcAge } from "lib/age";
import { calcBMI } from "lib/scores/bmi";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import {
  useHeightMetric,
  useWeightMetric,
} from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function useNumOfBMIScans() {
  const { data } = useWeightMetric();

  return data?.length || 1;
}

export function useBMIValues({ scanNum }: { scanNum: number | undefined }) {
  const { data: patient, isLoading: isPatientLoading } = usePatientQuery();
  const { data: weight, isLoading: isWeightLoading } = useWeightMetric();
  const { data: height, isLoading: isHeightLoading } = useHeightMetric();

  if (isPatientLoading || isWeightLoading || isHeightLoading) {
    return "loading";
  }

  if (!weight || !height || !patient) {
    return null;
  }

  const { cur: curWeight, prev: prevWeight } = getMetricHistory(
    weight,
    scanNum
  );
  const { cur: curHeight, prev: prevHeight } = getMetricHistory(
    height,
    scanNum
  );

  const value =
    curHeight != null && curWeight != null
      ? Math.round(calcBMI(curWeight.unit, curHeight.unit))
      : undefined;

  const previousValue =
    prevWeight != null && prevHeight != null
      ? Math.round(calcBMI(prevWeight.unit, prevHeight.unit))
      : prevWeight != null && curHeight != null
        ? Math.round(calcBMI(prevWeight.unit, curHeight.unit))
        : undefined;

  const age = patient?.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;
  const riskRanges = BMI.rangesFor({ age });
  const metricRating =
    value != null ? riskRanges.findRisk({ bmi: value }) : undefined;

  return {
    value,
    previousValue,
    subLabel: <Trans.MetricUnit />,
    metricRating,
    scanDate: curWeight?.measurement.timestampStart,
    ranges: undefined,
  };
}
