import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

const queryKey = (patientId: string) => ["attended-visits", patientId] as const;

const queryFn = async (apiClient: APIClient, patientId: string) => {
  const attendedStatus = [
    APITypesV1.VisitStatus.Ongoing,
    APITypesV1.VisitStatus.Completed,
  ] as const;

  return Promise.all(
    attendedStatus.map((status) => {
      return apiClient.visit.getPatientVisits(
        { patientId },
        { status, pageSize: 1, order: APITypesV1.SortOrder.Desc }
      ).result;
    })
  )
    .then((requests) => {
      return requests.reduce<APITypesV1.Visit[]>((acc, request) => {
        acc.push(...request.items);
        return acc;
      }, []);
    })
    .then((visits) => {
      return visits.map(fromAPI.toVisit);
    })
    .then((visits) => {
      return visits.at(0) ?? null;
    });
};

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useLatestAttendedVisitQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: () => queryFn(apiClient, patientId),
    queryKey: queryKey(patientId),
  });
}
