import { Metric } from "lib/api/metrics";
import { getHighestSide } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getHighestSide";

interface GetBySideProps {
  left?: Metric<"body.grip_strength.left">;
  right?: Metric<"body.grip_strength.right">;
}

export function pickHighestSide({
  left: leftGripStrength,
  right: rightGripStrength,
}: GetBySideProps) {
  const highestGripStrengthSide = getHighestSide({
    left: leftGripStrength?.unit.kilograms,
    right: rightGripStrength?.unit.kilograms,
  });

  return {
    gripStrength:
      highestGripStrengthSide === "left" ? leftGripStrength : rightGripStrength,
    side: highestGripStrengthSide,
  };
}
