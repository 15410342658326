import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { Fade } from "@pomle/react-viewstack-transitions";
import { useEffect, useState } from "react";
import { useIsQuestionnaireCompleteQuery } from "render/hooks/api/queries/useQuestionnaireQuery";
import { useValidPatientConsentsQuery } from "render/hooks/api/queries/useValidPatientConsentsQuery";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { VideoBackground } from "render/views/_shared/VideoBackground";
import { AtriumOnboarding } from "./components/AtriumOnboarding";
import { AtriumOnboardingCompleted } from "./components/AtriumOnboardingCompleted";

enum ViewActiveState {
  Loading,
  OnboardingInProgress,
  Complete,
}

export function AtriumSignIn() {
  const [activeState, setActiveState] = useState<ViewActiveState>(
    ViewActiveState.Loading
  );
  const onboardingQuery = useIsQuestionnaireCompleteQuery();
  const patientConsentsQuery = useValidPatientConsentsQuery();

  const [onboardingComplete, setOnboardingComplete] = useState(false);

  useEffect(() => {
    const isFetching = !onboardingQuery.isFetched || !patientConsentsQuery.data;
    if (isFetching) {
      setActiveState(ViewActiveState.Loading);
      return;
    }

    if (!onboardingComplete) {
      setActiveState(ViewActiveState.OnboardingInProgress);
      return;
    }

    setActiveState(ViewActiveState.Complete);
  }, [
    onboardingQuery,
    patientConsentsQuery.data,
    onboardingComplete,
    activeState,
  ]);

  return (
    <FullScreenPageLayout disableBackground>
      <ViewStack>
        <Fade active={activeState === ViewActiveState.Loading}>
          <FullScreenPageLayout disableBackground />
        </Fade>
        <Fade active={activeState === ViewActiveState.OnboardingInProgress}>
          <AtriumOnboarding
            onSuccess={() => {
              setOnboardingComplete(true);
            }}
          />
        </Fade>
        <Fade active={activeState === ViewActiveState.Complete}>
          <Sticky>
            {activeState === ViewActiveState.Complete && (
              <AtriumOnboardingCompleted />
            )}
          </Sticky>
        </Fade>
      </ViewStack>
      <VideoBackground />
    </FullScreenPageLayout>
  );
}
