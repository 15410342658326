import { Action, Event } from "render/hooks/useTracking";

const category = "Profile";

export const profileEvent = {
  signOutClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "sign_out_button",
    };
  },
  withdrawConsentClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "withdraw_button",
    };
  },
  backButtonClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "back_button",
    };
  },
  clickTab: (tab: string): Event => {
    return {
      category,
      action: Action.Click,
      label: "tab_navigation",
      value: tab,
    };
  },
  changeNumber: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "change_number_in_account_service",
    };
  },
};
