import { Typography } from "@cur8/maneki";
import { Marker } from "render/ui/presentation/ChartMarker/Marker";
import { concatPercent, normalize } from "./lib/util";
import styles from "./styles.module.sass";
import { MarkerData, RangeData } from "./types";

export const inRange = (
  val: number,
  min: number,
  max: number,
  { includeMax = false }: { includeMax: boolean }
) => {
  if (includeMax) {
    return val >= min && val <= max;
  }

  return val >= min && val < max;
};

interface RangeChartProps {
  ranges: RangeData[];
  values: MarkerData[];
}

export function RangeChart({ ranges, values }: RangeChartProps) {
  const sortedRanges = ranges.sort((a, b) => a.to - b.to);
  const gridTemplateColumns = sortedRanges
    .map((range) => `${range.width}fr`)
    .join(" ");

  return (
    <div
      className={styles.RangeChart}
      style={{
        gridTemplateColumns,
      }}
    >
      {sortedRanges.map((range, indexOfRange, arrayOfRanges) => {
        const isLastRangeInChart = indexOfRange === arrayOfRanges.length - 1;
        const markersWithinRange = values.filter((marker) =>
          inRange(marker.value, range.from, range.to, {
            includeMax: isLastRangeInChart,
          })
        );

        const hasPrimaryMarkerWithinRange = markersWithinRange.some(
          ({ variant }) =>
            variant === "primary" || variant === "primary-outlined"
        );

        return (
          <div
            className={styles.Container}
            key={`range_${range.from}_${range.to}`}
          >
            <div
              className={styles.Range}
              data-variant={
                hasPrimaryMarkerWithinRange ? range.variant : "none"
              }
            >
              {markersWithinRange.map((marker) => {
                const ratioToTotal =
                  (marker.value - range.from) / (range.to - range.from);
                const shouldClampRight = ratioToTotal > 0.9;

                return (
                  <div
                    className={styles.Marker}
                    style={
                      {
                        "--left": concatPercent(
                          normalize(marker.value, {
                            min: range.from,
                            max: range.to,
                          }) * 100
                        ),
                      } as React.CSSProperties
                    }
                    key={marker.key}
                    data-variant={marker.variant}
                    data-single={values.length === 1 ? true : undefined}
                    data-clamp-right={shouldClampRight}
                  >
                    <Marker
                      variant={marker.variant}
                      highlight={range.variant}
                    />
                    <div
                      className={styles.value}
                      data-marker-variant={marker.variant}
                      data-range-variant={range.variant}
                      data-marker-single={
                        values.length === 1 ? true : undefined
                      }
                    >
                      <Typography variant="eyebrow-s">
                        {marker.label}
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className={styles.RangeLabels}
              data-variant={
                hasPrimaryMarkerWithinRange ? range.variant : "none"
              }
            >
              <Typography variant="eyebrow-s">{range.label}</Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
}
