import { useQueryParams } from "@pomle/react-router-paths";
import { queries } from "render/routes/paths";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { useEyePressureValues } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEyePressure/useEyePressureValues";
import { useEyePressureMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricEyePressureDetails() {
  const [params, setParams] = useQueryParams(queries.metric);
  const { data } = useEyePressureMetric();

  const side = params.side[0] ?? "left";
  const numOfScansLeft = data?.left.length || 1;
  const numOfScansRight = data?.right.length || 1;
  const numOfScans = side === "left" ? numOfScansLeft : numOfScansRight;

  const scanNum = params.scan[0] ?? numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });
  const setSide = (side: "left" | "right") =>
    setParams({
      side: [side],
    });
  const values = useEyePressureValues({ scanNum, side });

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      side={side}
      setSide={setSide}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
    />
  );
}
