import { Question } from "@cur8/questionnaire";
import React from "react";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { StepView } from "../StepView";

interface QuestionViewProps {
  question: Question<unknown, React.ReactNode>;
  onPrev?: () => void;
  onNext: () => void;
  children: React.ReactNode;
  currentQuestion: number;
  totalQuestionCount: number;
  cta?: string | JSX.Element;
  explanation?: {
    openText: string | JSX.Element;
    explanationText: string | JSX.Element;
    closeText: string | JSX.Element;
  };
  hideProgressBar?: boolean;
}

export function QuestionView({
  question,
  onNext,
  onPrev,
  children,
  currentQuestion,
  totalQuestionCount,
  cta,
  explanation,
  hideProgressBar,
}: QuestionViewProps) {
  return (
    <StepView
      hideProgressBar={hideProgressBar}
      totalQuestionCount={totalQuestionCount}
      currentQuestion={currentQuestion}
      caption={question.statement}
      onPrev={onPrev}
      explanation={explanation}
      cta={
        cta ? (
          <ActionButton
            onClick={onNext}
            form={String(currentQuestion)}
            variant="suggestion"
          >
            {cta}
          </ActionButton>
        ) : null
      }
    >
      {children}
    </StepView>
  );
}
