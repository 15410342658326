export function createTimer(onRender: (deltaTime: number) => void) {
  let stopped = false;
  let lastTime = NaN;

  function update(time: number) {
    if (stopped) {
      return;
    }

    if (lastTime) {
      const deltaTime = time - lastTime;
      onRender(deltaTime);
    }

    lastTime = time;

    requestAnimationFrame(update);
  }

  requestAnimationFrame(update);

  return () => {
    stopped = true;
  };
}
