import { Typography } from "@cur8/maneki";

export function formatToDesignSystem(elements: HTMLCollectionOf<Element>) {
  return Array.from(elements)
    .filter((element) => element.tagName in variants)
    .map((element) => {
      const variant = variants[element.tagName];
      const parsedInnerHTML = element.innerHTML.replace(
        /href="/g,
        `target="_blank" rel="noreferrer" href="`
      );
      return (
        <Typography key={parsedInnerHTML} {...variant}>
          <span dangerouslySetInnerHTML={{ __html: parsedInnerHTML }} />
        </Typography>
      );
    });
}

const variants: Record<
  string,
  Pick<React.ComponentProps<typeof Typography>, "variant" | "color">
> = {
  P: { variant: "body-m", color: "subtle" },
  H1: { variant: "title-m", color: "default" },
  H2: { variant: "title-s", color: "default" },
};
