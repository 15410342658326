import { PaymentRequestPaymentMethodEvent, Stripe } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import { useConfig } from "render/context/ConfigContext";
import { useSession } from "render/context/MSALContext/MSALContext";
import { Checkout } from "render/hooks/mutations/useInitializeCheckoutMutation";

export function useWalletSubmitMutation() {
  const config = useConfig();
  const { patientId } = useSession();
  return useMutation({
    mutationFn: async ({
      ev,
      stripe,
      checkout,
    }: {
      ev: PaymentRequestPaymentMethodEvent;
      stripe: Stripe;
      checkout: Checkout;
    }) => {
      const callCompleteCheckout = async () => {
        let completeCheckoutLocation = `${config.apiBaseUrl}/api/v2/patient/${patientId}/stripe/complete-checkout?redirect_status=succeeded`;
        completeCheckoutLocation += `&payment_intent=${checkout.checkoutData.intentId}&payment_intent_client_secret=${checkout.checkoutData.clientSecret}`;
        return new Promise((resolve) => {
          window.location.href = completeCheckoutLocation;
          setTimeout(resolve, 5000);
        });
      };
      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          checkout.checkoutData.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: true }
        );
      if (confirmError) {
        ev.complete("fail");
        throw confirmError;
      } else {
        ev.complete("success");
        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(
            checkout.checkoutData.clientSecret
          );
          if (error) {
            throw error;
          } else {
            await callCompleteCheckout();
          }
        } else {
          await callCompleteCheckout();
        }
      }
    },
  });
}
