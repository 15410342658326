import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Triglycerides</>,
  sv_SE: <>Triglycerider</>,
});

const Risk = {
  Normal: localize({
    en_GB: <>Normal</>,
    sv_SE: <>Normal</>,
  }),
  Risk: localize({
    en_GB: <>High</>,
    sv_SE: <>Hög</>,
  }),
};

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        Triglycerides are a type of fat found in your blood. When you eat, your
        body converts any calories it doesn't need to use immediately into
        triglycerides stored in your fat cells. High levels of triglycerides
        contribute to the hardening and thickening of arterial walls, increasing
        the risk of stroke, heart attack, and angina.
      </>
    ),
    sv_SE: (
      <>
        Triglycerider är en typ av fett som finns i ditt blod. När du äter
        omvandlar din kropp alla kalorier den inte omedelbart behöver använda
        till triglycerider som lagras i dina fettceller. Höga nivåer av
        triglycerider bidrar till förkalkning och förträngning av kärlväggar,
        vilket ökar risken för stroke, hjärtinfarkt och kärlkramp.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        High triglyceride levels, particularly when they are combined with low
        HDL cholesterol or high LDL cholesterol, increase the risk of
        atherosclerosis and related cardiovascular diseases. Elevated
        triglycerides can also be a marker for other conditions that increase
        the risk of heart disease and stroke, such as obesity and metabolic
        syndrome. Very high triglyceride levels increase the risk of
        pancreatitis.
      </>
    ),
    sv_SE: (
      <>
        Höga nivåer av triglycerider, särskilt när de kombineras med lågt
        HDL-kolesterol eller högt LDL-kolesterol, ökar risken för
        åderförkalkning och relaterade hjärt-kärlsjukdomar. Förhöjda
        triglyceridnivåer kan också vara en markör för andra tillstånd som ökar
        risken för hjärtsjukdom och stroke, såsom fetma och metaboliskt syndrom.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        Low levels of triglycerides are generally considered a sign of good
        health and mean that you're less likely to have high amounts of fat
        circulating in your blood. This scenario is associated with a lower risk
        of heart disease and stroke. Extremely low levels could indicate other
        health issues, such as malabsorption or malnutrition, and should be
        evaluated by a healthcare professional.
      </>
    ),
    sv_SE: (
      <>
        Låga nivåer av triglycerider anses generellt vara ett tecken på god
        hälsa och innebär att du är mindre benägen att ha höga mängder fett som
        cirkulerar i ditt blod. Denna situation är förknippad med en lägre risk
        för hjärtsjukdom och stroke. Extremt låga nivåer kan indikera andra
        hälsoproblem, såsom malabsorption eller undernäring.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Tracking the trend of your triglyceride levels over time can help you
        understand the impact of lifestyle changes or medication on your health.
        A decreasing trend in triglyceride levels can mean that lifestyle
        changes, such as improved diet or more exercise, are effectively
        reducing your cardiovascular risk. Conversely, an upward trend might
        signal a need to re-evaluate your dietary habits or physical activity
        levels.
      </>
    ),
    sv_SE: (
      <>
        Att följa trenden för dina triglyceridnivåer över tid kan hjälpa dig att
        förstå effekterna av livsstilsförändringar eller medicinering. En
        minskande trend i triglyceridnivåer kan innebära att
        livsstilsförändringar, såsom förbättrad kost eller mer motion, effektivt
        minskar din kardiovaskulära risk. En uppåtgående trend kan signalera ett
        behov av att omvärdera dina kostvanor eller fysiska aktivitet.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        The optimal level for triglycerides is less than 2.3 mmol/L. If you
        fasted before your blood test, then it should be below 1.7 mmol/L.
        Maintaining your triglycerides in the optimal range can help minimise
        your risk of cardiovascular disease and other related health issues.
      </>
    ),
    sv_SE: (
      <>
        Den optimala nivån för triglycerider är mindre än 2,3 mmol/L. Om du har
        fastat innan ditt blodprov ska det vara under 1,7 mmol/L. Att hålla dina
        triglyceridnivåer inom det optimala intervallet kan hjälpa till att
        minimera risken för hjärt-kärlsjukdom och andra relaterade hälsoproblem.
      </>
    ),
  }),
};

export const Trans = { MetricName, Risk, FAQ };
