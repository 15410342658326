import { useCallback, useEffect, useMemo, useRef } from "react";

export function useTimeOut(delay: number, callback?: () => void) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<number>();

  const startTimeout = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      callbackRef.current?.();
      timeoutRef.current = undefined;
    }, delay);
  }, [delay]);

  const clearTimeout = useCallback(() => {
    if (!timeoutRef.current) return;
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  }, []);

  const pending = useCallback(() => Boolean(timeoutRef.current), []);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => clearTimeout, [clearTimeout]);

  return useMemo(
    () => ({
      startTimeout,
      clearTimeout,
      pending,
    }),
    [startTimeout, clearTimeout, pending]
  );
}
