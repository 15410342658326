import { Typography } from "@cur8/maneki";
import { ReactComponent as CloseIcon } from "assets/icons/24x24/24x24_close.svg";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { SharedTrans } from "render/views/trans";
import background from "./background.jpg";
import styles from "./styles.module.sass";
import * as Trans from "./trans";

interface OnboardingStudiesSummaryViewProps {
  onClose: () => void;
  onNext: () => void;
}

export function OnboardingStudiesSummaryView({
  onClose,
  onNext,
}: OnboardingStudiesSummaryViewProps) {
  return (
    <FullScreenPageLayout className={styles.container}>
      <div className={styles.sticky}>
        <LogoHeader
          leftElement={
            <IconButton
              ariaLabel={SharedTrans.Close()}
              onClick={onClose}
              icon={<CloseIcon />}
            />
          }
        />
      </div>

      <div className={styles.body}>
        <div className={styles.content}>
          <Typography variant="display-s">
            <Trans.Title />
          </Typography>

          <img alt="studies" className={styles.image} src={background} />

          <Typography variant="body-m" color="subtle">
            <Trans.Paragraph1 />
          </Typography>
          <Typography variant="body-m" color="subtle">
            <Trans.Paragraph2 />
          </Typography>
        </div>
        <ActionButton variant="suggestion" onClick={onNext}>
          <Typography variant="label-m">
            <Trans.Next />
          </Typography>
        </ActionButton>
      </div>
    </FullScreenPageLayout>
  );
}
