import {
  Answer,
  ChoiceQuestion,
  ChoiceResponse,
  createChoiceAnswer,
  isChoiceResponse,
} from "@cur8/questionnaire";
import { useCallback } from "react";
import { Radio } from "../Radio";
import styles from "./styles.module.sass";

interface ChoiceInputWithDescriptionsProps<Value, Statement, ChoiceStatement> {
  question: ChoiceQuestion<Value, Statement, ChoiceStatement>;
  answer?: Answer;
  onAnswer: (answer: Answer<ChoiceResponse>) => void;
  descriptions?: Array<JSX.Element | string>;
}

export function ChoiceInputWithDescriptions<
  Value,
  Statement extends React.ReactNode,
  ChoiceStatement extends Record<"description" | "label", React.ReactNode>,
>({
  question,
  answer,
  onAnswer,
}: ChoiceInputWithDescriptionsProps<Value, Statement, ChoiceStatement>) {
  let pickedIndex: number;

  if (answer && isChoiceResponse(answer.response)) {
    pickedIndex = answer.response.choiceIndex;
  }

  const handleChoice = useCallback(
    (choiceIndex: number) => {
      const answer = createChoiceAnswer(choiceIndex);
      onAnswer(answer);
    },
    [onAnswer]
  );
  return (
    <fieldset data-hj-suppress className={styles.ChoiceInput}>
      {question.choices.map((choice, choiceIndex) => {
        const { label, description } = choice.statement;

        return (
          <Radio
            choiceIndex={choiceIndex}
            pickedIndex={pickedIndex}
            choiceDescription={description}
            choice={{ statement: label, value: choice.value }}
            handleChoice={handleChoice}
          />
        );
      })}
    </fieldset>
  );
}
