import Cookies from "js-cookie";
import { FALLBACK_LOCALE } from "render/context/InternationalizationContext/InternationalizationContext";
import { COOKIE_NAME } from "../../utils";
import { getNavigatorLang } from "../getNavigatorLang";
import { language2Locale } from "../language2Locale";

export function getInitialLanguage() {
  // Detection order: User (It's handlend in AuthSessionLanguageSetter), Cookie, Browser
  const cookie = Cookies.get(COOKIE_NAME);
  const navLoc = language2Locale(getNavigatorLang());

  if (cookie) {
    const cookieLoc = language2Locale(cookie);
    if (cookieLoc) {
      return cookieLoc;
    }
  }

  if (navLoc) {
    return navLoc;
  }

  return FALLBACK_LOCALE;
}
