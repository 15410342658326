import { localize } from "render/context/InternationalizationContext";
import styles from "render/views/CheckoutSuccessView/styles.module.sass";

const Thanks = localize({
  en_GB: <>Thanks</>,
  sv_SE: <>Tack</>,
});

const Place = localize<{ place: JSX.Element }>({
  en_GB: ({ place }) => <>Place: {place}</>,
  sv_SE: ({ place }) => <>Plats: {place}</>,
});

const Time = localize<{ time: number }>({
  en_GB: ({ time }) => (
    <>
      Time: <span className={styles.bolded}>{time} minutes</span>
    </>
  ),
  sv_SE: ({ time }) => (
    <>
      Tid: <span className={styles.bolded}>{time} minuter</span>
    </>
  ),
});

const Error = {
  Unexpected: localize({
    en_GB: <>An unexpected error has occurred</>,
    sv_SE: <>Ett oväntat fel uppstod</>,
  }),
};

const ContactUs = localize({
  en_GB: "Contact us",
  sv_SE: "Kontakta oss",
});

const Close = localize({
  en_GB: "Close",
  sv_SE: "Stänga",
});

export const SharedTrans = {
  Thanks,
  Time,
  Place,
  Error,
  ContactUs,
  Close,
};
