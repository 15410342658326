import styles from "./styles.module.sass";

export function HeaderLayout({
  title,
  subTitle,
}: {
  title: JSX.Element;
  subTitle?: JSX.Element[];
}) {
  return (
    <div className={styles.HeaderLayout}>
      <div className={styles.title}>{title}</div>
      {subTitle && (
        <div className={styles.subTitleContainer}>
          {subTitle.map((s, i) => (
            <div key={i} className={styles.subTitle}>
              {s}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
