import { APITypesV1 } from "@cur8/api-client";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";

export type Cart = Required<APITypesV1.Cart>;

export function useUpdateCartMutation() {
  const apiClient = useAPIClient();
  const { patientId } = useSession();
  const mutationFn = useCallback(
    async (payload: APITypesV1.UpdatePatientCartRequest) => {
      return apiClient.billing
        .updateCart(patientId, payload)
        .result.then((body) => body as Cart);
    },

    [apiClient, patientId]
  );

  return useMutation({ mutationFn });
}
