import { PathLink } from "@pomle/react-router-paths";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { paths } from "render/routes/paths";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useWeightMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { WeightMetric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";
import { ReactNode } from "react";
import { changeLabel, weightValue } from "./lib";

export function MetricWeight() {
  const patientQuery = usePatientQuery();
  const weightMetricQuery = useWeightMetric();

  if (!patientQuery.data || weightMetricQuery.isPending) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!weightMetricQuery.data) {
    return null;
  }

  const { cur: current, prev: previous } = getMetricHistory(
    weightMetricQuery.data
  );

  const diff =
    current && previous
      ? current.unit.kilograms - previous.unit.kilograms
      : undefined;

  let note: undefined | ReactNode[];
  if (diff != null) {
    note = [
      changeLabel({ diff: { kilograms: diff } }),
      weightValue({
        patient: patientQuery.data,
        value: { kilograms: diff },
      }).reduce<ReactNode[]>((acc, prev) => {
        if (acc.length > 0) {
          acc.push(<>&nbsp;</>);
        }
        acc.push(prev[0], <>&nbsp;</>, prev[1]);

        return acc;
      }, []),
    ];
  }

  return (
    <PathLink to={paths.weightMetric.url({})}>
      <WeightMetric
        label={<Trans.MetricName />}
        isClinicalStudy={false}
        description={weightValue({
          patient: patientQuery.data,
          value: current?.unit,
        })}
        note={note}
      />
    </PathLink>
  );
}
