interface DataRangeProps {
  min: number;
  max: number;
  color: string;
  delay?: string;
  duration: number;
}

export function DataRange({
  color,
  max,
  min,
  delay,
  duration,
}: DataRangeProps) {
  const to = Math.abs(max - min);
  return (
    <rect x="calc(50% - 2.5px)" y={`${max}%`} width={"5px"} fill={color} rx="2">
      <animate
        attributeName="height"
        begin={delay ?? "0s"}
        dur={`${duration}s`}
        repeatCount="1"
        fill="freeze"
        values={`0%;${to}%`}
        keyTimes="0;1"
        calcMode="spline"
        keySplines=".17,.67,.83,.67"
      />
    </rect>
  );
}
