import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Eye pressure</>,
  sv_SE: <>Ögontryck</>,
});

const MetricLabel = localize({
  en_GB: <>mmHg</>,
  sv_SE: <>mmHg</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        Your eye pressure is important because it tells us about the health of
        the interior of your eyes. Having high pressure inside the eye increases
        the risk of developing glaucoma, a chronic eye disease that leads to
        irreversible visual field loss. However, high eye pressure does not
        always lead to glaucoma and eye damage.
      </>
    ),
    sv_SE: (
      <>
        Ditt ögontryck är viktigt eftersom det berättar om hälsan i ögonens
        inre. Att ha högt tryck inuti ögat ökar risken för att utveckla glaukom,
        en kronisk ögonsjukdom som leder till oåterkallelig synfältsförlust.
        Högt ögontryck leder dock inte alltid till glaukom och ögonskador.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        The normal eye pressure for most people should not exceed 24 mmHg. If it
        is higher than this, it is advised to undergo further investigation with
        an eye doctor.
      </>
    ),
    sv_SE: (
      <>
        Det normala ögontrycket för de flesta bör inte överstiga 24 mmHg. Om det
        är högre än så rekommenderas att genomgå ytterligare utredning hos en
        ögonläkare.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        Low eye pressure can sometimes be a sign that you have problems with the
        health of your eyes. In most people, the eye pressure shouldn’t be less
        than 8 mmHg. It is usually secondary to previous eye surgery or
        medication.
      </>
    ),
    sv_SE: (
      <>
        Lågt ögontryck kan ibland vara ett tecken på att dina ögons hälsa inte
        är optimalt. Hos de flesta bör ögontrycket inte vara mindre än 8 mmHg.
        Det är vanligtvis sekundärt till tidigare ögonoperationer eller
        medicinering.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Monitoring your eye pressure over time can help you to understand
        whether you are at risk of developing glaucoma in the future.
      </>
    ),
    sv_SE: (
      <>
        Att övervaka ditt ögontryck över tid kan hjälpa dig att förstå om du
        löper risk att utveckla glaukom i framtiden.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        Your eye pressure should be between 8 and 24 mmHg. There is no real way
        of affecting this why monitoring it with qualitative methods is
        recommended.
      </>
    ),
    sv_SE: (
      <>
        Ditt ögontryck bör ligga mellan 8 och 24 mmHg och det finns inget
        riktigt sätt att påverka detta, varför vi rekommenderar monitorering med
        kvalitetssäkrade metoder.
      </>
    ),
  }),
};

export const Trans = { MetricName, MetricLabel, FAQ };
