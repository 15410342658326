import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>HDL</>,
  sv_SE: <>HDL</>,
});

const MetricUnit = localize({
  en_GB: <>mmol/L</>,
  sv_SE: <>mmol/L</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        HDL cholesterol, often referred to as healthy cholesterol, picks up
        excess cholesterol in your body tissues. It delivers cholesterol to
        tissues with high cholesterol requirements, such as the organs which
        make hormones like cortisol, oestradiol and testosterone. HDL also
        transports excess cholesterol to the liver for disposal. HDL helps to
        protect your body from the effects of unhealthy forms of cholesterol
        such as LDL, VLDL and IDL, collectively known as non-HDL cholesterol.
      </>
    ),
    sv_SE: (
      <>
        HDL-kolesterol, ofta kallat hälsosamt kolesterol, plockar upp överskott
        av kolesterol i dina kroppsvävnader. Det levererar kolesterol till
        vävnader med höga krav på kolesterol, såsom organen som producerar
        hormoner som kortisol, östrogen och testosteron. HDL transporterar också
        överskott av kolesterol till levern för bortskaffande. HDL hjälper till
        att skydda din kropp från effekterna av ohälsosamma former av kolesterol
        såsom LDL, VLDL och IDL, som tillsammans kallas non-HDL-kolesterol.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        Higher levels of HDL cholesterol are usually a sign of good health, as
        they are associated with lower risks of having a heart attack or stroke.
        This is because HDL helps to clear cholesterol from your arteries,
        preventing the build-up that can lead to heart attacks and stroke.
      </>
    ),
    sv_SE: (
      <>
        Högre nivåer av HDL-kolesterol är vanligtvis en indikation på god hälsa,
        eftersom de är förknippade med lägre risker för hjärtinfarkt eller
        stroke. Detta beror på att HDL hjälper till att rensa kolesterol från
        dina artärer, vilket förhindrar uppkomsten av plack som kan leda till
        hjärtinfarkt och stroke.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        Low HDL cholesterol levels could increase your risk of heart disease.
        Since HDL assists in transporting cholesterol away from the arteries and
        back to the liver for disposal, low levels mean cholesterol can
        accumulate, potentially leading to blocked arteries and associated
        health problems.
      </>
    ),
    sv_SE: (
      <>
        Låga nivåer av HDL-kolesterol kan öka risken för hjärtsjukdom. Eftersom
        HDL hjälper till att transportera bort kolesterol från artärerna och
        tillbaka till levern för rensning, innebär låga nivåer att kolesterol
        kan ansamlas, vilket potentiellt kan leda till kärlsjuka och relaterade
        hälsoproblem.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Monitoring the trend of your HDL cholesterol levels can help with
        assessing the impact of lifestyle changes, such as adopting a healthier
        diet or increasing physical activity. An upward trend in HDL levels is
        often a sign that these changes are positively affecting your
        cardiovascular health.
      </>
    ),
    sv_SE: (
      <>
        Att övervaka trenden för dina HDL-kolesterolnivåer kan hjälpa till att
        bedöma effekterna av livsstilsförändringar, såsom att äta en
        hälsosammare kost eller öka fysisk aktivitet. En uppåtgående trend i
        HDL-nivåer är ofta ett tecken på att dessa förändringar har en positiv
        effekt på din hjärt-kärlhälsa.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        Aiming for higher HDL cholesterol levels will help to minimise your risk
        of developing heart disease and stroke. The optimal level for women is
        1.8 to 2.5 mmol/L, and for men it is 1.4 to 2.0 mmol/L. Providing your
        HDL level falls within this range you will be getting the maximum
        protective effect from it. It is not clear whether you continue to get
        incremental benefits by aiming for levels higher than this, with some
        research showing increasing risks of developing illness with very high
        levels.
      </>
    ),
    sv_SE: (
      <>
        Att sikta på högre HDL-kolesterolnivåer kommer att hjälpa till att
        minimera risken för att utveckla hjärtsjukdom och stroke. Den optimala
        nivån för kvinnor är 1,8 till 2,5 mmol/L, och för män är det 1,4 till
        2,0 mmol/L. Om din HDL-nivå faller inom detta intervall kommer du att få
        maximal skyddseffekt från det. Det är inte helt tydligt om du fortsätter
        att få ökat skydd genom att sikta på nivåer högre än detta. Viss
        forskning visar på ökande risk för hjärt-kärlsjukdom med mycket höga
        nivåer.
      </>
    ),
  }),
};

export const Trans = { MetricName, MetricUnit, FAQ };
