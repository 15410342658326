import { BookableSlot } from "@cur8/rich-entity";
import { useCallback } from "react";
import { BookableTimeSlot } from "./components/BookableTimeSlot";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface ListViewProps {
  selectedSlotId: string | undefined;
  onSelect: (slot: BookableSlot) => void;
  onSiteResetClick: () => void;
  allSitesSelected: boolean;
  slots: BookableSlot[] | undefined;
}

export function ListView({
  onSelect,
  onSiteResetClick,
  selectedSlotId,
  allSitesSelected,
  slots,
}: ListViewProps) {
  const select = useCallback(
    (slot: BookableSlot) => () => {
      return onSelect(slot);
    },
    [onSelect]
  );
  const isSelected = useCallback(
    (slot: BookableSlot) => {
      return slot.slotId === selectedSlotId;
    },
    [selectedSlotId]
  );

  if (!slots) {
    return null;
  }

  return (
    <div className={styles.ListView}>
      {slots.length === 0 && (
        <div className={styles.empty}>
          {allSitesSelected && (
            <>
              <div className={styles.head}>
                <Trans.AllSites.Header />
              </div>
              <div className={styles.body}>
                <Trans.AllSites.Description />
              </div>
            </>
          )}
          {!allSitesSelected && (
            <>
              <div className={styles.head}>
                <Trans.Site.Header />
              </div>
              <div className={styles.body}>
                <Trans.Site.Description />
              </div>
              <button onClick={onSiteResetClick} className={styles.link}>
                <Trans.Site.ResetSites />
              </button>
            </>
          )}
        </div>
      )}
      {slots.length > 0 && (
        <ul>
          {slots.map((slot) => {
            return (
              <li key={slot.slotId}>
                <BookableTimeSlot
                  isSelected={isSelected(slot)}
                  onClick={select(slot)}
                  slot={slot}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
