import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: (
    <>
      Thanks! We are currently creating an appointment for your Neko Body scan.
    </>
  ),
  sv_SE: <>Tack! Vi skapar just nu en tid för din Neko Body scan.</>,
});

const SubTitle = localize({
  en_GB: <>Your visit will appear shortly.</>,
  sv_SE: <>Ditt besök kommer att synas inom kort.</>,
});

export const Trans = { Title, SubTitle };
