import { APITypesV1 } from "@cur8/api-client";
import { PathRoute } from "@pomle/react-router-paths";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { Direction, Fade } from "@pomle/react-viewstack-transitions";
import { useIdentityVerificationStatus } from "render/hooks/api/queries/useIdentityVerificationStatus";
import { paths } from "render/routes/paths";
import { mount } from "render/routes/routes/SessionRoutes/lib/mount";
import { ReportView } from "render/views/Report/AppointmentDataView";
import { AppointmentIdentityRequiredView } from "render/views/Report/AppointmentIdentityRequiredView";

function IdentityVerificationWall() {
  const identityVerificationStatus = useIdentityVerificationStatus();

  const isReady = identityVerificationStatus.data != null;
  const hasVerifiedIdentity =
    identityVerificationStatus.data?.identityVerificationStatus ===
    APITypesV1.IdentityVerificationStatus.Verified;

  return (
    <ViewStack>
      <Fade active={isReady && !hasVerifiedIdentity}>
        <Sticky>
          {!hasVerifiedIdentity && <AppointmentIdentityRequiredView />}
        </Sticky>
      </Fade>
      <Fade active={isReady && hasVerifiedIdentity}>
        <Sticky>{hasVerifiedIdentity && <ReportView />}</Sticky>
      </Fade>
    </ViewStack>
  );
}

export function ReportRoutes() {
  return (
    <>
      <PathRoute path={paths.report}>
        {mount(IdentityVerificationWall, {
          animation: "slide",
          direction: Direction.Down,
        })}
      </PathRoute>
      <PathRoute path={paths.appointmentSummary}>
        {mount(IdentityVerificationWall, {
          animation: "slide",
          direction: Direction.Down,
        })}
      </PathRoute>
    </>
  );
}
