export function splitText(text: string, maxWidth: number, isMobile: boolean) {
  const words = text.split(" ");
  const container = document.createElement("p");

  if (isMobile) {
    container.style.fontFamily = "IBM Plex Sans Regular";
    container.style.fontSize = "28px";
    container.style.fontStyle = "normal";
    container.style.fontWeight = "300";
    container.style.lineHeight = "32px";
    container.style.letterSpacing = "-1px";
  } else {
    container.style.fontFamily = "Orleans Roman";
    container.style.fontSize = "40px";
    container.style.fontStyle = "normal";
    container.style.fontWeight = "400";
    container.style.lineHeight = "44px";
  }

  container.style.height = "0";
  container.style.overflowX = "scroll";
  container.style.whiteSpace = "nowrap";
  container.style.position = "absolute";
  container.style.display = "hidden";

  document.body.appendChild(container);

  try {
    const result = words.reduce<string[][]>((acc, word) => {
      if (acc.length === 0) {
        acc.push([]);
      }
      let last = acc[acc.length - 1];

      last.push(word);

      container.innerText = last.join(" ");

      if (container.clientWidth >= maxWidth) {
        last.pop();
        last = [word];
        acc.push(last);
      }

      return acc;
    }, []);

    return result.map((words) => words.join(" "));
  } catch (e) {
    throw e;
  } finally {
    document.body.removeChild(container);
  }
}
