import { Patient } from "@cur8/rich-entity";
import { localize } from "render/context/InternationalizationContext";

const Greeting = localize<{ patient: Patient }>({
  en_GB: ({ patient }) => {
    const name = patient.name?.firstName ? ` ${patient.name.firstName}` : "";
    return <>Welcome{name}, you are ready to book your scan</>;
  },
  sv_SE: ({ patient }) => {
    const name = patient.name?.firstName ? ` ${patient.name.firstName}` : "";
    return <>Välkommen{name}, det är dags att boka din scan</>;
  },
});

const Book = localize({
  en_GB: <>Book your scan</>,
  sv_SE: <>Boka din scan</>,
});

const SlotStatus = {
  Available: localize({
    en_GB: <>Limited slots available</>,
    sv_SE: <>Endast få platser kvar</>,
  }),
  AllTaken: localize({
    en_GB: <>No slots available</>,
    sv_SE: <>Inga tider tillgängliga</>,
  }),
};

const NoToken = {
  Welcome: localize({
    en_GB: <>Welcome to Neko Health!</>,
    sv_SE: <>Välkommen till Neko Health!</>,
  }),

  WhenItsYourTurn: localize({
    en_GB: (
      <>We will contact you when it's your turn to book your Neko Body Scan.</>
    ),
    sv_SE: (
      <>
        Vi kommer att kontakta dig när det är din tur att boka din Neko Body
        Scan.
      </>
    ),
  }),

  BuyButton: localize({
    en_GB: <>Reserve Now</>,
    sv_SE: <>Reservera din tid nu</>,
  }),
};
const ScanPrebooked = localize({
  en_GB: <>Your Neko Body Scan is reserved.</>,
  sv_SE: <>Din Neko Body Scan är reserverad.</>,
});

const WeWillReachYou = localize({
  en_GB: (
    <>
      We will contact you within a couple of weeks to book a time that suits
      you.
    </>
  ),
  sv_SE: (
    <>
      Vi kommer att kontakta dig inom ett par veckor för att boka in en tid som
      passar dig.
    </>
  ),
});

export const Trans = {
  Greeting,
  SlotStatus,
  Book,
  ScanPrebooked,
  NoToken,
  WeWillReachYou,
};
