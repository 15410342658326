import { Typography } from "@cur8/maneki";
import { PathLink } from "@pomle/react-router-paths";
import { ReactComponent as ArrowRightIcon } from "assets/icons/24x24/24x24_arrow-right.svg";
import { ReactNode, useMemo } from "react";
import styles from "./styles.module.sass";

interface LinkToProps {
  href: string;
  head: ReactNode;
  description?: ReactNode;
  variant: "wide" | "square";
}

const VARIANTS: Record<LinkToProps["variant"], string> = {
  wide: styles.variantWide,
  square: styles.variantSquare,
};

export function LinkTo({ href, head, description, variant }: LinkToProps) {
  const className = useMemo(() => {
    return [styles.LinkTo, VARIANTS[variant] ?? ""].join(" ");
  }, [variant]);

  return (
    <PathLink to={href} className={className}>
      <div className={styles.text}>
        <Typography variant="label-m" color="default">
          {head}
        </Typography>
        {description ? (
          <Typography variant="body-s" color="subtle">
            {description}
          </Typography>
        ) : null}
      </div>
      <ArrowRightIcon className={styles.icon} />
    </PathLink>
  );
}
