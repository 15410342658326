import { Typography } from "@cur8/maneki";
import {
  Answer,
  MultiChoiceQuestion,
  MultiChoiceResponse,
  createMultiChoiceAnswer,
  isMultiChoiceResponse,
} from "@cur8/questionnaire";
import { useCallback, useMemo } from "react";
import { Checkbox } from "render/ui/form/Checkbox";
import styles from "./styles.module.sass";

interface MultiChoiceInputProps<T> {
  question: MultiChoiceQuestion<T, React.ReactNode, React.ReactNode>;
  answer?: Answer;
  onAnswer: (answer: Answer<MultiChoiceResponse>) => void;
}

export function MultiChoiceInput<T>({
  question,
  answer,
  onAnswer,
}: MultiChoiceInputProps<T>) {
  const indices = useMemo(() => {
    const indices = [];
    if (answer && isMultiChoiceResponse(answer.response)) {
      indices.push(...answer.response.choiceIndices);
    }
    return new Set<number>(indices);
  }, [answer]);

  const handleChoice = useCallback(
    (choiceIndex: number) => {
      if (indices.has(choiceIndex)) {
        indices.delete(choiceIndex);
      } else {
        indices.add(choiceIndex);
      }

      const answer = createMultiChoiceAnswer([...indices]);

      onAnswer(answer);
    },
    [indices, onAnswer]
  );

  return (
    <fieldset data-hj-suppress className={styles.MultiChoiceInput}>
      {question.choices.map((choice, choiceIndex) => (
        <Checkbox
          key={choiceIndex}
          bordered
          checked={indices.has(choiceIndex)}
          inverted
          name={choice.value as string}
          value={choice.statement as string}
          onChange={() => handleChoice(choiceIndex)}
        >
          <Typography variant="label-m">{choice.statement}</Typography>
        </Checkbox>
      ))}
    </fieldset>
  );
}
