import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Lymphocytes</>,
  sv_SE: <>Lymfocyter</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        Lymphocytes, a type of white blood cell, play several roles in our
        immune system. It fights off infection (particularly viruses) and
        remembers how to fight past infections. By measuring lymphocyte levels,
        we can look for signs of recent or current infections, and in a small
        number of people it can help with identifying long term conditions
        affecting the immune system.
      </>
    ),
    sv_SE: (
      <>
        Lymfocyter, en typ av vit blodkropp, har flera funktioner i vårt
        immunförsvar. De bekämpar framförallt virus och agerar som
        immunförsvarets minne. Genom att beräkna nivåer av lymfocyter kan vi se
        tecken till aktuell eller nyligen genomgången infektion. Hos en liten
        andel individer kan vi med detta prov identifiera andra
        sjukdomstillstånd som påverkar immunförsvaret.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        A high result means that your body is producing more than the normal
        amount of lymphocytes. If you have a viral infection like a cold or flu,
        then this is a sign that your immune system is working normally and is
        fighting the infection. This is by far the most common cause of a high
        lymphocyte count. Normally your lymphocytes will settle down to normal
        levels a couple of weeks after the infection.
      </>
    ),
    sv_SE: (
      <>
        Ett högt resultat betyder att din kropp producerar mer än den normala
        mängden lymfocyter. Om du har en virusinfektion som en förkylning eller
        influensa är detta ett tecken på att ditt immunförsvar fungerar normalt
        och bekämpar infektionen. Detta är den absolut vanligaste orsaken till
        ett högt antal lymfocyter. Normalt kommer dina lymfocyter att återgå
        till normala nivåer ett par veckor efter infektionen.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        There are a wide range of causes for low lymphocytes. Your lymphocyte
        count can dip temporarily in response to recent stress or infections.
        Medications used to treat autoimmune conditions can suppress the
        production of lymphocytes, this is a sign that they are working. Some
        infections target lymphocytes and cause low levels. The bone marrow
        produces lymphocytes, so low levels can be caused by diseases that
        affect the bone marrow. They can also be caused by nutritional
        deficiencies, such as not having enough zinc in your diet.
      </>
    ),
    sv_SE: (
      <>
        Det finns ett brett spektrum av orsaker till låga lymfocyter. Ditt
        lymfocytantal kan sjunka tillfälligt som svar på senaste stress eller
        infektioner. Läkemedel som används för att behandla autoimmuna tillstånd
        kan undertrycka produktionen av lymfocyter, detta är ett tecken på att
        de fungerar. Vissa infektioner riktar sig mot lymfocyter och orsakar
        låga nivåer. Benmärgen producerar lymfocyter, så låga nivåer kan orsakas
        av sjukdomar som påverkar benmärgen. De kan också orsakas av
        näringsbrister, som att du inte har tillräckligt med zink i kosten.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        A consistently high lymphocyte count may be caused by a longstanding
        infection, or by a long-term illness. If your lymphocyte count is
        steadily climbing over time this can be a sign of serious problems
        developing in the bone marrow or lymph glands. A longstanding low count
        might mean there is an underlying issue with your immune system. If your
        lymphocyte count is steadily falling over time, then this could be a
        sign of an immune deficiency developing. You should see your doctor if
        you have an abnormal lymphocyte count that does not improve.
      </>
    ),
    sv_SE: (
      <>
        Ett konsekvent högt antal lymfocyter kan orsakas av en långvarig
        infektion eller av en långvarig sjukdom. Om ditt antal lymfocyter
        stadigt stiger över tiden kan detta vara ett tecken på allvarliga
        problem som utvecklas i benmärgen eller lymfkörtlarna. Ett långvarigt
        lågt antal kan betyda att det finns ett underliggande problem med ditt
        immunförsvar. Om ditt antal lymfocyter stadigt sjunker över tid kan
        detta vara ett tecken på att en immunbrist utvecklas. Du bör prata med
        din läkare om du har ett onormalt antal lymfocyter som inte förbättras.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        Your immune system adapts to threats like viral infections, so there is
        no specific target number for lymphocyte count. A normal range is
        1.1-3.5 x 10^9/L, but it varies by measurement technique.
      </>
    ),
    sv_SE: (
      <>
        Ditt immunsystem anpassar sig till hot som virusinfektioner, så det
        finns inget specifikt referensvärde för antalet lymfocyter. Ett normalt
        intervall är 1,1-3,5 x 10^9/L, men det varierar beroende på mätteknik.
      </>
    ),
  }),
};

export const Trans = { MetricName, FAQ };
