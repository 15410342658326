import { PropsWithChildren } from "react";
import { classNames } from "@pomle/classnames";

interface StepProps {
  className?: string;
  activeClass: string;
  hiddenClass: string;
  isActive: boolean;
}

export function Step({
  className,
  activeClass,
  hiddenClass,
  isActive,
  children,
}: PropsWithChildren<StepProps>) {
  return (
    <div
      tabIndex={!isActive ? -1 : undefined}
      className={classNames(className ?? "", {
        [activeClass]: isActive,
        [hiddenClass]: !isActive,
      })}
    >
      {children}
    </div>
  );
}
