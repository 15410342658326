import { PaginatedResponse } from "@cur8/api-client";

export async function exhaust<K>(
  callback: (
    continuationToken: string | undefined
  ) => Promise<PaginatedResponse<K>>
): Promise<K[]> {
  const result: K[] = [];
  let nextPage: string | undefined;
  do {
    const response = await callback(nextPage);
    result.push(...response.items);
    nextPage = response.nextPage;
  } while (nextPage);
  return result;
}
