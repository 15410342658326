import { ChoiceQuestion, MultiChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum MedicalHistoryDiabetesEnum {
  No = "No",
  Yes = "Yes",
}

export enum MedicalHistoryDiabetesTypeEnum {
  TypeOne = "Type one",
  TypeTwo = "Type two",
}

export enum MedicalHistoryDiabetesComplicationsEnum {
  EyeProblems = "Eye problems",
  FootProblems = "Foot problems",
  HeartProblems = "Heart or vessel problems",
  KidneyProblems = "Kidney problems",
}

export const MedicalHistoryDiabetes = new ChoiceQuestion(
  <Trans.MedicalHistoryDiabetes.Question />,
  [
    [<Trans.MedicalHistoryDiabetes.No />, MedicalHistoryDiabetesEnum.No],
    [<Trans.MedicalHistoryDiabetes.Yes />, MedicalHistoryDiabetesEnum.Yes],
  ]
);

export const MedicalHistoryDiabetesType = new ChoiceQuestion(
  <Trans.MedicalHistoryDiabetes.TypeQuestion />,
  [
    [
      {
        label: <Trans.MedicalHistoryDiabetes.TypeOne />,
        description: <Trans.MedicalHistoryDiabetes.TypeOneDescription />,
      },
      MedicalHistoryDiabetesTypeEnum.TypeOne,
    ],
    [
      {
        label: <Trans.MedicalHistoryDiabetes.TypeTwo />,
        description: <Trans.MedicalHistoryDiabetes.TypeTwoDescription />,
      },
      MedicalHistoryDiabetesTypeEnum.TypeTwo,
    ],
  ]
);

export const MedicalHistoryDiabetesComplications = new MultiChoiceQuestion(
  <Trans.MedicalHistoryDiabetes.ComplicationsQuestion />,
  [
    [
      {
        label: <Trans.MedicalHistoryDiabetes.EyeProblems />,
        description: <Trans.MedicalHistoryDiabetes.EyeProblemsDescription />,
      },
      MedicalHistoryDiabetesComplicationsEnum.EyeProblems,
    ],
    [
      {
        label: <Trans.MedicalHistoryDiabetes.FootProblems />,
        description: <Trans.MedicalHistoryDiabetes.FootProblemsDescription />,
      },
      MedicalHistoryDiabetesComplicationsEnum.FootProblems,
    ],
    [
      {
        label: <Trans.MedicalHistoryDiabetes.HeartProblems />,
        description: <Trans.MedicalHistoryDiabetes.HeartProblemsDescription />,
      },
      MedicalHistoryDiabetesComplicationsEnum.HeartProblems,
    ],
    [
      {
        label: <Trans.MedicalHistoryDiabetes.KidneyProblems />,
        description: <Trans.MedicalHistoryDiabetes.KidneyProblemsDescription />,
      },
      MedicalHistoryDiabetesComplicationsEnum.KidneyProblems,
    ],
  ]
);
