import {
  ChoiceQuestion,
  MultiChoiceQuestion,
  TextQuestion,
} from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum AllergiesListEnum {
  Dairy = "Dairy",
  InsectSting = "Insect sting",
  Latex = "Latex",
  Morphine = "Morphine",
  Nuts = "Nuts",
  Pets = "Pets",
  Pollen = "Pollen",
  Wheat = "Wheat",
  SomethingElse = "Something else",
}

export enum AllergiesEnum {
  Yes = "Yes",
  No = "No",
}

export const AllergyChoices: [JSX.Element, AllergiesListEnum][] = [
  [<Trans.Allergies.Dairy />, AllergiesListEnum.Dairy],
  [<Trans.Allergies.InsectSting />, AllergiesListEnum.InsectSting],
  [<Trans.Allergies.Latex />, AllergiesListEnum.Latex],
  [<Trans.Allergies.Morphine />, AllergiesListEnum.Morphine],
  [<Trans.Allergies.Nuts />, AllergiesListEnum.Nuts],
  [<Trans.Allergies.Pets />, AllergiesListEnum.Pets],
  [<Trans.Allergies.Pollen />, AllergiesListEnum.Pollen],
  [<Trans.Allergies.Wheat />, AllergiesListEnum.Wheat],
  [<Trans.Allergies.SomethingElse />, AllergiesListEnum.SomethingElse],
];

export const Allergies = new ChoiceQuestion(<Trans.Allergies.Question />, [
  [<Trans.Allergies.No />, AllergiesEnum.No],
  [<Trans.Allergies.Yes />, AllergiesEnum.Yes],
]);

export const AllergiesList = new MultiChoiceQuestion(
  <Trans.Allergies.AllergiesListQuestion />,
  AllergyChoices
);

export const OtherAllergies = new TextQuestion(
  <Trans.Allergies.OtherAllergiesQuestion />
);
