import { classNames } from "@pomle/classnames";
import { DateTime } from "luxon";
import { ReactElement } from "react";
import styles from "./styles.module.sass";

interface LogoHeaderProps {
  leftElement?: ReactElement;
  rightElement?: ReactElement;
  date: DateTime;
  mode: "light" | "dark";
}

export function Header({
  leftElement,
  rightElement,
  date,
  mode,
}: LogoHeaderProps) {
  return (
    <div
      className={classNames(styles.header, {
        [styles.dark]: mode === "dark",
        [styles.light]: mode === "light",
      })}
    >
      <div className={styles.left}>{leftElement}</div>
      <div className={styles.date}>{date.toFormat("dd MMM, yyyy")}</div>
      <div className={styles.right}>{rightElement}</div>
    </div>
  );
}
