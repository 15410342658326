import { createElement } from "react";
import { Slide, Direction, Fade } from "@pomle/react-viewstack-transitions";
import { Sticky } from "@pomle/react-viewstack";

type Keys = keyof typeof Direction;
type Values = (typeof Direction)[Keys];

type Animation =
  | {
      animation: "fade";
    }
  | { animation: "slide"; direction: Values };

export function mount<T extends {}>(
  Component: (props: T) => React.ReactElement | null,
  params: Animation & { exact?: boolean }
) {
  return function render(match: { params: T; exact: boolean } | null) {
    let isMatch = match != null;
    if (params.exact) {
      isMatch = match?.exact === true;
    }

    const element = (
      <Sticky delay={100}>
        {isMatch && match ? createElement(Component, match.params) : null}
      </Sticky>
    );

    switch (params.animation) {
      case "fade":
        return <Fade active={!!isMatch}>{element}</Fade>;
      case "slide":
        return (
          <Slide active={!!isMatch} direction={params.direction}>
            {element}
          </Slide>
        );
      default:
        return element;
    }
  };
}
