import { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.sass";

interface ToggleProps {
  checked: boolean;
  onClick: (val: boolean) => void;
  label: string;
  name: string;
}

export function Toggle({ checked, onClick, label, name }: ToggleProps) {
  const [animation, setAnimation] = useState<string>();
  const getAnimationFromValue = useCallback(
    (value: boolean) => (value ? "check" : "unCheck"),
    []
  );

  useEffect(() => {
    if (!animation) {
      return;
    }
    setAnimation(getAnimationFromValue(checked));
  }, [checked, animation, getAnimationFromValue]);

  return (
    <div className={styles.toggle}>
      <label className={styles.visuallyHidden} htmlFor={name}>
        {label}
      </label>
      <input
        name={name}
        id={name}
        checked={checked}
        onChange={() => {
          setAnimation(getAnimationFromValue(!checked));
          onClick(!checked);
        }}
        className={styles.input}
        data-animation={animation}
        type="checkbox"
      />
    </div>
  );
}
