import { splitSlotId } from "lib/visit/splitSlotId";
import { useSlotQuery } from "render/hooks/api/queries/useSlotQuery";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { AppointmentInfoSection } from "./components/AppointmentInfoSection";

interface AppointmentViewProps {
  slotId: string;
}

export function AppointmentView({ slotId }: AppointmentViewProps) {
  const { visitId } = splitSlotId(slotId);
  const slotQuery = useSlotQuery({ slotId });

  return (
    <FullScreenPageLayout>
      {slotQuery.data && (
        <AppointmentInfoSection slot={slotQuery.data} visitId={visitId} />
      )}
    </FullScreenPageLayout>
  );
}
