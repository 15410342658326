import { DateTime } from "luxon";
import { ReactNode, useMemo } from "react";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface MiniCalendarProps {
  date: DateTime;
  renderDay: (date: DateTime) => ReactNode;
}

interface Day {
  date: DateTime;
}

export function WeeklyCalendar({ date, renderDay }: MiniCalendarProps) {
  const dateKey = date.toUTC().toISO();

  const days = useMemo(() => {
    const result: Day[] = [];
    const start = date.startOf("week");
    const end = date.endOf("week");

    let pointer = start.startOf("week");

    while (pointer <= end) {
      result.push({
        date: pointer,
      });

      pointer = pointer.plus({ day: 1 });
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateKey]);

  return (
    <div className={styles.WeeklyCalendar}>
      <span>
        <ol className={styles.header}>
          <li>
            <Trans.WeekdayFirstLetter.Monday />
          </li>
          <li>
            <Trans.WeekdayFirstLetter.Tuesday />
          </li>
          <li>
            <Trans.WeekdayFirstLetter.Wednesday />
          </li>
          <li>
            <Trans.WeekdayFirstLetter.Thursday />
          </li>
          <li>
            <Trans.WeekdayFirstLetter.Friday />
          </li>
          <li>
            <Trans.WeekdayFirstLetter.Saturday />
          </li>
          <li>
            <Trans.WeekdayFirstLetter.Sunday />
          </li>
        </ol>
        <div className={styles.header}>
          {days.map(({ date }, index) => {
            return (
              <div key={index}>
                <span>{renderDay(date)}</span>
              </div>
            );
          })}
        </div>
      </span>
    </div>
  );
}
