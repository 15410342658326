import { APITypesV1 } from "@cur8/api-client";
import { queryKey as patientQueryKey } from "render/hooks/api/queries/usePatientQuery";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fromAPI, UnitSystem } from "@cur8/rich-entity";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";

interface PatientUpdateProps {
  email?: string;
  firstName?: string;
  lastName?: string;
  preferredLang?: string;
  displayName?: string;
  sex?: APITypesV1.Sex;
  preferredUnitSystem?: UnitSystem;
}

function unitAdapter(unit: UnitSystem) {
  switch (unit) {
    case UnitSystem.Imperial:
      return APITypesV1.UnitSystem.Imperial;
    case UnitSystem.Metric:
      return APITypesV1.UnitSystem.Metric;
    default:
      return undefined;
  }
}

export function usePatientMutation({
  onSuccess,
}: { onSuccess?: () => void } = {}) {
  const { patientId } = useSession();
  const apiClient = useAPIClient().patient;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (props: PatientUpdateProps) => {
      return apiClient.fetchPatient({ patientId }).result.then((patient) => {
        const update: APITypesV1.UpdatePatientDetailsRequest = {};

        if (props.email) {
          update.contactDetails =
            patient.contactDetails ??
            ({} as APITypesV1.UpdatePatientDetailsRequest["contactDetails"]);
          if (props.email) {
            update.contactDetails.email = props.email;
          }
        }
        if (props.firstName || props.lastName || props.displayName) {
          update.name =
            patient.name ??
            ({} as APITypesV1.UpdatePatientDetailsRequest["name"]);
          update.name.firstName = props.firstName ?? patient.name.firstName;
          update.name.lastName = props.lastName ?? patient.name.lastName;
          update.name.displayName =
            props.displayName ?? patient.name.displayName;
        }
        if (props.preferredLang) {
          update.preferredLanguage = props.preferredLang;
        }
        if (props.preferredUnitSystem) {
          const preferredUnitSystem = unitAdapter(props.preferredUnitSystem);
          update.preferredUnitSystem = preferredUnitSystem;
        }

        return apiClient
          .updatePatient(patientId, update)
          .result.then(fromAPI.toPatientDetails);
      });
    },
    onSuccess(data) {
      queryClient.setQueryData(patientQueryKey(patientId), data);
      onSuccess?.();
    },
  });
}
