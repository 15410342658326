import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { Checkout } from "render/views/checkout/_CheckoutContext/hooks/useCreateIntentMutation/useCreateIntentMutation";

export function useSubmitCardPaymentMutation() {
  const mutationFn = useCallback(
    async ({
      checkout,
      elements,
      stripe,
    }: {
      stripe: Stripe;
      elements: StripeElements;
      checkout: Checkout;
    }) => {
      const result = await stripe.confirmPayment({
        elements,
        redirect: "always",
        confirmParams: {
          return_url: checkout?.checkoutData.completeCheckoutUrl,
        },
      });
      if (result.error) {
        throw result.error;
      }
    },
    []
  );
  return useMutation({ mutationFn });
}
