import { Event, Action } from "render/hooks/useTracking";

const category = "Auth/Redirect";

export const authRedirectEvent = {
  goToRootPageClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "go_to_root_page_button",
    };
  },
  pageView: (): Event => {
    return {
      category,
      action: Action.View,
      label: "page_view",
    };
  },
  pageLeave: (): Event => {
    return {
      category,
      action: Action.View,
      label: "page_leave",
    };
  },
};
