import { Typography } from "@cur8/maneki";
import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  Title: localize({
    en_GB: "Our commitment to your data",
    sv_SE: "Vårt åtagande för din data",
  }),
  Paragraph: localize<{ link: string }>({
    en_GB: ({ link }) => (
      <>
        Your data is encrypted and can only be shared with your permission. Read
        more about{" "}
        <a href={link} target="_blank" rel="noreferrer">
          <Typography variant="link-m" color="subtle">
            how we process your data.
          </Typography>
        </a>
      </>
    ),
    sv_SE: ({ link }) => (
      <>
        Dina uppgifter är krypterade och kan endast delas med ditt tillstånd.
        Läs mer om{" "}
        <Typography variant="link-m" color="subtle">
          <a href={link} target="_blank" rel="noreferrer">
            hur vi behandlar dina uppgifter.
          </a>
        </Typography>
      </>
    ),
  }),
  ResearchStudies: localize({
    en_GB: "Research Studies",
    sv_SE: "Forskningsstudier",
  }),
  ResearchStudiesDescription: localize({
    en_GB: (
      <>
        As research studies request permission to read your data, they will be
        added to this list. Participation is completely voluntary, and you can
        cancel your participation at any time, even after you have given
        consent.
      </>
    ),
    sv_SE: (
      <>
        De forskningsstudier som begär tillåtelse att läsa din data kommer att
        läggas till här. Deltagandet är helt frivilligt och du kan avbryta ditt
        deltagande när som helst, även efter att du har gett ditt samtycke.
      </>
    ),
  }),
  Products: localize({
    en_GB: "Product & Services",
    sv_SE: "Produkter & tjänster",
  }),
  Agreed: localize({
    en_GB: "Agreed",
    sv_SE: "Signerat",
  }),
  Expired: localize({
    en_GB: "Expired",
    sv_SE: "Löpte ut",
  }),
  Revoked: localize({
    en_GB: "Revoked",
    sv_SE: "Återkallades",
  }),
  OptedOut: localize({
    en_GB: "Opted out",
    sv_SE: "Valt bort",
  }),
  Status: localize<{ verb: JSX.Element; date: string }>({
    en_GB: ({ verb, date }) => (
      <>
        {verb} on {date}
      </>
    ),
    sv_SE: ({ verb, date }) => (
      <>
        {verb} {date}
      </>
    ),
  }),
  GdprExport: localize({
    en_GB: "GDPR export",
    sv_SE: "GDPR-export",
  }),
  RequestPersonalData: localize({
    en_GB: "Request your personal data",
    sv_SE: "Begär dina personuppgifter",
  }),
  CreateFiles: localize({
    en_GB:
      "We will create files for you to download your personal data in compliance with GDPR regulations.",
    sv_SE:
      "Vi kommer att skapa filer så att du kan ladda ner dina personuppgifter i enlighet med GDPR.",
  }),
};
