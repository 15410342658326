import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: () => {
    return <>Code</>;
  },
  sv_SE: () => {
    return <>Kod</>;
  },
});

const Copy = localize({
  en_GB: () => {
    return <>Copy</>;
  },
  sv_SE: () => {
    return <>Kopiera</>;
  },
});

const Copied = localize({
  en_GB: () => {
    return <>Copied!</>;
  },
  sv_SE: () => {
    return <>Kopierat!</>;
  },
});

const CoppiedToClipboard = localize({
  en_GB: <>Copied to clipboard</>,
  sv_SE: <>Kopierat till urklipp</>,
});

const Expires = {
  Today: localize({
    en_GB: <>Expires today!</>,
    sv_SE: <>Utgår idag!</>,
  }),
  In: localize<{ days: number }>({
    en_GB: ({ days }) => <>Expires in {days} days</>,
    sv_SE: ({ days }) => <>Utgår om {days} dagar</>,
  }),
};

export const Trans = { Title, Copy, Copied, CoppiedToClipboard, Expires };
