import { useNav } from "@pomle/react-router-paths";
import { useCallback, useEffect } from "react";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { ActionButton } from "render/ui/trigger/ActionButton";
import styles from "./styles.module.sass";
import { authRedirectEvent } from "./tracking";
import { Trans } from "./trans";

/*
 ** This view is a fallback for a successful login after a redirect
 ** Inside MSAL Context a redirect from this screen should happen
 ** Incase it does not there is a call to action for the end user to do so
 ** There are analytic events to track if that happens
 */
export function RedirectView() {
  const nav = {
    home: useNav(paths.root),
  };
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent(authRedirectEvent.pageView());
    return () => trackEvent(authRedirectEvent.pageLeave());
  }, [trackEvent]);

  const onClick = useCallback(() => {
    trackEvent(authRedirectEvent.goToRootPageClick());
    nav.home.set({});
  }, [trackEvent, nav.home]);

  return (
    <FullScreenPageLayout>
      <div className={styles.body}>
        <div className={styles.content}>
          <ActionButton onClick={onClick}>
            <Trans.CTA />
          </ActionButton>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
