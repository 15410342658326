import { localize } from "render/context/InternationalizationContext";

const Header = localize({
  en_GB: <>An unexpected error has occurred trying to log you in</>,
  sv_SE: <>Ett oväntat fel uppstod</>,
});

const TryAgain = localize({
  en_GB: <>Try again</>,
  sv_SE: <>Försök igen</>,
});

export const Trans = {
  Header,
  TryAgain,
};
