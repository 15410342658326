import { type ReactNode } from "react";
import { PopupBackdrop } from "render/ui/presentation/PopupBackdrop";
import styles from "./styles.module.sass";

type ToastProps = {
  children?: ReactNode;
};

export function Toast({ children }: ToastProps) {
  return (
    <PopupBackdrop>
      <div className={styles.toast}>{children}</div>
    </PopupBackdrop>
  );
}
