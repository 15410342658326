import { SnackbarProvider } from "notistack";
import { PropsWithChildren } from "react";
import styles from "./styles.module.sass";

export function SnackbarContext({ children }: PropsWithChildren<{}>) {
  return (
    <SnackbarProvider
      classes={{
        variantError: styles.variantError,
        variantSuccess: styles.variantBIGSuccess,
        root: styles.root,
        containerRoot: styles.containerRoot,
      }}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      hideIconVariant
    >
      {children}
    </SnackbarProvider>
  );
}
