import { Answers } from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import { FamilyHeritage } from "lib/questionnaire/question-flows/onboarding/questions/heritage";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { MultiChoiceInput } from "render/ui/presentation/Questionnaire/components/MultiChoiceInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function HeritageStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(FamilyHeritage);
  const label = TransQuestions.Heritage.Question();
  const { englishLabel } = TransQuestions.Heritage.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={FamilyHeritage}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(FamilyHeritage);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.HeritageExplanation />,
      }}
    >
      <MultiChoiceInput
        question={FamilyHeritage}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(FamilyHeritage, {
            answer,
            questionAnswer: {
              questionId: "ethnicities",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "textarray",
                value: FamilyHeritage.getValue(answer),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], _: Answers) {
  steps.push(HeritageStep);
}
