import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Grip Strength</>,
  sv_SE: <>Greppstyrka</>,
});

const MetricUnit = localize({
  en_GB: <>kg</>,
  sv_SE: <>kg</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        This test measures how tightly you can grasp an object with your hands.
        The strength of your hands is closely related to the strength of muscles
        elsewhere in your body, so grip strength can be used to monitor the
        general health of your muscles.
      </>
    ),
    sv_SE: (
      <>
        Detta test mäter hur hårt du kan greppa ett föremål med händerna.
        Styrkan i dina händer är nära relaterad till styrkan hos muskler på
        andra ställen i din kropp. Greppstyrkan kan användas för att få en
        förståelse för dina musklers allmänna hälsa.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        A high result means that you are likely to be stronger than most people
        of your age. High grip strength usually means that you have high muscle
        mass throughout your body. This means that you are less likely to
        experience problems that are caused by muscle loss as you get older. You
        should keep doing regular strength exercises to protect your muscles.
      </>
    ),
    sv_SE: (
      <>
        Ett högt resultat betyder att du sannolikt är starkare än de flesta i
        din ålder. Hög greppstyrka innebär oftast att du har en hög nivå av
        muskelmassa i hela kroppen. Det betyder att du är mindre benägen att
        uppleva problem som orsakas av muskelförlust när du blir äldre. Du bör
        fortsätta göra regelbundna styrkeövningar för att skydda dina muskler.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low result means that you are likely to be less strong than most
        people of your age. Low grip strength usually means that you also have
        less muscle mass elsewhere in your body. We lose muscle mass as we age,
        and having low grip strength means that you are more likely to
        experience problems such as difficulties like struggling to open jars
        and tins, or moving from sitting to standing as you get older. It can
        also increase the risk of falls in old age.
      </>
    ),
    sv_SE: (
      <>
        Ett lågt resultat betyder att du sannolikt är svagare än de flesta i din
        ålder. Låg greppstyrka gör oftast att du även har mindre muskelmassa på
        andra ställen i kroppen. Vi tappar muskelmassa när vi åldras, och att ha
        låg greppstyrka gör att du är mer benägen att uppleva problem och
        svårigheter som att öppna burkar eller att gå från sittande till stående
        när du blir äldre. Det kan också öka risken för fall vid hög ålder.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        You can use grip strength to monitor your upper body strength and muscle
        mass over time. This can help you to understand whether you are doing
        enough resistance training to maintain and grow your muscles. We tend to
        lose muscle mass as we age, which can increase the risk of falls in old
        age. The good news is that you can gain muscle mass at any age by doing
        regular strength exercises.
      </>
    ),
    sv_SE: (
      <>
        Du kan använda greppstyrkan för att övervaka din överkroppsstyrka och
        muskelmassa över tid. Detta kan hjälpa dig att förstå om du tränar
        tillräckligt med styrketräning för att underhålla och bygga dina
        muskler. Vi tenderar att tappa muskelmassa när vi åldras, vilket bland
        annat kan öka risken för fall vid hög ålder. Den goda nyheten är att du
        kan bygga muskelmassa i alla åldrar genom att göra regelbundna
        styrkeövningar.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        Because your grip strength varies by your sex and your age there is no
        single number that you should be aiming for. We compare your grip
        strength to the values of other Neko members around the same age as you.
        If your grip strength is too low for your age, or it is decreasing over
        time, then our doctors will talk to you about the ways you can improve
        it. It is normal for there to be a small difference in the strength of
        your hands, your dominant hand is usually up to 10% stronger than your
        non-dominant hand.
      </>
    ),
    sv_SE: (
      <>
        Eftersom din greppstyrka varierar beroende på ditt kön och din ålder
        finns det inget enskilt nummer du bör sikta på. Vi jämför din
        greppstyrka med normalvärdena för någon som är i samma ålder och kön som
        du. Om din greppstyrka är för låg för din ålder, eller om den minskar
        med tiden, kommer våra läkare att prata med dig om hur du kan förbättra
        den. Det är normalt att det är en liten skillnad i styrkan på dina
        händer, din dominanta hand är vanligtvis upp till 10% starkare än din
        icke-dominanta hand.
      </>
    ),
  }),
};

export const Trans = { MetricName, MetricUnit, FAQ };
