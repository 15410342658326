import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: "Thanks",
  sv_SE: "Tack",
});

const PatientName = localize<{ patientName: string }>({
  en_GB: ({ patientName }) => `${patientName}!`,
  sv_SE: ({ patientName }) => `${patientName}!`,
});

const Description = localize({
  en_GB: "You’re ready for your scan and can put away your phone.",
  sv_SE: "Du är redo för din scan och kan lägga undan telefonen.",
});

export const Trans = {
  Title,
  PatientName,
  Description,
};
