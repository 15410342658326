import { localize } from "render/context/InternationalizationContext";
import styles from "./styles.module.sass";

const Title = localize({
  en_GB: <>Your assignment</>,
  sv_SE: <>Din uppgift</>,
});

const Notes = {
  Starting: localize({
    en_GB: <>Track your blood pressure for 7 days to complete.</>,
    sv_SE: <>Mät ditt blodtryck under 7 sju dagar för att slutföra.</>,
  }),
  DaysTracked: localize<{ tracked: number; total: number }>({
    en_GB: ({ tracked, total }) => (
      <>
        <span className={styles.bolded}>{tracked}</span> / {total} days tracked
      </>
    ),
    sv_SE: ({ tracked, total }) => (
      <>
        <span className={styles.bolded}>{tracked}</span> / {total} dagar mätt
      </>
    ),
  }),
};

export const Trans = { Title, Notes };
