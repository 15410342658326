import { Typography } from "@cur8/maneki";
import { useNav } from "@pomle/react-router-paths";
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { ReactComponent as CompassIcon } from "assets/icons/16x16/16x16_pin.svg";
import { ReactComponent as PlusIcon } from "assets/icons/16x16/16x16_plus.svg";
import { ReactComponent as ChatIcon } from "assets/icons/24x24/24x24_chat.svg";
import { ReactComponent as CloseIcon } from "assets/icons/24x24/24x24_close.svg";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useSession } from "render/context/MSALContext";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Input } from "render/ui/presentation/Input";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { usePaymentRequestQuery } from "render/views/checkout/_CheckoutContext/hooks/usePaymentRequestQuery/usePaymentRequestQuery";
import { useWalletSubmitMutation } from "render/views/checkout/_CheckoutContext/hooks/useWalletSubmitMutation/useWalletSubmitMutation";
import { ProductPrice } from "render/views/checkout/components/ProductPrice";
import { useCheckoutContext } from "../_CheckoutContext/CheckoutContext";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

function Country({ countryCode }: { countryCode: string }) {
  return (
    <>
      {countryCode === "SE" && <Trans.City.Stockholm />}
      {countryCode === "GB" && <Trans.City.London />}
    </>
  );
}

export function CodePayment() {
  const { isNewUser } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const {
    applyDiscountCode,
    cart,
    fullPrice,
    discountCode,
    stripe,
    checkout,
    validateTokenMutation,
    updateCartMutation,
    discountApplied,
  } = useCheckoutContext();
  const contactUsPopup = useContactUsPopup();
  const nav = {
    home: useNav(paths.root),
    cardPayment: useNav(paths.checkoutCard),
  };

  const [formData, setFormData] = useState({
    code: discountCode,
  });

  const [formErrors, setFormError] = useState<{ discountCode: Error | null }>();

  useEffect(() => {
    setFormError({
      discountCode: validateTokenMutation.error ?? updateCartMutation.error,
    });
  }, [validateTokenMutation.error, updateCartMutation.error]);

  const [autoFocus] = useState(formData.code == null);

  const formControls = {
    reset: useCallback(() => {
      setFormError({ discountCode: null });
      setFormData((data) => {
        return { ...data, code: "" };
      });
    }, []),
    setCode: useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      setFormError({ discountCode: null });
      setFormData((data) => {
        return { ...data, code: e.target.value };
      });
    }, []),
    onSubmit: useCallback(
      (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        if (formData.code == null) {
          return;
        }
        setFormError({ discountCode: null });
        applyDiscountCode({ code: formData.code });
      },
      [applyDiscountCode, formData.code]
    ),
    errors: formErrors,
  };

  const walletCallbackMutation = useWalletSubmitMutation();
  const paymentRequestQuery = usePaymentRequestQuery({ cart, stripe });

  const walletCallbackMutationMutate = walletCallbackMutation.mutateAsync;

  useEffect(() => {
    if (
      paymentRequestQuery.data == null ||
      stripe == null ||
      checkout == null
    ) {
      return;
    }

    const { paymentRequest, wallet } = paymentRequestQuery.data;

    if (wallet?.applePay || wallet?.googlePay) {
      const handler = (ev: PaymentRequestPaymentMethodEvent) => {
        walletCallbackMutationMutate({ ev, stripe, checkout }).catch(() => {
          enqueueSnackbar(<Trans.Error.Unexpected />, { variant: "error" });
        });
      };
      paymentRequest.on("paymentmethod", handler);
      return () => {
        paymentRequest.off("paymentmethod", handler);
      };
    }
  }, [
    paymentRequestQuery.data,
    stripe,
    checkout,
    walletCallbackMutationMutate,
    enqueueSnackbar,
  ]);

  const controls = {
    showAddCode: useCallback(() => {
      setFormData((data) => {
        return { ...data, code: "" };
      });
    }, []),
    payWithWallet: useCallback(() => {
      paymentRequestQuery.data?.paymentRequest.show();
    }, [paymentRequestQuery.data]),
    isPending: walletCallbackMutation.isPending,
  };

  return (
    <FullScreenPageLayout>
      <div className={styles.CodePaymentView}>
        <div className={styles.header}>
          <LogoHeader
            leftElement={
              isNewUser ? null : (
                <div className={styles.icon}>
                  <IconButton onClick={nav.home.on({})} icon={<CloseIcon />} />
                </div>
              )
            }
            rightElement={
              <IconButton
                onClick={contactUsPopup.emit}
                icon={
                  <div className={styles.icon}>
                    <ChatIcon />
                  </div>
                }
              />
            }
          />
        </div>
        <div className={styles.content}>
          <div className={styles.body}>
            <section className={styles.description}>
              <Typography variant="display-s">
                <Trans.Header />
              </Typography>
              <Typography variant="body-m" color="subtle">
                <Trans.Description />
              </Typography>
              <div className={styles.country}>
                {cart?.country ? (
                  <>
                    <CompassIcon />
                    <Typography variant="body-s">
                      {cart?.country && <Country countryCode={cart?.country} />}
                    </Typography>
                  </>
                ) : undefined}
              </div>
            </section>

            <section className={styles.product}>
              <ProductPrice
                discountApplied={discountApplied}
                cart={cart}
                discountCode={discountCode}
                fullPrice={fullPrice}
              />
            </section>

            <section className={styles.inviteCode}>
              {formData.code == null ? (
                <div className={styles.addCode}>
                  <Typography variant="body-m">
                    <Trans.Code.Label />
                  </Typography>
                  <button
                    disabled={paymentRequestQuery.data == null}
                    className={styles.addCodeButton}
                    onClick={controls.showAddCode}
                  >
                    <Typography
                      variant="eyebrow-m"
                      color={
                        paymentRequestQuery.data == null ? "subtle" : "default"
                      }
                    >
                      <Trans.CTA.AddCode />
                    </Typography>
                    <PlusIcon />
                  </button>
                </div>
              ) : null}
              {formData.code != null ? (
                <form onSubmit={formControls.onSubmit}>
                  <Input
                    disabled={discountApplied}
                    autoFocus={autoFocus}
                    autocomplete="off"
                    name="inviteCode"
                    label={<Trans.CodeForm.Label />}
                    error={
                      formControls.errors?.discountCode ? (
                        <Trans.Error.InvalidInvitationCode />
                      ) : undefined
                    }
                    hideClearButton
                    cta={
                      !discountApplied
                        ? {
                            label: <Trans.CodeForm.CTA />,
                            onClick: formControls.onSubmit,
                          }
                        : undefined
                    }
                    onChange={formControls.setCode}
                    onClear={formControls.reset}
                    value={formData.code}
                  />
                </form>
              ) : null}
            </section>

            <section
              className={styles.actions}
              data-loading={paymentRequestQuery.data == null}
            >
              {paymentRequestQuery.data == null ? (
                <>
                  <div className={styles.callToActionSkeleton}>
                    <Skeleton />
                  </div>
                </>
              ) : undefined}
              <div
                className={styles.buttons}
                data-loading={paymentRequestQuery.data == null}
              >
                {paymentRequestQuery.data != null ? (
                  <>
                    {paymentRequestQuery.data.wallet?.applePay ? (
                      <ActionButton
                        disabled={controls.isPending}
                        walletVariant="apple"
                        variant="wallet"
                        onClick={controls.payWithWallet}
                      >
                        <Trans.CTA.ApplePay />
                      </ActionButton>
                    ) : undefined}
                    {paymentRequestQuery.data.wallet?.googlePay ? (
                      <ActionButton
                        disabled={controls.isPending}
                        variant="wallet"
                        walletVariant="google"
                        onClick={controls.payWithWallet}
                      >
                        <Trans.CTA.GooglePay />
                      </ActionButton>
                    ) : undefined}
                    <ActionButton
                      disabled={controls.isPending}
                      variant="primary"
                      onClick={nav.cardPayment.on({})}
                    >
                      <Trans.CTA.CardPayment />
                    </ActionButton>
                  </>
                ) : undefined}
              </div>
            </section>
          </div>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
