import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string, orderId: string) => [
  "patient",
  patientId,
  "order",
  orderId,
];
const queryFn =
  (apiClient: APIClient, patientId: string, orderId: string) => async () => {
    return await apiClient.billing.getPatientOrder(patientId, orderId).result;
  };

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useOrderQuery<T = Body>(
  orderId: string,
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryKey: queryKey(patientId, orderId),
    queryFn: queryFn(apiClient, patientId, orderId),
  });
}
