import { DateTime } from "luxon";
import { useEffect, useMemo } from "react";
import styles from "./styles.module.sass";
import { useSiteSlotsQuery } from "render/hooks/api/queries/useSiteSlotsQuery";
import { useLiveTime } from "render/hooks/useLiveTime";

interface DayProps {
  now: DateTime;
  date: DateTime;
  bookableUntil: DateTime;
  siteIds: string[];
  onClick: (date: DateTime) => void;
  selected?: boolean;
  isToday?: boolean;
}

export function Day({
  now,
  date,
  bookableUntil,
  siteIds,
  onClick,
  isToday,
  selected,
}: DayProps) {
  const liveTimer = useLiveTime("minute");
  const isInThePast = date.startOf("day") < now.startOf("day");
  const isPastBookableDate = date.startOf("day") > bookableUntil.startOf("day");

  const siteSlotsQuery = useSiteSlotsQuery(
    {
      siteIds,
      pageSize: 1,
      start: useMemo(() => {
        const result = DateTime.max(liveTimer, date.startOf("day"));
        return result;
      }, [date, liveTimer]),
      end: useMemo(() => date.endOf("day"), [date]),
    },
    { enabled: !(isInThePast || isPastBookableDate) }
  );
  const slots = siteSlotsQuery.data;
  const refetchSlots = siteSlotsQuery.refetch;

  useEffect(() => {
    return () => {
      refetchSlots();
    };
  }, [refetchSlots, selected]);

  return (
    <button
      data-selected={selected}
      data-today={isToday}
      onClick={() => onClick(date)}
      className={styles.Day}
      disabled={!slots?.length || isInThePast || isPastBookableDate}
    >
      {date.day}
    </button>
  );
}
