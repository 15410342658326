import { Typography } from "@cur8/maneki";
import { ReactComponent as ShareIcon } from "assets/icons/16x16/16x16_share.svg";
import { ComponentProps, useCallback } from "react";
import { usePostScanShareContext } from "render/context/PostScanShareContext";
import { useTracking } from "render/hooks/useTracking";
import { Trans } from "../../trans";
import BgImage from "../ScanCompleteAsset/assets/background.png";

import { useScanCompleteAssetQuery } from "render/hooks/api/queries/useScanCompleteAssetQuery";
import { postScanShareEvent } from "../../tracking";
import styles from "./styles.module.sass";

export function PostScanShareViewEntrypoint({
  onClick,
  ...props
}: ComponentProps<"button">) {
  const { setIsOpen, setConfig, visit } = usePostScanShareContext();
  const { trackEvent } = useTracking();

  // prefetch share asset
  useScanCompleteAssetQuery(visit?.visitId, { enabled: !!visit?.visitId });

  const openPostScanShareModal = useCallback(() => {
    setConfig({ variant: "modal" });
    setIsOpen(true);
  }, [setConfig, setIsOpen]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      trackEvent(postScanShareEvent.openModalClick());
      openPostScanShareModal();
      onClick?.(e);
    },
    [onClick, openPostScanShareModal, trackEvent]
  );

  return (
    <button className={styles.button} onClick={handleClick} {...props}>
      <div className={styles.card}>
        <img
          className={styles.bg}
          src={BgImage}
          alt="Shareable asset thumbnail"
          width={100}
          height={100}
        />

        <div className={styles.iconContainer}>
          <ShareIcon className={styles.icon} />
        </div>
      </div>

      <Typography variant="body-xs">
        <Trans.ScanCard />
      </Typography>
    </button>
  );
}
