import { localize } from "render/context/InternationalizationContext";

const Error = {
  FailedToSaveMetric: localize({
    en_GB: <>An error occurred saving your data</>,
    sv_SE: <>Ett fel uppstod när ditt data skulle sparas</>,
  }),
  FailedToGiveConsent: localize({
    en_GB: <>An unexpected error happend</>,
    sv_SE: <>Ett oväntat fel har inträffat</>,
  }),
};

const StudyConsent = {
  Title: localize({
    en_GB: <>Please confirm your participation</>,
    sv_SE: <>Vänligen bekräfta ditt deltagande</>,
  }),
  Subtitle: localize({
    en_GB: (
      <>
        I hereby consent to participation in the studies. I acknowledge that the
        participation is voluntary and that I can choose to withdraw from
        participating at any time, even after I have provided my consent.
      </>
    ),
    sv_SE: (
      <>
        Jag ger härmed mitt samtycke till att delta i studierna. Jag erkänner
        att deltagande är frivilligt och att jag när som helst kan välja att
        avstå från att delta, även efter att jag har gett mitt samtycke.
      </>
    ),
  }),
  Footnote: localize({
    en_GB: (
      <>
        You can withdraw from our clinical studies any time from your profile or
        by contacting Neko Health AB which is the data controller and study
        principal by email at{" "}
        <a href="mailto:research@nekohealth.com">research@nekohealth.com</a>
      </>
    ),
    sv_SE: (
      <>
        Du kan hantera och ändra ditt deltagande när som helst på din profil
        eller genom att kontakta Neko Health AB, huvudansvarig för studien, via
        e-post på{" "}
        <a href="mailto:research@nekohealth.com">research@nekohealth.com</a>
      </>
    ),
  }),
  LearnMore: localize({
    en_GB: "Learn more",
    sv_SE: "Läs mer",
  }),
  Participate: localize({
    en_GB: "Participate in study",
    sv_SE: "Delta i studien",
  }),
  AgreeAndSign: localize({
    en_GB: "Agree and sign",
    sv_SE: "Samtyck och signera",
  }),
};

export const Trans = { Error, StudyConsent };
