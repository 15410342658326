import { MappedConsent } from "lib/consents/mapConsents";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import { useLocale } from "render/context/InternationalizationContext";

export function useLocalisedMetadataDocumentUri(consent: MappedConsent) {
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return consent.consentMetadata.documents.find(
    (document) => document.language === languageCode
  )?.uri;
}
