import { paths } from "render/routes/paths";

export function createRedirectUrl({ origin }: { origin: string }) {
  const url = new URL(origin);
  url.pathname = paths.auth.redirect.build({});
  //https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4458#issuecomment-2261145171
  //adding a trailing slash should help with safari browsers not redirecting
  url.pathname += url.pathname.endsWith("/") ? "" : "/";
  return url.toString();
}
