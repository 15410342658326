import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  Arrow: localize({
    en_GB: "back",
    sv_SE: "tillbaka",
  }),
  ErrorWhileDownloading: localize({
    en_GB: "An error happened while downloading the document",
    sv_SE: "Ett fel inträffade när vi försökte ladda ner dokumentet",
  }),
};
