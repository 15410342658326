import { localize } from "render/context/InternationalizationContext";

const Submitted = {
  Title: localize({
    en_GB: "We're verifying your ID",
    sv_SE: "Vi verifierar ditt ID",
  }),
  Text: localize({
    en_GB:
      "Thanks for completing this important step. We’ll let you know soon if we need any more info from you.",
    sv_SE:
      "Tack för att du har slutfört detta viktiga steg. Vi meddelar dig om vi behöver någon mer information från dig.",
  }),
  Done: localize({
    en_GB: "Done",
    sv_SE: "Klart",
  }),
};

const Pending = {
  Title: localize({
    en_GB: "It's time to verify your identity",
    sv_SE: "Bekräfta din ID-handling",
  }),
  Paragraph1: localize({
    en_GB:
      "To view the health report from your Neko Body Scan, we need to verify it's you.",
    sv_SE:
      "För att se hälsorapporten från din Neko Body Scan måste vi verifiera dig först.",
  }),
  SubParagraph1: localize({
    en_GB: "It takes about 2 minutes to complete",
    sv_SE: "Det tar ca 2 minuter",
  }),

  VerifyCta: localize({
    en_GB: "Let's begin",
    sv_SE: "Fortsätt",
  }),
};
const Fail = {
  Title: localize({
    en_GB: "Something went wrong",
    sv_SE: "Något gick fel",
  }),
  Paragraph1: localize({
    en_GB:
      "We were not able to verify your identity, please try again. Contact customer support if the issue persists.",
    sv_SE:
      "Vi kunde inte verifiera ditt ID, vänligen försök igen. Kontakta vår kundsupport om problemet kvarstår.",
  }),

  TryAgain: localize({
    en_GB: "Try again",
    sv_SE: "Försök igen",
  }),

  Skip: localize({
    en_GB: "Setup up later",
    sv_SE: "Lägg till senare",
  }),
};
const Success = {
  Title: localize({
    en_GB: "Your ID has been verified!",
    sv_SE: "Ditt ID har verifierats!",
  }),
  Paragraph: localize({
    en_GB:
      "Thank you for completing this important step, you will now be able to access all of your health information.",
    sv_SE:
      "Tack för att du har slutfört detta viktiga steg, du kommer nu att kunna komma åt alla dina hälsouppgifter.",
  }),
  CTA: localize({
    en_GB: "Continue",
    sv_SE: "Fortsätt",
  }),
};

export const Trans = {
  Pending,
  Submitted,
  Fail,
  Success,
};
