import { Answers } from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import {
  Allergies,
  AllergiesEnum,
  AllergiesList,
  AllergiesListEnum,
  OtherAllergies,
} from "lib/questionnaire/question-flows/onboarding/questions/allergies";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { MultiChoiceInput } from "render/ui/presentation/Questionnaire/components/MultiChoiceInput";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function AllergiesStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(Allergies);
  const label = TransQuestions.Allergies.Question();
  const { englishLabel } = TransQuestions.Allergies.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={Allergies}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(Allergies);
      }}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.AllergyExplanation />,
      }}
    >
      <ChoiceInput
        question={Allergies}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(Allergies, {
            answer,
            questionAnswer: {
              questionId: "allergies",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: Allergies.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}
function AllergiesListStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(AllergiesList);
  const label = TransQuestions.Allergies.AllergiesListQuestion();
  const { englishLabel } = TransQuestions.Allergies.AllergiesListQuestion;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  const cta = <Trans.General.Continue />;

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={AllergiesList}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(AllergiesList);
      }}
      cta={cta}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.AllergyExplanation />,
      }}
    >
      <MultiChoiceInput
        question={AllergiesList}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(AllergiesList, {
            answer,
            questionAnswer: {
              questionId: "allergies.list",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "textarray",
                value: AllergiesList.getValue(answer),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

function OtherAllergiesStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(OtherAllergies);
  const label = TransQuestions.Allergies.OtherAllergiesQuestion();
  const { englishLabel } = TransQuestions.Allergies.OtherAllergiesQuestion;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={OtherAllergies}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(OtherAllergies);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.AllergyExplanation />,
      }}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(OtherAllergies, {
            answer,
            questionAnswer: {
              questionId: "allergies.other",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "text",
                value: OtherAllergies.getValue(answer).trim(),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(AllergiesStep);

  if (answers.lookup(Allergies) === AllergiesEnum.Yes) {
    steps.push(AllergiesListStep);
  }

  if (
    answers.lookup(AllergiesList)?.includes(AllergiesListEnum.SomethingElse)
  ) {
    steps.push(OtherAllergiesStep);
  }
}
