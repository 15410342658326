import { AggregatedBloodPressureData } from "@cur8/rich-entity";
import { DateTime } from "luxon";

export type MeasuredBloodPressureData = Omit<
  AggregatedBloodPressureData,
  "measuredAt"
> & { measuredAt: DateTime };

export interface MeasuredBloodPressureDataLookUpValue {
  orderPosition: number;
  data: MeasuredBloodPressureData;
}

function withData(
  measurement: AggregatedBloodPressureData
): measurement is MeasuredBloodPressureData {
  return !!measurement.measuredAt;
}
function sortByMeasuredDate(
  a: MeasuredBloodPressureData,
  b: MeasuredBloodPressureData
) {
  return a.measuredAt > b.measuredAt ? 1 : -1;
}
function reduceToLookUpTable(
  acc: Map<string, MeasuredBloodPressureDataLookUpValue>,
  measurement: MeasuredBloodPressureData
) {
  acc.set(measurement.measuredAt.toISODate(), {
    data: measurement,
    orderPosition: acc.size + 1,
  });
  return acc;
}

export function createMeasurementsLookUpTable(
  measurements: AggregatedBloodPressureData[]
) {
  const result = [...measurements]
    .filter(withData)
    .sort(sortByMeasuredDate)
    .reduce(reduceToLookUpTable, new Map());

  return result;
}
