import { APITypesV1 } from "@cur8/api-client";
import { localize } from "render/context/InternationalizationContext";

const Title = {
  Starting: localize({
    en_GB: <>Ready to start tracking?</>,
    sv_SE: <>Redo att börja mäta?</>,
  }),
  InProgress: localize({
    en_GB: <>Tracking in progress</>,
    sv_SE: <>Mätning pågår</>,
  }),
  Completed: localize({
    en_GB: <>Done!</>,
    sv_SE: <>Klart!</>,
  }),
};

const WearLocation = localize<{ wearLocation: APITypesV1.WearableLocation }>({
  en_GB: ({ wearLocation }) => {
    const locationMap: Record<APITypesV1.WearableLocation, string> = {
      leftWrist: "left wrist",
      rightWrist: "right wrist",
      upperLeftArm: "upper left arm",
      upperRightArm: "upper right arm",
    };
    return (
      <>
        Don't forget to wear the monitor on your{" "}
        <b>{locationMap[wearLocation]}</b>.
      </>
    );
  },
  sv_SE: ({ wearLocation }) => {
    const locationMap: Record<APITypesV1.WearableLocation, string> = {
      leftWrist: "vänster handled",
      rightWrist: "höger handled",
      upperLeftArm: "",
      upperRightArm: "",
    };
    return (
      <>
        Bär armbandet på <b>{locationMap[wearLocation]}</b>.
      </>
    );
  },
});

const Note = {
  Progress: localize<{ daysLeft: number }>({
    en_GB: ({ daysLeft }) => (
      <>
        Your blood pressure is being continuously tracked throughout the day and
        night. Track for {daysLeft} more days to complete the assignment.
      </>
    ),
    sv_SE: ({ daysLeft }) => (
      <>
        Ditt blodtryck mäts kontinuerligt under hela dagen och natten. Mät under
        ytterligare {daysLeft} dagar för att slutföra uppgiften.
      </>
    ),
  }),
  Download: localize({
    en_GB: (
      <>
        Download the Aktiia app from the{" "}
        <a href="https://apps.apple.com/gb/app/aktiia/id1503559263">iOS</a> or{" "}
        <a href="https://play.google.com/store/apps/details?id=com.aktiia.android.production">
          Android
        </a>{" "}
        app store. Follow the instructions to pair your wristband and start
        tracking your blood pressure.
      </>
    ),
    sv_SE: (
      <>
        Ladda ner Aktiia-appen från{" "}
        <a href="https://apps.apple.com/gb/app/aktiia/id1503559263">iOS</a>{" "}
        eller{" "}
        <a href="https://play.google.com/store/apps/details?id=com.aktiia.android.production">
          Android
        </a>{" "}
        app store. Följ instruktionerna för att para ihop armbandet och börja
        mäta ditt blodtryck.
      </>
    ),
  }),
  DoctorFollowUp: localize({
    en_GB: (
      <>
        You've completed the assignment. Your doctor will reach out to you to
        discuss your results in the coming days.
      </>
    ),
    sv_SE: (
      <>
        Du har slutfört uppgiften. Din läkare kommer att kontakta dig för att
        diskutera dina resultat under de kommande dagarna.
      </>
    ),
  }),
  AssignmentCompleted: localize({
    en_GB: <>You've completed the assignment. Find your doctor's note below.</>,
    sv_SE: <>Du har slutfört uppgiften. Hitta din läkares bedömning nedan.</>,
  }),
};

export const Trans = { Title, Note, WearLocation };
