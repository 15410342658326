import { CSSProperties, PropsWithChildren } from "react";
import styles from "./styles.module.sass";

export function FadeIn({
  offset,
  children,
}: PropsWithChildren<{
  offset?: number;
}>) {
  return (
    <div
      className={styles.FadeIn}
      style={
        {
          "--offset": `${offset ?? 0}px`,
        } as CSSProperties
      }
    >
      {children}
    </div>
  );
}
