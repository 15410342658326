import { APITypesV1 } from "@cur8/api-client";
import { Typography } from "@cur8/maneki";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface DiscountCodeRedeemedProps {
  token: APITypesV1.DiscountToken;
}

export function DiscountCodeRedeemed({ token }: DiscountCodeRedeemedProps) {
  return (
    <>
      <Typography variant="eyebrow-m" color="subtle">
        <Trans.Title name={token.redeemerName} />
      </Typography>
      <div className={styles.row}>
        <div className={styles.code}>
          <Typography variant="label-m">{token.code}</Typography>
        </div>
      </div>
    </>
  );
}
