import { ReactComponent as WristbandIcon } from "./assets/wristband.svg";
import styles from "./styles.module.sass";

export function NoProgressTable() {
  return (
    <div className={styles.NoProgressTable}>
      <div>
        <WristbandIcon />
      </div>
    </div>
  );
}
