import { Typography } from "@cur8/maneki";
import { ReactComponent as Clock } from "assets/icons/16x16/16x16_clock.svg";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { StepView } from "render/views/OnboardingView/components/OnboardingFlowView/components/Questions/components/StepView";
import {
  Step,
  StepProps,
} from "render/views/OnboardingView/components/OnboardingFlowView/components/Questions/step";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

function LifeStyleIntro({
  onPrev,
  onNext,
  currentQuestion,
  totalQuestions,
}: StepProps) {
  const { data: patient } = usePatientQuery();

  const patientName =
    patient?.name?.displayName ?? patient?.name?.firstName ?? "";

  return (
    <StepView
      hideProgressBar={true}
      cta={
        <ActionButton variant="suggestion" onClick={onNext}>
          <Trans.Button />
        </ActionButton>
      }
      totalQuestionCount={totalQuestions}
      currentQuestion={currentQuestion}
      onPrev={onPrev}
      showCloseIcon
    >
      <section className={styles.content}>
        <Typography variant="display-s">
          <Trans.Title name={patientName} />
        </Typography>
        <Typography variant="body-m" color="subtle">
          <Trans.ContentOne />
        </Typography>
        <Typography variant="body-m" color="subtle">
          <Trans.ContentTwo />
        </Typography>
        <div className={styles.time}>
          <Clock />
          <Typography variant="body-s">
            <Trans.Time />
          </Typography>
        </div>
      </section>
    </StepView>
  );
}

export function filter(steps: Step[]) {
  steps.push(LifeStyleIntro);
}
