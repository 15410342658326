import React from "react";
import styles from "./styles.module.sass";

interface BurgerLayoutProps {
  children: [React.ReactNode, React.ReactNode, React.ReactNode];
}

export const BurgerLayout: React.FC<BurgerLayoutProps> = ({
  children: [header, content, footer],
}) => {
  return (
    <div className={styles.BurgerLayout}>
      <header>{header}</header>
      <main>{content}</main>
      <footer>{footer}</footer>
    </div>
  );
};
