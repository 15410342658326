import { Typography } from "@cur8/maneki";
import { UnitSystem } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback, useEffect, useState } from "react";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { usePatientMutation } from "render/hooks/mutations/usePatientMutation";
import { paths } from "render/routes/paths";
import { RadioButtons } from "render/ui/form/RadioButtons";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { EditPage } from "render/views/ProfileView/components/PatientProfile/components/EditPage";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface FormData {
  preferredUnitSystem: UnitSystem;
}

export function EditUnitPage() {
  const nav = {
    profileCredentials: useNav(paths.profileCredentials),
  };
  const patientQuery = usePatientQuery();
  const patientMutation = usePatientMutation({
    onSuccess: nav.profileCredentials.on({}),
  });

  const [formData, setFormData] = useState<FormData>();
  useEffect(() => {
    setFormData(patientQuery.data);
  }, [patientQuery.data]);

  const controls = {
    isPending: patientMutation.isPending,
    setUnit: (preferredUnitSystem: UnitSystem) =>
      setFormData({ preferredUnitSystem }),
    submit: useCallback(
      (e: React.FormEvent<HTMLFormElement> | undefined = undefined) => {
        e?.preventDefault();
        if (!formData || patientMutation.isPending) {
          return;
        }
        patientMutation.mutateAsync(formData);
      },
      [formData, patientMutation]
    ),
  };

  return (
    <EditPage
      title={<Trans.Title />}
      subTitle={<Trans.Description />}
      onBack={nav.profileCredentials.on({})}
      cta={
        <ActionButton
          disabled={controls.isPending}
          hideIcon
          variant="suggestion"
          onClick={controls.submit}
        >
          <div className={styles.fullWidth}>
            <Typography variant="label-m">
              {controls.isPending ? (
                <Trans.Actions.Saving />
              ) : (
                <Trans.Actions.Save />
              )}
            </Typography>
          </div>
        </ActionButton>
      }
    >
      <form className={styles.EditNamePage} onSubmit={controls.submit}>
        <RadioButtons
          entries={[
            {
              value: UnitSystem.Imperial,
              content: <Trans.Unit.Imperial />,
            },
            {
              value: UnitSystem.Metric,
              content: <Trans.Unit.Metric />,
            },
          ]}
          onChange={controls.setUnit}
          value={formData?.preferredUnitSystem}
        />
      </form>
    </EditPage>
  );
}
