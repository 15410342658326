import { localize } from "render/context/InternationalizationContext";

const Site = {
  ResetSites: localize({
    en_GB: "Any health centre",
    sv_SE: "Alla Hälsocenter",
  }),
  Header: localize({
    en_GB: "No available times",
    sv_SE: "Inga lediga tider",
  }),
  Description: localize({
    en_GB:
      "Please try selecting another health centre, or check again at a later time.",
    sv_SE:
      "Vänligen välj ett annat hälsocenter, eller försök igen vid ett senare tillfälle.",
  }),
};

const AllSites = {
  Header: localize({
    en_GB: "No available times",
    sv_SE: "Inga lediga tider",
  }),
  Description: localize({
    en_GB:
      "Please try selecting another health centre, or check again at a later time.",
    sv_SE:
      "Vänligen välj ett annat hälsocenter, eller försök igen vid ett senare tillfälle.",
  }),
};

export const Trans = {
  Site,
  AllSites,
};
