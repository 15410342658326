import { Locale, localize } from "render/context/InternationalizationContext";

/**
 * Calls `localize` with the specified arguments, but also makes the `en_GB`
 * translation statically available.
 * @example
 * const foo = createQuestion({ en_GB: 'english foo', sv_SE: 'swedish foo' });
 * setLocale('sv_SE');
 * foo();
 * // 'english foo'
 * foo.englishLabel;
 * // 'english foo'
 */
export function localizeQuestion(labels: Record<Locale, string>) {
  const localization = localize(labels) as Question;
  localization.englishLabel = labels.en_GB;
  return localization;
}

type Question = {
  (): string;
  englishLabel: string;
};
