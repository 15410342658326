import { localize } from "render/context/InternationalizationContext";

const AllSet = localize({
  en_GB: <>You’re all set</>,
  sv_SE: <>Klart</>,
});

const ConfirmationSentViaEmail = localize({
  en_GB: <>A confirmation email has been sent to your inbox.</>,
  sv_SE: <>En bekräftelse har skickats via e-post.</>,
});

const NextButton = localize({
  en_GB: <>Book your scan</>,
  sv_SE: <>Boka din scan</>,
});

export const Trans = {
  AllSet,
  ConfirmationSentViaEmail,
  NextButton,
};
