import { PhysicalIdType, PhysicalIdURI } from "@cur8/uri";
import { Personnummer } from "render/ui/format/Personnummer";

interface PhysicalIdentifierProps {
  uri: PhysicalIdURI;
}

export function PhysicalIdentifier({ uri }: PhysicalIdentifierProps) {
  if (uri.idType === PhysicalIdType.BankId) {
    return <Personnummer nr={uri.id} />;
  }

  return <>{uri.id}</>;
}
