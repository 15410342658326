import { Stage } from "../../../utils";
import { Trans } from "./trans";
import { HeaderLayout } from "./components/HeaderLayout";
import {
  AktiiaMonitorBloodPressureAssignmentData,
  Assignment,
} from "@cur8/rich-entity";

interface HeaderProps {
  assignment: Assignment<AktiiaMonitorBloodPressureAssignmentData>;
  stage: Stage;
  daysLeft: number | null;
  doctorsNote: string | null | undefined;
}

export function Header({
  stage,
  daysLeft,
  doctorsNote,
  assignment,
}: HeaderProps) {
  if (stage === Stage.Starting) {
    return (
      <HeaderLayout
        title={<Trans.Title.Starting />}
        subTitle={
          assignment.data.wearableLocation
            ? [
                <Trans.Note.Download />,
                <Trans.WearLocation
                  wearLocation={assignment.data.wearableLocation}
                />,
              ]
            : [<Trans.Note.Download />]
        }
      />
    );
  }
  if (stage === Stage.InProgress) {
    return (
      <HeaderLayout
        title={<Trans.Title.InProgress />}
        subTitle={
          daysLeft != null
            ? [<Trans.Note.Progress daysLeft={daysLeft} />]
            : undefined
        }
      />
    );
  }

  return (
    <HeaderLayout
      title={<Trans.Title.Completed />}
      subTitle={
        doctorsNote
          ? [<Trans.Note.AssignmentCompleted />]
          : [<Trans.Note.DoctorFollowUp />]
      }
    />
  );
}
