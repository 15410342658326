import { localize } from "render/context/InternationalizationContext";

export const Consent = localize({
  en_GB: "Consent and sign",
  sv_SE: "Samtyck och signera",
});

export const RenewConsent = localize({
  en_GB: "Renew Consent",
  sv_SE: "Förnya samtycke",
});

export const RevokeConsent = localize({
  en_GB: "Revoke Consent",
  sv_SE: "Återkalla samtycke",
});

export const ConsentError = localize({
  en_GB: <>An error happened while consenting</>,
  sv_SE: <>Ett fel inträffade när du försökte samtycka</>,
});

export const RemoveConsentError = localize({
  en_GB: <>An error happen while removing your consent</>,
  sv_SE: <>Ett fel inträffade när vi försökte återkalla ditt samtycke</>,
});
