import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { silenceAbort } from "lib/error";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { QueryOptions } from "typings/query";

interface Options {
  signal?: AbortSignal;
}

interface Params {
  country?: string;
}

const queryKey = (params: Params) => {
  return ["sites", params.country];
};

const queryFn = (
  apiClient: APIClient,
  params: Params,
  signal: AbortSignal | undefined
) => {
  const req = apiClient.site.querySites({
    country: params?.country,
  });
  req.result.catch(silenceAbort);
  signal?.addEventListener("abort", req.abandon);

  return req.result.then(({ items }) => items);
};

type Key = ReturnType<typeof queryKey>;
type Body = Awaited<ReturnType<typeof queryFn>>;

export function useSitesQuery<T = Body>(
  params: Params = {},
  options: QueryOptions<Body, Key, T> = {}
) {
  const apiClient = useAPIClient();

  const requestFn = useCallback(
    ({ signal }: Options) => {
      return queryFn(apiClient, params, signal);
    },
    [apiClient, params]
  );

  return useQuery({
    ...options,
    queryFn: requestFn,
    queryKey: queryKey(params),
  });
}
