import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { Accordion } from "render/ui/presentation/AccordionV2";
import { MetricSection } from "./MetricSection";
import { MetricViewProps } from "./types";
import { FAQTitles } from "../trans";
import styles from "./styles.module.sass";

type MetricDetailHeaderProps = MetricViewProps & {
  FAQText?: {
    Why: () => JSX.Element;
    High?: () => JSX.Element;
    Low?: () => JSX.Element;
    Trend: () => JSX.Element;
    Aim?: () => JSX.Element;
  };
};

function FAQSection({
  FAQText,
}: Required<Pick<MetricDetailHeaderProps, "FAQText">>) {
  return (
    <Accordion>
      <Accordion.Item
        header={<FAQTitles.Why />}
        text={<FAQText.Why />}
        id="why"
      />
      {FAQText.High ? (
        <Accordion.Item
          header={<FAQTitles.High />}
          text={<FAQText.High />}
          id="high"
        />
      ) : null}
      {FAQText.Low ? (
        <Accordion.Item
          header={<FAQTitles.Low />}
          text={<FAQText.Low />}
          id="low"
        />
      ) : null}
      <Accordion.Item
        header={<FAQTitles.Trend />}
        text={<FAQText.Trend />}
        id="trend"
      />
      {FAQText.Aim ? (
        <Accordion.Item
          header={<FAQTitles.Aim />}
          text={<FAQText.Aim />}
          id="aim"
        />
      ) : null}
    </Accordion>
  );
}

export function MetricDetails({
  label,
  scanNum,
  setScanNum,
  changeDisplayValue,
  previousDisplayValue,
  numOfScans,
  isClinicalStudy,
  FAQText,
  hideChange,
  displayValue,
  scanDate,
  changeIcon,
}: MetricDetailHeaderProps) {
  return (
    <FullScreenPageLayout>
      <MetricSection
        changeIcon={changeIcon}
        changeDisplayValue={changeDisplayValue}
        previousDisplayValue={previousDisplayValue}
        displayValue={displayValue}
        label={label}
        scanNum={scanNum}
        setScanNum={setScanNum}
        numOfScans={numOfScans}
        isClinicalStudy={isClinicalStudy}
        hideChange={hideChange}
        scanDate={scanDate}
      >
        <div className={styles.bottomHalf}>
          {FAQText !== undefined ? <FAQSection FAQText={FAQText} /> : null}
        </div>
      </MetricSection>
    </FullScreenPageLayout>
  );
}
