import { Typography } from "@cur8/maneki";
import { useNav } from "@pomle/react-router-paths";
import * as EmailValidator from "email-validator";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { usePatientMutation } from "render/hooks/mutations/usePatientMutation";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { paths } from "render/routes/paths";
import { Input } from "render/ui/presentation/Input";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { EditPage } from "../EditPage/EditPage";
import { Trans } from "./Trans";
import styles from "./styles.module.sass";

interface FormFields {
  email: React.ReactElement;
}

export function EditEmailPage() {
  const patientQuery = usePatientQuery();
  const patientMutation = usePatientMutation();

  const [email, setEmail] = useState<string>("");

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!patientQuery.data) {
      return;
    }

    const { contactDetails } = patientQuery.data;
    setEmail(contactDetails.email ?? "");
  }, [patientQuery.data]);

  const nav = {
    profileCredentials: useNav(paths.profileCredentials),
  };

  const errors = useMemo<Partial<FormFields>>(() => {
    const errors: Partial<FormFields> = {};

    if (email.trim().length === 0) {
      errors.email = <Trans.Validation.Email.Required />;
    } else {
      const valid = EmailValidator.validate(email);
      if (!valid) {
        errors.email = <Trans.Validation.Email.NotValid />;
      }
    }

    return errors;
  }, [email]);

  const handleOnSave = useCallback(
    async (e: React.FormEvent<HTMLFormElement> | undefined = undefined) => {
      if (e) {
        e.preventDefault();
      }
      setIsSubmitted(true);
      if (Object.keys(errors).length > 0) {
        return;
      }

      await patientMutation.mutateAsync({
        email,
      });

      await patientQuery.refetch();
      nav.profileCredentials.go({});
    },
    [errors, nav.profileCredentials, patientMutation, patientQuery, email]
  );

  const onSaveHandle = useAsyncHandle(handleOnSave);

  const renderError = useCallback(
    (error?: React.ReactElement) => {
      return !isSubmitted || !error ? undefined : error;
    },
    [isSubmitted]
  );

  return (
    <EditPage
      title={<Trans.Title />}
      subTitle={<Trans.Subtitle />}
      onBack={nav.profileCredentials.on({})}
      cta={
        <ActionButton
          hideIcon
          variant="suggestion"
          onClick={onSaveHandle.callback}
        >
          <div className={styles.fullWidth}>
            <Typography variant="label-m">
              {onSaveHandle.busy ? (
                <Trans.Actions.Saving />
              ) : (
                <Trans.Actions.Save />
              )}
            </Typography>
          </div>
        </ActionButton>
      }
    >
      <form className={styles.EditNamePage} onSubmit={onSaveHandle.callback}>
        <Input
          label={<Trans.Label.Email />}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
          onClear={() => {}}
          hideClearButton
          error={renderError(errors.email)}
        />
      </form>
    </EditPage>
  );
}
