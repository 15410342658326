import { AggregatedBloodPressureData } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AnimationTime } from "../../../../utils/animationConstants";
import { DataDisplay } from "./components/DataDisplay";
import styles from "./styles.module.sass";

interface CandleProps {
  toY: (v: number) => number;
  data: AggregatedBloodPressureData;
  onClick: () => void;
  delay: number;
  isHidden: boolean;
}

export function Candle({ data, toY, onClick, delay, isHidden }: CandleProps) {
  const lineRef = useRef<SVGLineElement>(null);
  const [lineDashOffset, setLineDashOffset] = useState<number>();
  const isNumber = useCallback(
    (n: any): n is number => !isNaN(parseFloat(n)) && !isNaN(n - 0),
    []
  );
  const filterNumbers = useCallback(
    (val: any[]) => val.filter(isNumber),
    [isNumber]
  );

  const getRanges = useCallback(
    (values: number[]) => ({
      min: toY(Math.min(...values)),
      max: toY(Math.max(...values)),
    }),
    [toY]
  );
  const systolicDay = useMemo(() => {
    const values = filterNumbers([
      data.avgDay?.systolic,
      data.maxDay?.systolic,
      data.minDay?.systolic,
    ]);

    return getRanges(values);
  }, [data, filterNumbers, getRanges]);

  const diastolicDay = useMemo(() => {
    const values = filterNumbers([
      data.avgDay?.diastolic,
      data.maxDay?.diastolic,
      data.minDay?.diastolic,
    ]);

    return getRanges(values);
  }, [data, filterNumbers, getRanges]);

  const line = useMemo(() => {
    return {
      min: Math.min(
        ...Object.values(diastolicDay),
        ...Object.values(systolicDay)
      ),
      max: Math.max(
        ...Object.values(diastolicDay),
        ...Object.values(systolicDay)
      ),
    };
  }, [diastolicDay, systolicDay]);

  const colors = {
    night: "#4c6a70",
    day: "#263032",
    line: "#9ebcc3",
  };

  useEffect(() => {
    if (!lineRef.current) {
      return;
    }

    setLineDashOffset(lineRef.current.getTotalLength());
  }, []);

  const diff = line.max - line.min;

  const calcAnimationDuration = useCallback(
    ({ max, min }: { max: number; min: number }) => {
      const diff = line.max - line.min;
      const duration =
        (Math.abs(max - min) / diff) * AnimationTime.GraphAnimation;

      return duration;
    },
    [line]
  );
  const calcAnimationDelay = useCallback(
    ({ max }: { max: number }) => {
      return (
        AnimationTime.GraphAnimation -
        ((line.max - max) / diff) * AnimationTime.GraphAnimation
      );
    },
    [line, diff]
  );

  const diastolicDelay = calcAnimationDelay(diastolicDay);
  const diastolicDuration = calcAnimationDuration(diastolicDay);
  const systolicDuration = calcAnimationDuration(systolicDay);

  return (
    <div className={classNames(styles.Candle, { [styles.hidden]: isHidden })}>
      <svg
        onClick={onClick}
        overflow="visible"
        width="32px"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
      >
        <line
          ref={lineRef}
          className={styles.line}
          x1="50%"
          y1={`${line.min}%`}
          y2={`${line.max}%`}
          x2="50%"
          stroke={colors.line}
          style={{
            animationDuration: `${AnimationTime.GraphAnimation}s`,
            animationDelay: `${delay}s`,
            strokeDasharray: lineDashOffset,
            strokeDashoffset: lineDashOffset,
          }}
        />
        {lineDashOffset != null && (
          <>
            <DataDisplay
              duration={systolicDuration}
              delay={`${delay}s`}
              max={systolicDay.max}
              min={systolicDay.min}
              color={colors.day}
            />
            <DataDisplay
              duration={diastolicDuration}
              delay={`${delay + diastolicDelay}s`}
              max={diastolicDay.max}
              min={diastolicDay.min}
              color={colors.day}
            />
          </>
        )}
      </svg>
    </div>
  );
}
