import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";

function queryFn(apiClient: APIClient, sessionId: string) {
  return apiClient.checkinSession.joinSession({
    sessionId,
  }).result;
}

function queryKey(sessionId: string) {
  return ["join-concierge-session", sessionId];
}

export function useJoinConciergeSessionQuery({
  sessionId,
}: {
  sessionId: string;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryFn: () => {
      return queryFn(apiClient, sessionId);
    },
    queryKey: queryKey(sessionId),
  });
}
