import { Carousel } from "./components/Carousel";
import styles from "./styles.module.sass";

interface QuestionnaireProps {
  activeIndex: number;
  children: React.ReactNode;
}

export function Questionnaire({ activeIndex, children }: QuestionnaireProps) {
  return (
    <div className={styles.Questionnaire}>
      <Carousel index={activeIndex}>{children}</Carousel>
    </div>
  );
}
