import { Typography } from "@cur8/maneki";
import { ReactComponent as ArrowIcon } from "assets/icons/24x24/24x24_arrow_left.svg";
import { ReactComponent as CloseIcon } from "assets/icons/24x24/24x24_close.svg";
import React, { ReactNode } from "react";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";

interface EditPageProps {
  onBack: () => void;
  title: ReactNode;
  subTitle?: ReactNode;
  cta: ReactNode;
}

export function EditPage({
  children,
  onBack,
  title,
  subTitle,
  cta,
}: React.PropsWithChildren<EditPageProps>) {
  return (
    <div className={styles.EditPage}>
      <div className={styles.content}>
        <div className={styles.header}>
          <LogoHeader
            leftElement={
              <div className={styles.closeIcon}>
                <div className={styles.mobile}>
                  <IconButton icon={<ArrowIcon />} onClick={onBack} />
                </div>
                <div className={styles.desktop}>
                  <IconButton icon={<CloseIcon />} onClick={onBack} />
                </div>
              </div>
            }
          />
        </div>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              <Typography variant="display-s">{title}</Typography>
            </div>
            {subTitle && (
              <Typography variant="body-m" color="subtle">
                {subTitle}
              </Typography>
            )}
          </div>
          {children}
        </div>
        <div className={styles.cta}>{cta}</div>
      </div>
    </div>
  );
}
