import { PropsWithChildren } from "react";
import { VideoBackground } from "render/views/_shared/VideoBackground";
import { PopupFrame } from "./components/PopUpFrame";

export function ApplicationLayout({ children }: PropsWithChildren<{}>) {
  return (
    <PopupFrame>
      {children}
      <VideoBackground />
    </PopupFrame>
  );
}
