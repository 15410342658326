import { useCallback, useMemo } from "react";
import { useConfig } from "render/context/ConfigContext";

type Format = "html" | "pdf";

export enum Flags {}

const params = new URLSearchParams(window.location.search);

export const FLAGS = new Set<Flags>(
  params.getAll("flags") as unknown as Flags[]
);

export class Study {
  public name: string;
  public format: Format;

  private baseUrl: string;

  constructor(
    name: string,
    { baseUrl, format }: { baseUrl: string; format: Format }
  ) {
    this.baseUrl = baseUrl;
    this.name = name;
    this.format = format;
    this.getUrl = this.getUrl.bind(this);
  }

  getUrl(lang: string) {
    return new URL(
      `${this.name}.${lang}.${this.format}`,
      this.baseUrl
    ).toString();
  }
}

export function useConsentsMap() {
  const { appConfig } = useConfig();
  const getOptions = useCallback(
    ({ format }: { format: Format }) => {
      return {
        baseUrl: appConfig.legalApiUrl,
        format,
      };
    },
    [appConfig.legalApiUrl]
  );

  return useMemo(
    () => ({
      studies: {
        CARDIO: new Study(
          "forms/study/2023-cardio",
          getOptions({ format: "html" })
        ),
        DERMAFLOW: new Study(
          "forms/study/2023-dermaflow",
          getOptions({ format: "html" })
        ),
        SPECTRUM: new Study(
          "forms/study/2023-spectrum",
          getOptions({ format: "html" })
        ),
      },
      other: {
        PATIENTJOURNAL: new Study(
          "forms/sweden/2023-december-journal-access",
          getOptions({ format: "html" })
        ),
        DEVELOPMENTANDTESTING: new Study(
          "forms/gdpr/2023-june-testing-and-development",
          getOptions({ format: "html" })
        ),
        PRIVACYPOLICY: new Study(
          "/pdfs/privacy-policy/2023-10-18-Neko-Health-Privacy-Notice",
          getOptions({ format: "pdf" })
        ),
      },
    }),
    [getOptions]
  );
}
