import styles from "./styles.module.sass";

export function Spinner() {
  return (
    <svg className={styles.spinner} viewBox="25 25 50 50">
      <circle
        className={styles.circle}
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
