export function fileSize(size: number) {
  const magnitude = size && Math.floor(Math.log2(size) / 10);
  const byteSize = size / 1024 ** magnitude;
  const length = Math.min(magnitude, 2);
  const prefix = prefixes[magnitude - 1];
  return [byteSize.toFixed(length), prefix, unit].filter(Boolean).join("");
}

const prefixes = "KMGTP";

const unit = "B";
