import { PropsWithChildren, useCallback, useRef } from "react";
import styles from "./styles.module.sass";

interface BackdropProps {
  active: boolean;
  /**@default 'blur' */
  kind?: "blur" | "subtle" | "transparent";
  onOutsideClick?: () => void;
}

export function Backdrop({
  active,
  children,
  kind = "blur",
  onOutsideClick,
}: PropsWithChildren<BackdropProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const onBackgroundClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target !== ref.current) {
        return;
      }
      onOutsideClick?.();
    },
    [onOutsideClick]
  );

  return (
    <div
      ref={ref}
      className={styles.Backdrop}
      data-active={active}
      data-kind={kind}
      onClick={onBackgroundClick}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
}
