import { AuthError } from "@azure/msal-browser";
import { Typography } from "@cur8/maneki";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { ActionButton } from "render/ui/trigger/ActionButton/ActionButton";
import styles from "./style.module.sass";
import { Trans } from "./trans";

interface LoginErrorViewProps {
  error: AuthError;
  login: () => void;
}

export function LoginErrorView({ error, login }: LoginErrorViewProps) {
  return (
    <FullScreenPageLayout>
      <div className={styles.body}>
        <div className={styles.content}>
          <Typography variant="display-s">
            <Trans.Header />
          </Typography>
          <Typography variant="title-l" color="subtle">
            {error.message}
          </Typography>
          <ActionButton onClick={login}>
            <Trans.TryAgain />
          </ActionButton>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
