import { localize } from "render/context/InternationalizationContext";

type value = {
  value: React.ReactNode;
  subLabel?: React.ReactNode;
};

export const Trans = {
  Normal: localize({
    en_GB: "Normal",
    sv_SE: "Normal",
  }),
  Warning: localize({
    en_GB: "Monitor",
    sv_SE: "Bevaka",
  }),
  NoData: localize({
    en_GB: "No data",
    sv_SE: "Ingen information",
  }),
  Optimal: localize({
    en_GB: "Optimal",
    sv_SE: "Optimalt",
  }),
  PreviousLabel: localize({
    en_GB: "Previous visit:",
    sv_SE: "Föregående besök:",
  }),
  Previous: localize<value>({
    en_GB: ({ value, subLabel }) => (
      <>
        Previous visit: {value} {subLabel}
      </>
    ),
    sv_SE: ({ value, subLabel }) => (
      <>
        Föregående besök: {value} {subLabel}
      </>
    ),
  }),
  Increased: localize<value>({
    en_GB: ({ value }) => <>Increased by {value}</>,
    sv_SE: ({ value }) => <>En ökning med {value}</>,
  }),
  Decreased: localize<value>({
    en_GB: ({ value }) => <>Decreased by {value}</>,
    sv_SE: ({ value }) => <>En minskning med {value}</>,
  }),
  Close: localize({
    en_GB: "Close",
    sv_SE: "Stְänga",
  }),
  PreviousScan: localize({
    en_GB: "Go to previous scan",
    sv_SE: "Gå till tidigare scan",
  }),
  NextScan: localize({
    en_GB: "Go to next scan",
    sv_SE: "Gå till nästa scan",
  }),
  UnknownDate: localize({
    en_GB: "unknown date",
    sv_SE: "okänt datum",
  }),
  Left: localize({
    en_GB: "left",
    sv_SE: "vänster",
  }),
  Right: localize({
    en_GB: "right",
    sv_SE: "höger",
  }),
  NoDataText: localize({
    en_GB: "No data available for this visit.",
    sv_SE: "Ingen data tillgänglig för detta besök",
  }),
  Baseline: localize({
    en_GB: "Baseline visit",
    sv_SE: "Första besöket",
  }),
};

export const FAQTitles = {
  Why: localize({
    en_GB: "Why do we collect it?",
    sv_SE: "Varför mäter vi det? ",
  }),
  High: localize({
    en_GB: "What does a high result mean?",
    sv_SE: "Vad betyder ett högt resultat?",
  }),
  Low: localize({
    en_GB: "What does a low result mean?",
    sv_SE: "Vad betyder ett lågt resultat?",
  }),
  Trend: localize({
    en_GB: "What does the trend tell me?",
    sv_SE: "Vad säger trenden mig?",
  }),
  Aim: localize({
    en_GB: "What should I aim for?",
    sv_SE: "Vad ska jag sikta på?",
  }),
};
