import { ChoiceQuestion, TextQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum DiabetesEnum {
  Yes = "Yes",
  No = "No",
}

export const Diabetes = new ChoiceQuestion(<Trans.Diabetes.Question />, [
  [<Trans.Diabetes.No />, DiabetesEnum.No],
  [<Trans.Diabetes.Yes />, DiabetesEnum.Yes],
]);

export const DiabetesDetails = new TextQuestion(<Trans.Diabetes.Details />);
