type Side = "left" | "right";

type GetHighestSideProps = {
  left?: number;
  right?: number;
};

export function getHighestSide({ left, right }: GetHighestSideProps): Side {
  if (left === undefined) return "right";
  if (right === undefined) return "left";

  return left > right ? "left" : "right";
}
