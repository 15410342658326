import PointCloudVideoWideMP4 from "assets/video/pointcloud/background-pointcloud-wide.mp4";
import PointCloudImageWide from "assets/video/pointcloud/background-pointcloud-wide.png";
import PointCloudVideoWideWebM from "assets/video/pointcloud/background-pointcloud-wide.webm";
import PointCloudVideoMP4 from "assets/video/pointcloud/background-pointcloud.mp4";
import PointCloudImage from "assets/video/pointcloud/background-pointcloud.png";
import PointCloudVideoWebm from "assets/video/pointcloud/background-pointcloud.webm";
import { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.sass";

type Key = "mobile" | "desktop";

function getKey() {
  return window.innerWidth > 980 ? "desktop" : "mobile";
}

export function VideoBackground() {
  const [opacity, setOpacity] = useState(0);
  const [key, setKey] = useState<Key>(getKey());

  const showVideo = useCallback(() => {
    setOpacity(1);
  }, []);

  useEffect(() => {
    const updateKey = () => {
      setKey(getKey());
    };

    window.addEventListener("resize", updateKey);
    return () => window.removeEventListener("resize", updateKey);
  }, []);

  return (
    <>
      <picture key={`${key}_picture`}>
        <source srcSet={PointCloudImage} media="(max-width: 980px)" />
        <source srcSet={PointCloudImageWide} />
        <img
          className={styles.videoBackgroundImage}
          src={PointCloudImageWide}
          alt="wave-background"
        />
      </picture>
      <video
        key={`${key}_video`}
        style={{
          opacity,
        }}
        onPlay={showVideo}
        className={styles.videoBackground}
        preload="auto"
        autoPlay
        loop
        muted
        playsInline
      >
        <source
          src={PointCloudVideoWebm}
          type="video/webm"
          media="(max-width: 980px)"
        />
        <source
          src={PointCloudVideoMP4}
          type="video/mp4"
          media="(max-width: 980px)"
        />
        <source src={PointCloudVideoWideWebM} type="video/webm" />
        <source src={PointCloudVideoWideMP4} type="video/mp4" />
      </video>
    </>
  );
}
