import { derace } from "@pomle/throb";
import { useEffect, useMemo, useState } from "react";
import * as THREE from "three";

export function useTexture(src: string) {
  const [texture, setTexture] = useState<THREE.Texture>();

  const load = useMemo(() => {
    const loader = new THREE.TextureLoader();
    function load(src: string) {
      return loader.loadAsync(src);
    }

    return derace(load);
  }, []);

  useEffect(() => {
    load(src).then(([texture, sync]) => {
      if (sync) {
        setTexture(texture);
      }
    });

    return () => {
      setTexture(undefined);
    };
  }, [load, src]);

  useEffect(() => {
    if (texture) {
      return () => {
        texture.dispose();
      };
    }
  }, [texture]);

  return texture;
}
