import { Skeleton } from "render/ui/presentation/Skeleton";
import styles from "./styles.module.sass";

function InputSkeleton() {
  return (
    <div className={styles.inputSkeleton}>
      <div className={styles.top}>
        <Skeleton />
      </div>
      <div className={styles.bottom}>
        <Skeleton />
      </div>
    </div>
  );
}

export function PaymentFormSkeleton() {
  return (
    <div className={styles.PaymentFormSkeleton}>
      <section className={styles.skeletonSection}>
        <InputSkeleton />
        <InputSkeleton />
      </section>
      <section className={styles.skeletonSection}>
        <InputSkeleton />
      </section>
      <section className={styles.skeletonSection}>
        <InputSkeleton />
        <InputSkeleton />
      </section>
    </div>
  );
}
