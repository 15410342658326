import { MultiChoiceQuestion, TextQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum RelevantConditionsEnum {
  Autoimmune = "Autoimmune disease",
  Cancer = "Cancer",
  Neurological = "Neurological disease",
  SomethingElse = "Something else",
}

export const RelevantConditions = new MultiChoiceQuestion(
  <Trans.RelevantConditions.Question />,
  [
    [
      <Trans.RelevantConditions.Autoimmune />,
      RelevantConditionsEnum.Autoimmune,
    ],
    [<Trans.RelevantConditions.Cancer />, RelevantConditionsEnum.Cancer],
    [
      <Trans.RelevantConditions.Neurological />,
      RelevantConditionsEnum.Neurological,
    ],
    [
      <Trans.RelevantConditions.SomethingElse />,
      RelevantConditionsEnum.SomethingElse,
    ],
  ]
);

export const RelevantConditionsAutoimmuneDetails = new TextQuestion(
  <Trans.RelevantConditions.AutoimmuneDetails />
);

export const RelevantConditionsCancerDetails = new TextQuestion(
  <Trans.RelevantConditions.CancerDetails />
);

export const RelevantConditionsSomethingElseDetails = new TextQuestion(
  <Trans.RelevantConditions.SomethingElseDetails />
);
