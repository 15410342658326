import { PropsWithChildren, useEffect } from "react";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useInternationalization } from "../../InternationalizationContext";
import { language2Locale } from "../../lib/language2Locale";

export function AuthSessionLanguageSetter({ children }: PropsWithChildren<{}>) {
  const { setLocale } = useInternationalization();
  const patientQuery = usePatientQuery();

  useEffect(() => {
    if (!patientQuery.data?.preferredLanguage) {
      return;
    }
    const locale = language2Locale(patientQuery.data.preferredLanguage);
    if (!locale) {
      return;
    }

    setLocale(locale);
  }, [patientQuery.data?.preferredLanguage, setLocale]);

  return <>{children}</>;
}
