import { localize } from "render/context/InternationalizationContext";

export const Validation = {
  Phone: {
    Required: localize({
      en_GB: "Phone number is required",
      sv_SE: "Telefonnummer krävs",
    }),
    InvalidCountry: localize({
      en_GB: "Country code not provided",
      sv_SE: "Landskod saknas",
    }),
    NotNumber: localize({
      en_GB: "Not a valid phone number",
      sv_SE: "Ogiltigt telefonnummer",
    }),
    TooShort: localize({
      en_GB: "Phone number is too short",
      sv_SE: "Telefonnumret är för kort",
    }),
    TooLong: localize({
      en_GB: "Phone number is too long",
      sv_SE: "Telefonnumret är för långt",
    }),
    InvalidLength: localize({
      en_GB: "Phone number length is invalid",
      sv_SE: "Telefonnumrets längd är ogiltig",
    }),
  },
  Email: {
    Required: localize({
      en_GB: "Email is required",
      sv_SE: "Email krävs",
    }),
    NotValid: localize({
      en_GB: "Not a valid email address",
      sv_SE: "Ogiltig mail",
    }),
  },
};

const Title = localize({
  en_GB: "Email",
  sv_SE: "Email",
});
const Subtitle = localize({
  en_GB: "The Medical Team will use this email to contact you.",
  sv_SE:
    "Den email adress som det medicinska teamet kommer att använda för att kontakta dig.",
});
const Label = {
  Email: localize({
    en_GB: "Email",
    sv_SE: "Email",
  }),
};

const Actions = {
  Save: localize({
    en_GB: "Save",
    sv_SE: "Spara",
  }),
  Saving: localize({
    en_GB: "Saving...",
    sv_SE: "Sparar...",
  }),
};

export const Trans = {
  Title,
  Label,
  Subtitle,
  Validation,
  Error,
  Actions,
};
