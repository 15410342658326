import * as THREE from "three";
import { frag } from "./fragment";
import { vert } from "./vertex";

export class AvatarPointCloudMaterial extends THREE.ShaderMaterial {
  color = new THREE.Color();
  shimmer = { origin: 0, width: 0 };
  scan = { origin: 0, width: 1000 };

  points = {
    size: 1,
    mix: { position: 1, scatter: 0, front: 0, back: 0, heart: 0 },
  };

  falloff = {
    center: new THREE.Vector3(0, 0, 0),
    near: 2000,
    far: 5000,
  };

  wave = {
    length: 1,
    progression: 0,
    direction: new THREE.Vector3(0, 0, 0),
  };

  time = { value: 0 };

  constructor({ pointTexture }: { pointTexture: THREE.Texture }) {
    super({
      uniforms: THREE.UniformsUtils.merge([THREE.UniformsLib["fog"]]),
      vertexShader: vert,
      fragmentShader: frag,
      depthTest: false,
      fog: true,
      transparent: true,
      vertexColors: true,
    });

    Object.assign(this.uniforms, {
      uColor: { value: this.color },
      shimmer: {
        value: this.shimmer,
      },
      scan: {
        value: this.scan,
      },
      points: {
        value: this.points,
      },
      pointTexture: { value: pointTexture },
      wave: { value: this.wave },
      falloff: { value: this.falloff },
      time: this.time,
    });
  }
}
