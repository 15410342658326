import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

const queryKey = (patientId: string) =>
  ["earliest-upcoming-visit", patientId] as const;

const queryFn = async (apiClient: APIClient, patientId: string) => {
  const upcomingStatus = [
    APITypesV1.VisitStatus.CheckedIn,
    APITypesV1.VisitStatus.Scheduled,
  ] as const;

  return Promise.all(
    upcomingStatus.map((status) => {
      return apiClient.visit.getPatientVisits(
        { patientId },
        {
          status,
          pageSize: 1,
          order: APITypesV1.SortOrder.Asc,
          endDateTimeOffsetRange: {
            start: DateTime.now().toISO(),
          },
        }
      ).result;
    })
  )
    .then((requests) => {
      return requests.reduce<APITypesV1.Visit[]>((acc, request) => {
        acc.push(...request.items);
        return acc;
      }, []);
    })
    .then((visits) => {
      return visits.map(fromAPI.toVisit);
    })
    .then((visits) => {
      return visits.at(0) ?? null;
    });
};

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useEarliestUpcomingVisitQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: () => queryFn(apiClient, patientId),
    queryKey: queryKey(patientId),
  });
}
