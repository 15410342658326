import {
  ChoiceQuestion,
  MultiChoiceQuestion,
  TextQuestion,
} from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum MedicationsEnum {
  Yes = "Yes",
  No = "No",
}

export enum MedicationsTypeEnum {
  AntiDiabetic = "Anti-diabetic",
  Cholesterol = "Cholesterol",
  BloodPressure = "Blood pressure",
  SomethingElse = "Something else",
}

export const Medications = new ChoiceQuestion(<Trans.Medication.Question />, [
  [<Trans.Medication.No />, MedicationsEnum.No],
  [<Trans.Medication.Yes />, MedicationsEnum.Yes],
]);

export const MedicationsType = new MultiChoiceQuestion(
  <Trans.Medication.TypeQuestion />,
  [
    [<Trans.Medication.AntiDiabetic />, MedicationsTypeEnum.AntiDiabetic],
    [<Trans.Medication.Cholesterol />, MedicationsTypeEnum.Cholesterol],
    [<Trans.Medication.BloodPressure />, MedicationsTypeEnum.BloodPressure],
    [<Trans.Medication.SomethingElse />, MedicationsTypeEnum.SomethingElse],
  ]
);

export const MedicationsSomethingElse = new TextQuestion(
  <Trans.Medication.SomethingElseQuestion />
);
