import { localize } from "render/context/InternationalizationContext";

export const Title = localize({
  en_GB: "Our clinical studies",
  sv_SE: "Våra kliniska studier",
});

export const Paragraph1 = localize({
  en_GB: (
    <>
      As a part of the Neko Body Scan we conduct clinical studies using novel
      scanning techniques. Your health data helps us develop our tools so we can
      identify diseases and prevent or treat them earlier.
    </>
  ),
  sv_SE: (
    <>
      För att göra en Neko Body Scan behöver vi ditt samtycke till att deltaga i
      våra kliniska studier. Dina hälsouppgifter hjälper oss att utveckla våra
      verktyg så att vi kan hitta sjukdomar ännu snabbare och bättre.
    </>
  ),
});

export const Paragraph2 = localize({
  en_GB: (
    <>
      The trials we conduct have been reviewed by The Swedish Medical Products
      Agency (Läkemedelsverket) and the Swedish Ethical Review Authority
      (Etikprövningsmyndigheten).
    </>
  ),
  sv_SE: (
    <>
      Studierna har granskats och godkänts av Läkemedelsverket och
      Etikprövningsmyndigheten.
    </>
  ),
});

export const Next = localize({
  en_GB: "Next",
  sv_SE: "Gå vidare",
});
