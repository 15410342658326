import { localize } from "render/context/InternationalizationContext";

const maps = "http://google.com/maps/?q=Regeringsgatan+61,+Stockholm";
const ReturnLocation = {
  Regeringsgatan: localize({
    en_GB: (
      <>
        <a href={maps} target="_blank" rel="noreferrer">
          Regeringsgatan 61B, Stockholm
        </a>
      </>
    ),
    sv_SE: (
      <>
        <>
          <a href={maps} target="_blank" rel="noreferrer">
            Regeringsgatan 61B, Stockholm
          </a>
        </>
      </>
    ),
  }),
};

const HowToReturn = {
  Header: localize({
    en_GB: <>How to return</>,
    sv_SE: <>Hur returnerar man</>,
  }),
  Text: localize<{
    from: string;
    to: string;
    place: JSX.Element;
  }>({
    en_GB: ({ place, from, to }) => (
      <>
        Return the device anytime between{" "}
        <b>
          {from}-{to}
        </b>{" "}
        to {place}.
      </>
    ),
    sv_SE: ({ place, to, from }) => (
      <>
        Återlämna enheten närsomhelst mellan{" "}
        <b>
          {from}-{to}
        </b>{" "}
        till {place}.
      </>
    ),
  }),
};

const HowItWorks = {
  Header: localize({
    en_GB: <>How it works</>,
    sv_SE: <>Så fungerar det</>,
  }),
  Text1: localize({
    en_GB: (
      <>
        The wristband will measure your blood pressure at regular intervals
        throughout the day and night. It captures your blood pressure patterns
        under various conditions, including during sleep, physical activity, and
        daily routines, offering a comprehensive view of your blood pressure
        profile. Your results will appear on this page about a day after
        tracking.
      </>
    ),
    sv_SE: (
      <>
        Armbandet mäter ditt blodtryck med jämna mellanrum under hela dagen och
        natten. Det registrerar dina blodtrycksmönster under olika förhållanden,
        t.ex. under sömn, fysisk aktivitet och dagliga rutiner, vilket ger en
        heltäckande bild av din blodtrycksprofil. Dina resultat kommer att visas
        på den här sidan ungefär en dag efter mätningen.
      </>
    ),
  }),

  Text2: localize({
    en_GB: (
      <>
        Once you've completed the assignment, your doctor will review your
        tracking and reach out to you with an assessment and next steps.
      </>
    ),
    sv_SE: (
      <>
        När du har slutfört uppgiften kommer din läkare att granska dina
        mätningar och kontakta dig med en bedömning och nästa steg.
      </>
    ),
  }),
  Text3: localize({
    en_GB: (
      <>
        Keep in mind that several factors can cause natural increases in blood
        pressure throughout the day and night; physical activity, stress,
        caffeine, alcohol and certain medications.
      </>
    ),
    sv_SE: (
      <>
        Tänk på att flera faktorer kan orsaka naturliga ökningar av blodtrycket
        under dagen och natten: fysisk aktivitet, stress, koffein, alkohol och
        vissa mediciner.
      </>
    ),
  }),
};

export const Trans = { HowItWorks, HowToReturn, ReturnLocation };
