import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function NoConsentState() {
  return (
    <div className={styles.message}>
      <Trans.Message.NoConsentState />
    </div>
  );
}
