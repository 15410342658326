import { Typography } from "@cur8/maneki";
import { ReactComponent as Clock } from "assets/icons/16x16/16x16_clock.svg";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Trans as QuestionnaireIntroTrans } from "render/views/OnboardingView/components/OnboardingFlowView/components/Questions/steps/lifestyle/intro/trans";
import { CheckInBadge } from "../../../__shared/CheckInBadge";
import { CheckInStepExplanationLayout } from "../../../__shared/CheckInStepExplanationLayout";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface ConsentIntroProps {
  onDone: () => void;
}

export function QuestionnaireIntro({ onDone }: ConsentIntroProps) {
  return (
    <CheckInStepExplanationLayout
      actionsSticky
      SubHeader={<CheckInBadge />}
      Content={
        <div className={styles.text}>
          <Typography variant="display-s">
            <Trans.Header />
          </Typography>
          <Typography variant="body-m">
            <QuestionnaireIntroTrans.ContentOne />
          </Typography>
          <div className={styles.time}>
            <Clock />
            <Typography variant="body-s">
              <QuestionnaireIntroTrans.Time />
            </Typography>
          </div>
        </div>
      }
      Actions={
        <ActionButton onClick={onDone}>
          <QuestionnaireIntroTrans.Button />
        </ActionButton>
      }
    />
  );
}
