import { DateTime } from "luxon";
import { ReactNode, useMemo } from "react";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface MiniCalendarProps {
  date: DateTime;
  renderDay: (date: DateTime) => ReactNode;
}

interface Day {
  date: DateTime | undefined;
}

export function MiniCalendar({ date, renderDay }: MiniCalendarProps) {
  const dateKey = date.toUTC().toISO();

  const days = useMemo(() => {
    const result: Day[] = [];
    const start = date.startOf("month");
    const end = date.endOf("month");

    let pointer = start.startOf("week");

    while (pointer <= end) {
      if (pointer < start) {
        result.push({ date: undefined });
      } else {
        result.push({
          date: pointer,
        });
      }

      pointer = pointer.plus({ day: 1 });
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateKey]);

  return (
    <div>
      <ol className={styles.header}>
        <li>
          <Trans.WeekdayFirstLetter.Monday />
        </li>
        <li>
          <Trans.WeekdayFirstLetter.Tuesday />
        </li>
        <li>
          <Trans.WeekdayFirstLetter.Wednesday />
        </li>
        <li>
          <Trans.WeekdayFirstLetter.Thursday />
        </li>
        <li>
          <Trans.WeekdayFirstLetter.Friday />
        </li>
        <li>
          <Trans.WeekdayFirstLetter.Saturday />
        </li>
        <li>
          <Trans.WeekdayFirstLetter.Sunday />
        </li>
      </ol>
      <ol className={styles.days}>
        {days.map(({ date }, index) => {
          if (date == null) {
            return <li key={index}></li>;
          }
          return (
            <li className={styles.dayListItem} key={index}>
              {renderDay(date)}
            </li>
          );
        })}
      </ol>
    </div>
  );
}
