import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useBrachialPressureMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";

export function useNumOfScans(side: "left" | "right") {
  const { data: patient } = usePatientQuery();

  const { data } = useBrachialPressureMetric();

  if (!data || !patient) {
    return undefined;
  }

  const numOfScanLeft = data.left.length;
  const numOfScanRight = data.right.length;

  return side === "left" ? numOfScanLeft : numOfScanRight;
}
