import styles from "./styles.module.sass";

type Highlight = "none" | "danger" | "warning" | "normal";

interface MarkerProps {
  highlight: Highlight;
  variant?: "primary" | "outlined" | "primary-outlined";
}

export function Marker({ variant = "primary", highlight }: MarkerProps) {
  return (
    <div
      className={styles.Marker}
      data-variant={variant}
      data-highlight={highlight}
    >
      <div className={styles.inner} />
    </div>
  );
}
