import { Answers } from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import {
  RelevantConditions,
  RelevantConditionsAutoimmuneDetails,
  RelevantConditionsCancerDetails,
  RelevantConditionsEnum,
  RelevantConditionsSomethingElseDetails,
} from "lib/questionnaire/question-flows/onboarding/questions/relevantConditions";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { MultiChoiceInput } from "render/ui/presentation/Questionnaire/components/MultiChoiceInput";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function RelevantConditionsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(RelevantConditions);
  const label = TransQuestions.RelevantConditions.Question();
  const { englishLabel } = TransQuestions.RelevantConditions.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={RelevantConditions}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(RelevantConditions);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.RelevantConditionsExplanation />,
      }}
    >
      <MultiChoiceInput
        question={RelevantConditions}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(RelevantConditions, {
            answer,
            questionAnswer: {
              questionId: "relevantConditions",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "textarray",
                value: RelevantConditions.getValue(answer),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

function AutoimmuneDetailsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(RelevantConditionsAutoimmuneDetails);
  const label = TransQuestions.RelevantConditions.AutoimmuneDetails();
  const { englishLabel } = TransQuestions.RelevantConditions.AutoimmuneDetails;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={RelevantConditionsAutoimmuneDetails}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(RelevantConditionsAutoimmuneDetails);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.RelevantConditionsExplanation />,
      }}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(RelevantConditionsAutoimmuneDetails, {
            answer,
            questionAnswer: {
              questionId: "relevantConditions.autoimmuneDetails",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "text",
                value:
                  RelevantConditionsAutoimmuneDetails.getValue(answer).trim(),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

function CancerDetailsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(RelevantConditionsCancerDetails);
  const label = TransQuestions.RelevantConditions.CancerDetails();
  const { englishLabel } = TransQuestions.RelevantConditions.CancerDetails;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={RelevantConditionsCancerDetails}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(RelevantConditionsCancerDetails);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.RelevantConditionsExplanation />,
      }}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(RelevantConditionsCancerDetails, {
            answer,
            questionAnswer: {
              questionId: "relevantConditions.cancerDetails",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "text",
                value: RelevantConditionsCancerDetails.getValue(answer).trim(),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

function SomethingElseDetailsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(RelevantConditionsSomethingElseDetails);
  const label = TransQuestions.RelevantConditions.SomethingElseDetails();
  const { englishLabel } =
    TransQuestions.RelevantConditions.SomethingElseDetails;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={RelevantConditionsSomethingElseDetails}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(RelevantConditionsSomethingElseDetails);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.RelevantConditionsExplanation />,
      }}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(RelevantConditionsSomethingElseDetails, {
            answer,
            questionAnswer: {
              questionId: "relevantConditions.somethingElseDetails",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "text",
                value:
                  RelevantConditionsSomethingElseDetails.getValue(
                    answer
                  ).trim(),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(RelevantConditionsStep);

  if (
    answers
      .lookup(RelevantConditions)
      ?.includes(RelevantConditionsEnum.Autoimmune)
  ) {
    steps.push(AutoimmuneDetailsStep);
  }

  if (
    answers.lookup(RelevantConditions)?.includes(RelevantConditionsEnum.Cancer)
  ) {
    steps.push(CancerDetailsStep);
  }

  if (
    answers
      .lookup(RelevantConditions)
      ?.includes(RelevantConditionsEnum.SomethingElse)
  ) {
    steps.push(SomethingElseDetailsStep);
  }
}
