type Direction = "asc" | "desc";

export function sortBy<T>(val: (value: T) => number) {
  return function sort(dir: Direction) {
    const invert = dir === "asc" ? 1 : -1;

    return function compare(a: T, b: T) {
      return (val(a) - val(b)) * invert;
    };
  };
}
