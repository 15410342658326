import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";

interface RequestParameters {
  patientId: string;
  tokenSegment: string;
}

export function useClaimBookingTokenMutation() {
  const apiClient = useAPIClient().booking;

  return useCallback(
    ({ patientId, tokenSegment }: RequestParameters) => {
      return apiClient.claimBookingCapability({
        patientId,
        tokenSegment,
      }).result;
    },
    [apiClient]
  );
}
