import { localize } from "render/context/InternationalizationContext";

const Group = {
  HeartAndCirculation: localize({
    en_GB: <>Heart & circulation</>,
    sv_SE: <>Hjärta och cirkulation</>,
  }),
  BloodValues: localize({
    en_GB: <>Blood values</>,
    sv_SE: <>Blodvärden</>,
  }),
  Body: localize({
    en_GB: <>Body</>,
    sv_SE: <>Kropp</>,
  }),
  Skin: localize({
    en_GB: <>Skin</>,
    sv_SE: <>Hud</>,
  }),
};

export const Trans = { Group };
