import { APITypesV1 } from "@cur8/api-client";
import { useNav } from "@pomle/react-router-paths";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import {
  SeverityLevel,
  useAppInsights,
} from "render/context/AppInsightsContext";
import { useBookingTokens } from "render/hooks/api/queries/useBookingTokens";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useClaimBookingTokenMutation } from "render/hooks/mutations/useClaimBookingTokenMutation";
import { paths } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { VideoBackground } from "render/views/_shared/VideoBackground";
import { SharedTrans } from "render/views/trans";

export function ClaimBookingToken({ segment }: { segment: string }) {
  const nav = {
    home: useNav(paths.root),
    booking: useNav(paths.booking),
  };

  const { appInsights } = useAppInsights();
  const { enqueueSnackbar } = useSnackbar();
  const patientQuery = usePatientQuery();
  const claimBookingTokenMutation = useClaimBookingTokenMutation();
  const bookingTokens = useBookingTokens();
  const patient = useMemo(() => patientQuery.data, [patientQuery.data]);
  const [patientIsEligibleToClaim, setPatientIsEligibleToClaim] =
    useState(false);

  const hasMatchingOpenBookingToken = useMemo(() => {
    if (!bookingTokens.data) {
      return null;
    }

    return bookingTokens.data.some(
      (bookingToken) =>
        bookingToken.availableForBooking &&
        bookingToken.bookingTokenId?.indexOf(segment) === 0
    );
  }, [bookingTokens.data, segment]);

  // TODO: in the future, if there are no open slots once the patient navigates to /claim
  // we should show them some sort of "that's too bad" screen here and redirect them
  // to the homepage
  //
  // Arbitrary deadline to re-check this: 2023-07-31
  useEffect(() => {
    // make sure this useEffect is executed until the very end only once
    if (patientIsEligibleToClaim) {
      return;
    }

    if (!patient) {
      // wait until loads
      return;
    }

    if (patient.flags.includes(APITypesV1.PatientFlag.CanBook)) {
      // patient already claimed his ability to book - redirect them to the homepage
      nav.home.go({});
    }

    if (!bookingTokens.data) {
      // wait until loads
      return;
    }

    if (bookingTokens.data.length === 0) {
      // patient has no booking tokens - redirect them to the homepage
      nav.home.go({});
    }

    if (hasMatchingOpenBookingToken === false) {
      // patient has no matching open booking tokens - redirect them to the homepage
      nav.home.go({});
    }
    setPatientIsEligibleToClaim(true);
  }, [
    claimBookingTokenMutation,
    hasMatchingOpenBookingToken,
    nav.home,
    patient,
    segment,
    bookingTokens.data,
    patientQuery,
    enqueueSnackbar,
    patientIsEligibleToClaim,
  ]);

  useEffect(() => {
    async function claimBookingToken() {
      if (patient) {
        claimBookingTokenMutation({
          tokenSegment: segment,
          patientId: patient.patientId,
        })
          .then(async () => {
            await Promise.all([
              // refetch patient flags
              patientQuery.refetch(),
              // Pre-fetch the dependencies of the /booking screen (SuggestedSlotView),
              // so that it loads instantly
              bookingTokens.refetch(),
            ]);

            nav.booking.go({});
          })
          .catch((exception) => {
            if (exception instanceof Error) {
              appInsights.trackException({
                error: exception,
                exception: exception,
                severityLevel: SeverityLevel.Error,
              });
            }
            enqueueSnackbar(<SharedTrans.Error.Unexpected />, {
              variant: "error",
            });
            nav.home.go({});
          });
      }
    }

    if (!patientIsEligibleToClaim) {
      return;
    }

    claimBookingToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientIsEligibleToClaim]);

  return (
    <FullScreenPageLayout disableBackground>
      <BurgerLayout>
        <></>
        <></>
        <></>
      </BurgerLayout>
      <VideoBackground />
    </FullScreenPageLayout>
  );
}
