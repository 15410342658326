import { localize } from "render/context/InternationalizationContext";

const InvalidDocument = {
  Header: localize({
    en_GB: "Oh no...",
    sv_SE: "Åh nej...",
  }),
  Description: localize({
    en_GB: "The document could not be shown. Please refresh the page.",
    sv_SE: "Dokumentet kunde inte visas. Vänligen uppdatera sidan.",
  }),
};

const DocumentLoadFail = {
  Header: localize({
    en_GB: "Oh no...",
    sv_SE: "Åh nej...",
  }),
  Description: localize({
    en_GB: "The document could not be shown. Please refresh the page.",
    sv_SE: "Dokumentet kunde inte visas. Vänligen uppdatera sidan.",
  }),
};

const CTA = {
  Agree: localize({
    en_GB: "Agree and continue",
    sv_SE: "Godkänn och fortsätt",
  }),
  Reject: localize({
    en_GB: "Skip",
    sv_SE: "Hoppa över",
  }),
};

export const Trans = { InvalidDocument, DocumentLoadFail, CTA };
