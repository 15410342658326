import { Summary } from "render/ui/presentation/doctor/Summary";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface DoctorsNoteProps {
  doctorURI: string | null | undefined;
  note: string;
}

export function DoctorsNote({ doctorURI, note }: DoctorsNoteProps) {
  return (
    <div className={styles.DoctorsNote}>
      <div className={styles.header}>{<Trans.Title />}</div>
      <Summary text={note} doctorURI={doctorURI} />
    </div>
  );
}
