import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Visit, fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";

export function isCanceled(visit: Visit) {
  return visit.status === APITypesV1.VisitStatus.Cancelled;
}

export function isCheckedIn(visit: Visit) {
  return visit.checkinStartTimestamp != null;
}

function queryKey(patientId: string) {
  return ["todays-visits", patientId] as const;
}

function queryFn(apiClient: APIClient, patientId: string) {
  const today = DateTime.now();

  return apiClient.visit
    .getPatientVisits(
      { patientId },
      {
        pageSize: 20,
        order: APITypesV2.SortOrder.Asc,
        startDateTimeOffsetRange: {
          start: today.startOf("day").toISO(),
          end: today.endOf("day").toISO(),
        },
      }
    )
    .result.then((result) =>
      result.items.map(fromAPI.toVisit).filter((visit) => !isCanceled(visit))
    );
}

export function useTodayVisitsQuery() {
  const { patientId } = useSession();
  const api = useAPIClient();

  const fn = useCallback(() => queryFn(api, patientId), [api, patientId]);

  return useQuery({
    queryKey: queryKey(patientId),
    queryFn: fn,
    refetchInterval: (query) => {
      return query.state.data?.find(isCheckedIn) ? false : 3000;
    },
  });
}
