import { localize } from "render/context/InternationalizationContext";

const Title = localize<{ name: string | undefined | null }>({
  en_GB: ({ name }) => {
    if (name != null) {
      return <>Redeemed by {name}</>;
    }
    return <>Redeemed</>;
  },
  sv_SE: ({ name }) => {
    if (name != null) {
      return <>Nyttjad av {name}</>;
    }
    return <>Använd</>;
  },
});

export const Trans = { Title };
