import { Skeleton } from "render/ui/presentation/Skeleton";
import styles from "./styles.module.sass";

export function Loading() {
  return (
    <div className={styles.cardSkeleton}>
      <Skeleton />
    </div>
  );
}
