import { Patient } from "@cur8/rich-entity";
import { PhysicalIdType } from "@cur8/uri";
import { isPhysicalIdURI } from "lib/uri/guard";
import { DayDate } from "render/ui/format/DayDate";
import { Interleave } from "render/ui/format/Interleave";
import { PhysicalIdentifier } from "render/ui/format/PhysicalIdentifier";

const PREFERRED_TYPES = new Set([
  PhysicalIdType.BankId,
  PhysicalIdType.USSocialSecurityNo,
]);

interface PatientPhysicalIdentifierProps {
  patient: Patient;
}

export function PatientPhysicalIdentifier({
  patient,
}: PatientPhysicalIdentifierProps) {
  const { physicalIds } = patient;

  const uris = physicalIds.filter(isPhysicalIdURI).filter((uri) => {
    return PREFERRED_TYPES.has(uri.idType);
  });

  if (uris.length > 0) {
    return (
      <Interleave sep={<>&ensp;&bull;&ensp;</>}>
        {uris.map((uri) => {
          return <PhysicalIdentifier key={uri.toString()} uri={uri} />;
        })}
      </Interleave>
    );
  }

  if (patient.dateOfBirth) {
    return <DayDate date={patient.dateOfBirth} />;
  }

  return <>&mdash;</>;
}
