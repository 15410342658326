import { localize } from "render/context/InternationalizationContext";

const Header = localize({
  en_GB: "We need to know a little bit more about you...",
  sv_SE: "Vi behöver veta lite mer om dig",
});

export const Trans = {
  Header,
};
