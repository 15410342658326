import { classNames } from "@pomle/classnames";
import { DateTime, Interval } from "luxon";
import { useCallback, useMemo } from "react";
import { AnimationTime } from "../../utils/animationConstants";
import { MeasuredBloodPressureDataLookUpValue } from "../../utils/createMeasurementsLookUpTable";
import { AnimationDelayer } from "../AnimationDelayer";
import { BackgroundStripes } from "./components/BackroundStripes";
import { Candle } from "./components/Candle";
import { concat } from "./lib/concat";
import styles from "./styles.module.sass";

export interface ProgressTableProps {
  measurements: Map<string, MeasuredBloodPressureDataLookUpValue>;
  onSlotClick?: (day: DateTime) => void;
  selectedInterval: Interval;
  assignmentInterval: Interval;
}

export function ProgressTable({
  measurements,
  onSlotClick,
  selectedInterval,
  assignmentInterval,
}: ProgressTableProps) {
  const days = useMemo(
    () => assignmentInterval.splitBy({ day: 1 }).map(({ start }) => start),
    [assignmentInterval]
  );
  const offset = 20;
  const allValues = useMemo(() => {
    const result: number[] = [];

    measurements.forEach((value) => {
      result.push(...concat(value.data));
    });

    return result;
  }, [measurements]);

  const min = useMemo(() => Math.min(...allValues), [allValues]);
  const max = useMemo(() => Math.max(...allValues), [allValues]);
  const top = useMemo(() => {
    return Math.floor(max / offset) * offset + offset;
  }, [max]);
  const bottom = useMemo(() => {
    return Math.floor(min / offset) * offset - offset;
  }, [min]);
  const diff = top - bottom;

  const normalize = useCallback(
    (v: number) => ((v - bottom) * 100) / diff,
    [bottom, diff]
  );
  const invert = useCallback((n: number) => 100 - n, []);

  const toY = useCallback(
    (v: number) => invert(normalize(v)),
    [invert, normalize]
  );

  const breakPointCount = useMemo(
    () => Math.floor((top - bottom) / offset) + 1,
    [top, bottom]
  );

  const labels = useMemo(
    () =>
      Array.from({ length: breakPointCount })
        .map((_, index) => bottom + index * offset)
        .filter((_, index) => index % 2 !== 0),
    [breakPointCount, offset, bottom]
  );

  const findMeasurementAt = useCallback(
    (target: DateTime) => measurements.get(target.toISODate()),
    [measurements]
  );

  return (
    <div className={styles.ProgressTable}>
      <div className={styles.item}>
        <BackgroundStripes stripeCount={breakPointCount} height="203" />
      </div>
      <div className={classNames(styles.item, styles.values)}>
        <div></div>
        <div className={styles.data}>
          {days.map((day) => {
            const measurement = findMeasurementAt(day);
            const isWithinSelectedRange = selectedInterval.contains(day);
            const initialAnimationDelay = measurement
              ? measurement.orderPosition * AnimationTime.DelayBetweenSteps
              : 0;

            return (
              <AnimationDelayer
                key={day.toUnixInteger()}
                defaultDelay={0}
                initialDelay={initialAnimationDelay}
                isActive={isWithinSelectedRange}
              >
                {({ delay, isActive }) => {
                  if (!isActive) {
                    return <div style={{ display: "none" }} />;
                  }

                  if (!measurement) {
                    return <div style={{ visibility: "hidden" }} />;
                  }

                  return (
                    <Candle
                      onClick={() => onSlotClick?.(day)}
                      data={measurement.data}
                      toY={toY}
                      isHidden={!isActive}
                      delay={delay}
                    />
                  );
                }}
              </AnimationDelayer>
            );
          })}
        </div>

        <div className={styles.breakpointColumn}>
          {labels.map((label) => (
            <div
              key={label}
              className={styles.breakpointText}
              style={{
                top: `${toY(label)}%`,
              }}
            >
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
