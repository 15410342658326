import { AggregatedBloodPressureData } from "@cur8/rich-entity";

export function concat(data: AggregatedBloodPressureData) {
  return [
    ...Object.values(data.avgDay ?? {}),
    ...Object.values(data.avgNight ?? {}),
    ...Object.values(data.maxDay ?? {}),
    ...Object.values(data.maxNight ?? {}),
    ...Object.values(data.minDay ?? {}),
    ...Object.values(data.minNight ?? {}),
  ];
}
