import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: <>Before we begin, we need a little more information.</>,
  sv_SE: <>Innan vi börjar behöver vi lite mer information.</>,
});

const CompleteProfile = localize({
  en_GB: <>Complete Your Profile</>,
  sv_SE: <>Slutför din profil</>,
});

export const Trans = { Title, CompleteProfile };
