import { DateTime } from "luxon";

export function FullMonthDay({
  date,
  timeZoneId,
}: {
  date: DateTime;
  timeZoneId?: string;
}) {
  return <>{date.setZone(timeZoneId).toFormat("EEEE, dd MMMM")}</>;
}
