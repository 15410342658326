import { localize } from "render/context/InternationalizationContext";

const Action = localize({
  en_GB: "Go Home",
  sv_SE: "Gå hem",
});

export const Trans = {
  Action,
};
