import { UnitSystem } from "@cur8/rich-entity";
import { ReactNode } from "react";
import { Trans } from "./trans";

const record: Record<UnitSystem, ReactNode> = {
  [UnitSystem.Imperial]: <Trans.Imperial />,
  [UnitSystem.Metric]: <Trans.Metric />,
};

export function ChosenUnitSystem({ unit }: { unit?: UnitSystem }) {
  if (!unit) {
    return null;
  }

  return record[unit] ?? "";
}
