import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Neutrophils</>,
  sv_SE: <>Neutrofiler</>,
});

const Risk = {
  Normal: localize({
    en_GB: <>Normal</>,
    sv_SE: <>Normal</>,
  }),
  HighRisk: {
    High: localize({
      en_GB: <>High</>,
      sv_SE: <>Hög</>,
    }),
    VeryLow: localize({
      en_GB: <>Very low</>,
      sv_SE: <>Väldigt hög</>,
    }),
  },
};

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        Neutrophils are the most common type of white blood cell, their main job
        is to fight infections, particularly those caused by bacteria and fungi.
        By measuring neutrophil levels, we gain insight into your body's
        immediate response to infections and inflammatory processes. Neutrophils
        react quickly to infection, so their levels can change rapidly.
      </>
    ),
    sv_SE: (
      <>
        Neutrofiler är den vanligaste typen av vita blodkroppar. Deras
        huvudsakliga uppgift är att bekämpa infektioner, särskilt de som orsakas
        av bakterier och svampar. Genom att mäta neutrofilnivåer får vi insikt i
        din kropps omedelbara reaktion på infektioner och inflammatoriska
        processer. Vi kan se om det finns en pågående infektion eller ej.
        Neutrofiler reagerar snabbt på infektion, så deras nivåer kan ändras
        snabbt.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        An increased neutrophil count can be a sign of a bacterial infection, or
        an injury or inflammation. They can also increase with mental stress,
        and physical stress like exercise or intense physical work. They can
        also increase in some chronic inflammatory conditions like rheumatoid
        arthritis.
      </>
    ),
    sv_SE: (
      <>
        Ett ökat antal neutrofiler kan vara ett tecken på en bakteriell
        infektion, skada eller inflammation. De kan också öka vid stress och
        fysisk stress som träning eller intensivt fysiskt arbete. Man kan även
        se en ökning vid vissa kroniska inflammatoriska tillstånd som reumatoid
        artrit.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low neutrophil count can happen temporarily while your body is
        recovering from infection. Where it lasts for a long time, then it may
        be a sign of chronic infection, or an autoimmune or bone marrow problem.
        Some people naturally have low resting neutrophil levels which climb
        back up to high levels when needed to fight infection. Low neutrophils
        can make you more susceptible to infections, as your body's primary
        defence against bacteria and fungi is compromised. If we find you have a
        low level of neutrophils, then our doctors will advise you whether it
        needs further investigation.
      </>
    ),
    sv_SE: (
      <>
        Ett lågt antal neutrofiler kan inträffa tillfälligt medan din kropp
        återhämtar sig från infektion. Vid varaktigt lågt resultat kan det vara
        ett tecken på kronisk infektion, autoimmun sjukdom eller
        benmärgsproblem. Låga neutrofiler kan göra dig mer mottaglig för
        infektioner från bakterier och svamp. Om vi ​​upptäcker att du har en
        låg nivå av neutrofiler kommer våra läkare att ge dig råd om det behöver
        undersökas ytterligare.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Your neutrophils will naturally increase and decrease over time.
        Providing your result is in the normal range some fluctuation is
        perfectly normal and healthy. If your neutrophils are climbing upward
        over time, and are outside the normal range, then this suggests that
        there may be some long-term inflammation, stress or infection.
        Neutrophils that are decreasing over time are a sign that there is a
        problem either with the production of neutrophils, or that your body is
        using them quicker than they can be produced. A persistently abnormal
        neutrophil count should be discussed with your doctor.
      </>
    ),
    sv_SE: (
      <>
        Dina neutrofiler kommer naturligt att öka och minska med tiden.
        Förutsatt att ditt resultat ligger inom det normala intervallet är vissa
        fluktuationer helt normala och hälsosamma. Om dina neutrofiler klättrar
        uppåt över tiden och ligger utanför det normala intervallet, kan det
        tyda på att det kan finnas någon långvarig inflammation, stress eller
        infektion. Neutrofiler som minskar med tiden är ett tecken på att det
        finns ett problem antingen med produktionen av neutrofiler eller att din
        kropp använder dem snabbare än de kan produceras. Ett ihållande onormalt
        antal neutrofiler bör diskuteras med din läkare.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        Because your immune system is consistently reacting and adapting to
        threats such as bacterial infection, there is no single number to aim
        for when thinking about your neutrophil count. A normal result sits
        between approximately 1.6 and 5.9 x109/L. This will vary depending upon
        the technique used to measure them. If you have an abnormal neutrophil
        count that is not improving, then you should discuss it with your
        doctor.
      </>
    ),
    sv_SE: (
      <>
        Eftersom ditt immunförsvar konsekvent reagerar och anpassar sig till hot
        som bakterieinfektion, finns det inget enskilt nummer att sikta på när
        du tänker på ditt antal neutrofiler. Ett normalt resultat ligger mellan
        1,6 och 5,9 x10^9/L. Detta kommer att variera beroende på vilken teknik
        som används för att mäta dem.
      </>
    ),
  }),
};

export const Trans = { MetricName, Risk, FAQ };
