import { Patient } from "@cur8/rich-entity";
import { calcAge } from "lib/age";
import { Metric } from "lib/api/metrics";
import { bySystolic } from "lib/metric/sort";
import { patientScore2 } from "lib/scores/score2/score2";
import { DateTime } from "luxon";

export type Score2 = {
  score: number;
};

export type Score2Factors = {
  patient: Patient | undefined;
  date: DateTime;
  smoking: Metric<"lifestyle.smoking-habit"> | undefined;
  brachial: {
    left: Metric<"cardio.brachial_pressure.left"> | undefined;
    right: Metric<"cardio.brachial_pressure.right"> | undefined;
  };
  nonHDL: Metric<"bloodwork.non_hdl"> | undefined;
};

function notNullOrUndefined<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value != null;
}

export function calcScore2({
  patient,
  date,
  nonHDL,
  brachial: { left, right },
  smoking,
}: Score2Factors): Score2 | undefined {
  if (!patient || !nonHDL || !smoking) {
    return;
  }

  const highestBrachialPressure = [left, right]
    .filter(notNullOrUndefined)
    .sort(bySystolic("desc"))
    .at(0);

  if (highestBrachialPressure == null) {
    return;
  }

  const dateOfBirth = patient.dateOfBirth;
  const age = dateOfBirth ? calcAge(dateOfBirth, date) : NaN;

  const score2 = patientScore2({
    age,
    sex: patient.sex,
    metric: {
      nonHDL: nonHDL,
      smoking: smoking,
      brachialPressure: highestBrachialPressure,
    },
  });

  return {
    score: score2 ?? NaN,
  };
}
