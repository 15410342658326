import { Sticky } from "@pomle/react-viewstack";
import React from "react";
import styles from "./styles.module.sass";

interface CarouselProps {
  index: number;
  children: React.ReactNode;
}

export function Carousel({ index, children }: CarouselProps) {
  const items = React.Children.toArray(children);
  return (
    <div className={styles.Carousel}>
      {items.map((content, itemIndex) => {
        return (
          <div
            key={itemIndex}
            className={styles.item}
            data-prev={index > itemIndex}
            data-next={index < itemIndex}
            aria-hidden={index !== itemIndex}
          >
            <Sticky delay={650}>
              {index === itemIndex ? content : undefined}
            </Sticky>
          </div>
        );
      })}
    </div>
  );
}
