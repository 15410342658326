import { useEffect, useRef } from "react";
import styles from "./styles.module.sass";

interface AnimatedLineProps {
  line: string;
  duration: string;
  delay: string;
  inTranslateY: string;
  outTranslateY: string;
  playState: "paused" | "playing";
  onAnimationEnded?: (ev: AnimationEvent) => void;
}

export function AnimatedLine({
  line,
  duration,
  delay,
  inTranslateY,
  outTranslateY,
  playState,
  onAnimationEnded,
}: AnimatedLineProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const element = ref.current;

    if (onAnimationEnded) {
      element.addEventListener("animationend", onAnimationEnded);
    }

    return () => {
      if (element && onAnimationEnded) {
        element.removeEventListener("animationend", onAnimationEnded);
      }
    };
  }, [ref, onAnimationEnded]);

  return (
    <div
      ref={ref}
      className={styles.animatedLine}
      style={
        {
          "--duration": duration,
          "--delay": delay,
          "--in-translate-y": inTranslateY,
          "--out-translate-y": outTranslateY,
          "--play-state": playState,
        } as React.CSSProperties
      }
    >
      {line}
    </div>
  );
}
