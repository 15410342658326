import { Typography } from "@cur8/maneki";
import { useMemo } from "react";
import styles from "./styles.module.sass";

let counter = 0;

type Entry<T> = {
  value: T;
  content: React.ReactNode;
};

interface RadioButtonsProps<T> {
  disabled?: boolean;
  required?: boolean;
  value?: T;
  entries: Entry<T>[];
  label?: string;
  onChange: (value: T) => void;
}

export function RadioButtons<T>({
  disabled = false,
  required = false,
  value,
  entries,
  label,
  onChange,
}: RadioButtonsProps<T>) {
  const name = useMemo(() => {
    return `neko-radio-unique-group-${++counter}`;
  }, []);
  return (
    <div className={styles.RadioButtons}>
      {label && <div className={styles.label}>{label}</div>}
      {entries.map((entry, index) => {
        const selected = entry.value === value;
        return (
          <button
            key={index}
            disabled={disabled}
            type="button"
            value={selected ? "on" : "off"}
            onClick={() => onChange(entry.value)}
            className={styles.button}
          >
            <div className={styles.radio}>
              <div className={styles.frame}>
                <div className={styles.dot}></div>
              </div>
            </div>
            <Typography variant="body-m" color="subtle">
              {entry.content}
            </Typography>

            <input
              tabIndex={-1}
              type="radio"
              name={name}
              checked={selected}
              onChange={() => void 0}
              required={required}
            />
          </button>
        );
      })}
    </div>
  );
}
