import { APITypesV2 } from "@cur8/api-client";

export function checkIfSigned(
  signature: APITypesV2.ConsentSummary | undefined
): boolean {
  if (signature == null) {
    return false;
  }
  return !signature.expired && !signature.revoked && !signature.optedOut;
}
