import { useLatestConsentsQuery } from "render/hooks/api/queries/usePatientConsentsQuery";
import { ConsentDocument } from "../ConsentDocument";

type ConsentPageProps = {
  consentId: string;
  onClose: () => void;
};

export function ConsentPage({ consentId, onClose }: ConsentPageProps) {
  const { data: consents } = useLatestConsentsQuery();

  const consent = consents?.find(
    (c) => c?.relevantSignature?.consentId === consentId
  );

  if (!consent) {
    return null;
  }

  return <ConsentDocument onClose={onClose} consent={consent} />;
}
