import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createContext, PropsWithChildren, useContext } from "react";
export { SeverityLevel } from "@microsoft/applicationinsights-web";

export const Context = createContext<ApplicationInsights | null>(null);

export function AppInsightsContext({
  children,
  appInsights,
}: PropsWithChildren<{ appInsights: ApplicationInsights }>) {
  return <Context.Provider value={appInsights}>{children}</Context.Provider>;
}

export function useAppInsights() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAppInsights without AppInsightsContext");
  }
  return context;
}
