/* eslint no-extend-native: 0 */

/**
 * I tried adding core-js polyfill
 * but safari decided to ignore it
 * after a day of debugging on browserstack
 * this is the best solution I have come up
 * until we continue using create-react-app
 */

if (Array.prototype.at == null) {
  Array.prototype.at = function (index) {
    return this[index];
  };
}

export {};
