import {
  AktiiaMonitorBloodPressureAssignmentData,
  Assignment,
  isAktiiaMonitorBloodPressureAssignment,
} from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as ArrowIcon } from "assets/icons/24x24/24x24_arrow_left.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/24x24/24x24_chat.svg";
import { DateTime, Interval } from "luxon";
import { useCallback, useMemo, useState } from "react";
import { useAssignmentQuery } from "render/hooks/api/queries/useAssignmentsQuery";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Separator } from "render/ui/presentation/Separator/Separator";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { IconButton } from "render/ui/trigger/IconButton";
import { SharedTrans } from "render/views/trans";
import { AssignmentProgress } from "./components/AssignmentProgress";
import { BloodValuesForm } from "./components/BloodValuesForm";
import { Modal } from "./components/Modal";
import { NoProgressTable } from "./components/NoProgressTable";
import { ProgressDays } from "./components/ProgressDays";
import { ProgressTable } from "./components/ProgressTable";
import { DoctorsNote } from "./components/sections/DoctorsNote";
import { Header } from "./components/sections/Header";
import { Instructions } from "./components/sections/Instructions";
import styles from "./styles.module.sass";
import { Stage } from "./utils";
import { createMeasurementsLookUpTable } from "./utils/createMeasurementsLookUpTable";

export function AssignmentView({ assignmentId }: { assignmentId: string }) {
  const nav = {
    home: useNav(paths.root),
  };
  const assignmentsQuery = useAssignmentQuery(assignmentId);
  const contactUsPopup = useContactUsPopup();

  return (
    <FullScreenPageLayout>
      <div className={styles.AssignmentView}>
        <div className={styles.sticky}>
          <LogoHeader
            leftElement={
              <IconButton onClick={nav.home.on({})} icon={<ArrowIcon />} />
            }
            rightElement={
              <IconButton
                ariaLabel={SharedTrans.ContactUs()}
                icon={<QuestionIcon display="block" />}
                onClick={contactUsPopup.emit}
              />
            }
          />
        </div>
        <div className={styles.items}>
          <div
            className={classNames(styles.item, {
              [styles.active]: !assignmentsQuery.data,
            })}
          >
            <AssignmentSkeleton />
          </div>
          <div
            className={classNames(styles.item, {
              [styles.active]: !!assignmentsQuery.data,
            })}
          >
            {assignmentsQuery.data &&
              isAktiiaMonitorBloodPressureAssignment(assignmentsQuery.data) && (
                <PatientAssignment
                  assignment={assignmentsQuery.data}
                  refetch={assignmentsQuery.refetch}
                />
              )}
          </div>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}

function AssignmentSkeleton() {
  return (
    <div className={styles.body}>
      <div
        style={{
          height: "40px",
          maxWidth: "160px",
        }}
      >
        <Skeleton />
      </div>
      <div
        style={{
          marginTop: "12px",
          height: "12px",
          maxWidth: "300px",
        }}
      >
        <Skeleton />
      </div>
      <div
        style={{
          marginTop: "40px",
          height: "300px",
          maxWidth: "100%",
        }}
      >
        <Skeleton />
      </div>
      <div
        style={{
          marginTop: "24px",
          height: "100px",
          maxWidth: "100%",
        }}
      >
        <Skeleton />
      </div>
      <div style={{ marginTop: "24px", height: "100px", maxWidth: "100%" }}>
        <Skeleton />
      </div>
    </div>
  );
}

function PatientAssignment({
  assignment,
  refetch,
}: {
  assignment: Assignment<AktiiaMonitorBloodPressureAssignmentData>;
  refetch: () => void;
}) {
  const { data, startAt } = assignment;
  const currentStage = useMemo(() => {
    if (data.measurements == null || data.measurements.length === 0) {
      return Stage.Starting;
    }

    if (data.measurements.length < data.minCompletedMeasurements!) {
      return Stage.InProgress;
    }

    return Stage.Completed;
  }, [data]);
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<DateTime>(
    DateTime.now().minus({ day: 1 })
  );
  const openModal = useCallback((day: DateTime) => {
    setSelectedDay(day);
    setIsModalActive(true);
  }, []);
  const closeModal = useCallback(() => setIsModalActive(false), []);
  const clampRanges = {
    lower: useMemo(() => startAt.startOf("day"), [startAt]),
    upper: useMemo(() => {
      const measurements = data.measurements ?? [];
      if (measurements.length === data.minCompletedMeasurements) {
        const dates = measurements
          .filter(({ measuredAt }) => !!measuredAt)
          .map(({ measuredAt }) => measuredAt!);
        const max = DateTime.max(...dates);
        return max.endOf("day");
      }
      return DateTime.max(
        startAt.plus({ day: 6 }).endOf("day"),
        DateTime.now().minus({ day: 1 }).endOf("day")
      );
    }, [data.measurements, data.minCompletedMeasurements, startAt]),
  };

  const [rangeStart, setRangeStart] = useState(() =>
    DateTime.max(startAt, clampRanges.upper.minus({ days: 6 }))
  );
  const rangeEnd = useMemo(
    () => rangeStart.plus({ day: 6 }).endOf("day"),
    [rangeStart]
  );

  const assignmentInterval = useMemo(
    () => Interval.fromDateTimes(clampRanges.lower, clampRanges.upper),
    [clampRanges.lower, clampRanges.upper]
  );
  const selectedInterval = useMemo(
    () =>
      Interval.fromDateTimes(rangeStart.startOf("day"), rangeEnd.endOf("day")),
    [rangeStart, rangeEnd]
  );

  const daysLeft = useMemo(() => {
    const { minCompletedMeasurements, measurements } = data;

    if (minCompletedMeasurements == null) {
      return null;
    }

    return minCompletedMeasurements - (measurements?.length ?? 0);
  }, [data]);
  const measurementsTable = useMemo(
    () => createMeasurementsLookUpTable(data.measurements ?? []),
    [data.measurements]
  );

  return (
    <>
      <div className={styles.body}>
        <div>
          <Header
            assignment={assignment}
            stage={currentStage}
            daysLeft={daysLeft}
            doctorsNote={data.doctorsNote}
          />
          {currentStage === Stage.Starting && <NoProgressTable />}
          <Separator width="24px" margin="24px 0" />
          <AssignmentProgress
            stage={currentStage}
            completed={data.measurements?.length ?? 0}
            minRequired={data.minCompletedMeasurements!}
          />
          {currentStage !== Stage.Starting && (
            <ProgressTable
              assignmentInterval={assignmentInterval}
              selectedInterval={selectedInterval}
              onSlotClick={openModal}
              measurements={measurementsTable}
            />
          )}
          <ProgressDays
            assignmentInterval={assignmentInterval}
            selectedInterval={selectedInterval}
            onNav={setRangeStart}
            onSlotClick={openModal}
            measurements={measurementsTable}
          />
          <Separator width="24px" margin="0 0 24px 0" />
        </div>
        {data.doctorsNote && (
          <section>
            <DoctorsNote
              doctorURI={assignment.assignedTo?.toString()}
              note={data.doctorsNote}
            />
            <Separator width="24px" margin="24px 0 24px 0" />
          </section>
        )}

        {!assignment.data.returnedAt && (
          <section>
            <Instructions
              doctorsNote={data.doctorsNote}
              returnAt={data.returnAt}
              returnLocation={data.returnLocation}
            />
          </section>
        )}
      </div>

      <Modal isActive={isModalActive} onOutsideClick={closeModal}>
        <BloodValuesForm
          onSelect={setSelectedDay}
          interval={assignmentInterval}
          day={selectedDay}
          assignment={assignment}
          onSuccess={refetch}
          onClose={closeModal}
        />
      </Modal>
    </>
  );
}
