import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (doctorURI: string) => ["doctor-profile", doctorURI];
const queryFn = (apiClient: APIClient, doctorURI: string) => async () => {
  const [doctor, photo] = await Promise.all([
    apiClient.user.getUser(doctorURI).result,
    apiClient.user.getUserProfilePhoto({ userUri: doctorURI, size: 120 })
      .result,
  ]);

  return {
    doctor,
    photo: await photo.blob(),
  };
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useDoctorProfileQuery<T = Body>(
  { doctorURI }: { doctorURI: string },
  options: QueryOptions<Body, Key, T> = {}
) {
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryKey: queryKey(doctorURI),
    queryFn: queryFn(apiClient, doctorURI),
  });
}
