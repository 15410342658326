import { Locale } from "render/context/InternationalizationContext";

const LanguageCode = {
  En: "en",
  Sv: "sv",
} as const;

export function localeToISO639Code(locale: Locale) {
  switch (locale) {
    case "en_GB":
      return LanguageCode.En;
    case "sv_SE":
      return LanguageCode.Sv;
  }
}
