import { HeartRate } from "@cur8/health-risks-calc";
import { PathLink } from "@pomle/react-router-paths";
import { useHasAttendedVisitInSweden } from "render/hooks/api/queries/useVisitsQuery";
import { paths } from "render/routes/paths";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useHeartRateMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricHeartRate() {
  const { data, isLoading: isDataLoading } = useHeartRateMetric();
  const { data: hasAttendedVisitInSweden } = useHasAttendedVisitInSweden();

  if (isDataLoading) {
    return <MetricLoading label={<Trans.MetricName />} isClinicalStudy />;
  }

  if (!data) {
    return null;
  }

  const { cur, prev } = getMetricHistory(data);
  const riskRanges = HeartRate.rangesFor();
  const metricRating = cur && riskRanges.findRisk(cur.unit);
  const value = cur ? Math.trunc(cur.unit.bpm) : undefined;
  const prevValue = prev ? Math.trunc(prev.unit.bpm) : undefined;
  const isClinicalStudy = hasAttendedVisitInSweden === true;

  return (
    <PathLink to={paths.heartRateMetric.url({})}>
      <Metric
        value={value}
        metricRating={metricRating}
        label={<Trans.MetricName />}
        description={<Trans.MetricLabel />}
        isClinicalStudy={isClinicalStudy}
        previousValue={prevValue}
        showPreviousInsteadOfChange
      />
    </PathLink>
  );
}
