import { ReactNode } from "react";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { Separator } from "render/ui/presentation/Separator";
import styles from "./styles.module.sass";

interface CheckInStepExplanationLayoutProps {
  Header?: ReactNode;
  SubHeader?: ReactNode;
  Content: ReactNode;
  Actions?: ReactNode;
  actionsSticky?: boolean;
}

export function CheckInStepExplanationLayout({
  Actions,
  Content,
  Header,
  SubHeader,
  actionsSticky,
}: CheckInStepExplanationLayoutProps) {
  return (
    <FullScreenPageLayout disableBackground>
      <div className={styles.CheckInStepExplanationLayout}>
        <div className={styles.header}>{Header}</div>
        <div className={styles.body}>
          {SubHeader && (
            <div className={styles.subHeader}>
              {SubHeader}
              <Separator variant="small" />
            </div>
          )}

          <div className={styles.content}>
            <div className={styles.main}>{Content}</div>
          </div>
          {Actions && (
            <div className={styles.actions} data-sticky={actionsSticky}>
              {Actions}
            </div>
          )}
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
