import { Typography } from "@cur8/maneki";
import { PathLink } from "@pomle/react-router-paths";
import { ReactComponent as Chevron } from "assets/icons/16x16/16x16_chevron-right.svg";
import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface InfoFieldProps {
  label: ReactNode;
  value: ReactNode;
  href?: string;
  onClick?: () => void;
}

export function InfoField({ label, onClick, value, href }: InfoFieldProps) {
  if (href) {
    return (
      <PathLink to={href} className={styles.InfoField}>
        <div className={styles.label}>
          <Typography variant="eyebrow-m" color="subtle">
            {label}
          </Typography>
        </div>
        <div className={styles.row}>
          <div className={styles.value}>
            <Typography variant="body-m" color="default">
              {value}
            </Typography>
          </div>
          <div className={styles.chevron}>
            <Chevron />
          </div>
        </div>
      </PathLink>
    );
  }

  return (
    <div className={styles.InfoField} data-canedit={false}>
      <div className={styles.label}>
        <Typography variant="eyebrow-m" color="subtle">
          {label}
        </Typography>
      </div>
      <div className={styles.row}>
        <div className={styles.value}>
          <Typography variant="body-m" color="default">
            {value}
          </Typography>
        </div>
      </div>
    </div>
  );
}
