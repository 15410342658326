import { useCallback } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";

export enum Action {
  Click = "Click",
  View = "View",
  Submit = "Submit",
}

export type Event = {
  category: string;
  action: Action;
  label?: string;
  value?: string;
};

export function useTracking() {
  const appInsights = useAppInsights();
  const trackEvent = useCallback(
    (event: Event) => {
      appInsights.trackEvent({
        name: "patient_ui_web_analytics",
        properties: {
          ...event,
        },
      });
    },
    [appInsights]
  );
  const trackPageView = useCallback(() => {
    appInsights.trackPageView();
  }, [appInsights]);

  return {
    trackEvent,
    trackPageView,
  };
}
