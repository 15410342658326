import { PropsWithChildren } from "react";
import styles from "./styles.module.sass";

export function BlurLayer({
  isActive,
  children,
}: PropsWithChildren<{ isActive: boolean }>) {
  return (
    <div
      className={styles.BlurLayer}
      style={{
        pointerEvents: isActive ? "all" : "none",
      }}
    >
      <div
        className={styles.blur}
        style={{
          opacity: isActive ? 1 : 0,
        }}
      ></div>
      <div
        className={styles.content}
        style={{
          pointerEvents: isActive ? "all" : "none",
        }}
      >
        {children}
      </div>
    </div>
  );
}
