import { PropsWithChildren } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { ErrorBoundary as ErrorBoundaryWall } from "./components/ErrorBoundary";

export function ErrorBoundary({
  children,
  fallback,
}: PropsWithChildren<{
  fallback: (props: { message: string | undefined }) => JSX.Element;
}>) {
  const appInsights = useAppInsights();

  return (
    <ErrorBoundaryWall appInsights={appInsights} fallback={fallback}>
      {children}
    </ErrorBoundaryWall>
  );
}
