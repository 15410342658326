import { localize } from "render/context/InternationalizationContext";

export const ConsentError = localize({
  en_GB: <>An error happened while consenting</>,
  sv_SE: <>Ett fel inträffade när du försökte samtycka</>,
});

export const RevokeConsentError = localize({
  en_GB: <>An error happen while removing your consent</>,
  sv_SE: <>Ett fel inträffade när vi försökte återkalla ditt samtycke</>,
});
