import { localize } from "render/context/InternationalizationContext";

const Header = localize({
  en_GB: <>Payment Required</>,
  sv_SE: <>Betalning saknas</>,
});

const Description = localize({
  en_GB: <>You need to pay first before you can book</>,
  sv_SE: <>Du behöver betala innan du kan boka</>,
});

const CallToAction = localize({
  en_GB: <>Go to payments</>,
  sv_SE: <>Gå till betalning</>,
});

export const Trans = { Header, Description, CallToAction };
