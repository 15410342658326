import { localize } from "render/context/InternationalizationContext";

const Label = localize({
  en_GB: <>Preferred language</>,
  sv_SE: <>Välj språk</>,
});

const SvSE = localize({
  en_GB: <>Swedish</>,
  sv_SE: <>Svenska</>,
});

const EnGB = localize({
  en_GB: <>English</>,
  sv_SE: <>English</>,
});

export const Trans = {
  EnGB,
  Label,
  SvSE,
};
