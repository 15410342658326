import { ChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum SkinCancerEnum {
  Yes = "Yes",
  No = "No",
  NotSure = "Not sure",
}

export enum SkinCancerWhoEnum {
  Child = "Child",
  Parent = "Parent",
  Sibling = "Sibling",
  OtherRelative = "Other relative",
}

export const SkinCancer = new ChoiceQuestion(<Trans.SkinCancer.Question />, [
  [<Trans.SkinCancer.No />, SkinCancerEnum.No],
  [<Trans.SkinCancer.Yes />, SkinCancerEnum.Yes],
]);

export const SkinCancerMelanoma = new ChoiceQuestion(
  <Trans.SkinCancer.MelanomaQuestion />,
  [
    [<Trans.SkinCancer.No />, SkinCancerEnum.No],
    [<Trans.SkinCancer.Yes />, SkinCancerEnum.Yes],
    [<Trans.SkinCancer.NotSure />, SkinCancerEnum.NotSure],
  ]
);

export const SkinCancerMelanomaWho = new ChoiceQuestion(
  <Trans.SkinCancer.MelanomaWhoQuestion />,
  [
    [<Trans.SkinCancer.Child />, SkinCancerWhoEnum.Child],
    [<Trans.SkinCancer.Parent />, SkinCancerWhoEnum.Parent],
    [<Trans.SkinCancer.Sibling />, SkinCancerWhoEnum.Sibling],
    [<Trans.SkinCancer.OtherRelative />, SkinCancerWhoEnum.OtherRelative],
  ]
);
