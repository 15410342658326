import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: "General",
  sv_SE: "Allmänt",
});

const Button = {
  Logout: localize({
    en_GB: "Log Out",
    sv_SE: "Logga ut",
  }),
};

export const Trans = { Button, Title };
