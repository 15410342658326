import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: () => {
    return <>Code</>;
  },
  sv_SE: () => {
    return <>Kod</>;
  },
});

const Status = localize({
  en_GB: () => {
    return <>Expired</>;
  },
  sv_SE: () => {
    return <>Har utgått</>;
  },
});

export const Trans = { Title, Status };
