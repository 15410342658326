import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

const queryKey = (patientId: string, visitId: string | undefined) =>
  ["scan-booked-asset", patientId, visitId] as const;

const queryFn = (
  apiClient: APIClient,
  patientId: string,
  visitId: string | undefined
) => {
  if (visitId == null) {
    return Promise.reject(new Error("Invalid visitId"));
  }

  return apiClient.visit
    .fetchScanBookedAsset({
      patientId: patientId,
      visitId: visitId,
    })
    .result.then((result) => {
      return result.blob();
    });
};

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useScanBookedAssetQuery<T = Body>(
  visitId: string | undefined,
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    queryFn: () => queryFn(apiClient, patientId, visitId),
    queryKey: queryKey(patientId, visitId),
    staleTime: Infinity,
    ...options,
  });
}
