import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import { useEffect, useRef, useState } from "react";
import Success from "./assets/Check.json";
import Pulsating from "./assets/GridLoader.json";
import styles from "./styles.module.sass";

export enum LoaderStatus {
  Loading = "loading",
  Complete = "complete",
  Fail = "fail",
}
export interface LoaderProps {
  status: LoaderStatus;
  onDone: () => void;
}

export function Loader({ status, onDone }: LoaderProps) {
  const pulsingRef = useRef<HTMLDivElement>(null);
  const doneRef = useRef<HTMLDivElement>(null);
  const [pulsingAnimation, setPulsingAnimation] = useState<AnimationItem>();
  const [doneAnimation, setDoneAnimation] = useState<AnimationItem>();

  useEffect(() => {
    const container = pulsingRef.current;
    if (!container) {
      return;
    }
    if (pulsingAnimation) {
      return;
    }

    setPulsingAnimation(
      lottie.loadAnimation({
        container,
        renderer: "svg",
        animationData: Pulsating,
        loop: false,
        autoplay: false,
      })
    );
  }, [pulsingAnimation]);

  useEffect(() => {
    const container = doneRef.current;
    if (!container) {
      return;
    }
    if (doneAnimation) {
      return;
    }
    setDoneAnimation(
      lottie.loadAnimation({
        container,
        renderer: "svg",
        animationData: Success,
        loop: false,
        autoplay: false,
      })
    );
  }, [doneAnimation]);

  useEffect(() => {
    if (status === LoaderStatus.Fail) {
      pulsingAnimation?.setLoop(false);
      pulsingAnimation?.addEventListener("complete", onDone);
    } else {
      pulsingAnimation?.removeEventListener("complete", onDone);
    }
  }, [onDone, pulsingAnimation, status]);

  useEffect(() => {
    if (status === LoaderStatus.Complete) {
      doneAnimation?.play();
      doneAnimation?.addEventListener("complete", onDone);
    } else {
      doneAnimation?.removeEventListener("complete");
    }
  }, [doneAnimation, status, onDone]);

  useEffect(() => {
    pulsingAnimation?.setLoop(true);
    pulsingAnimation?.play();
  }, [pulsingAnimation]);

  return (
    <div className={styles.Loader}>
      <div
        ref={pulsingRef}
        className={styles.animation}
        data-hidden={status === "complete"}
      />
      <div
        ref={doneRef}
        className={styles.animation}
        data-hidden={status !== "complete"}
      />
    </div>
  );
}
