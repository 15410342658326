import { localize } from "render/context/InternationalizationContext";

const Imperial = localize({
  en_GB: "Imperial",
  sv_SE: "Imperial",
});

const Metric = localize({
  en_GB: "Metric",
  sv_SE: "Metrisk",
});

export const Trans = {
  Imperial,
  Metric,
};
