import { APITypesV1 } from "@cur8/api-client";
import { DateTime } from "luxon";
import { GdprExport as GdprExportType } from "render/hooks/api/queries/useGdprExportQuery";
import { Button } from "render/ui/trigger/Button";
import { Completed } from "./Completed";
import { Creating } from "./Creating";
import { CreationFailed } from "./CreationFailed";
import { Loading } from "./Loading";
import * as Trans from "./Trans";

type StateProps = {
  create: () => void;
  email: string | undefined;
  gdprExport: GdprExportType | undefined;
  isLoading: boolean;
};

export function State({ create, email, gdprExport, isLoading }: StateProps) {
  if (isLoading) {
    return <Loading />;
  }

  if (!gdprExport?.id) {
    return (
      <Button variant="outlined" onClick={create}>
        <Trans.CreateFiles />
      </Button>
    );
  }

  const { completedOn, jobState } = gdprExport;
  const requestedOn = gdprExport.requestedOn.toLocaleString(DateTime.DATE_MED);

  if (failedStates.has(jobState)) {
    return <CreationFailed onRetry={create} requestedOn={requestedOn} />;
  }

  if (!completedOn) {
    return <Creating email={email} requestedOn={requestedOn} />;
  }

  return (
    <Completed
      onCreate={create}
      path={gdprExport.path}
      requestedOn={requestedOn}
    />
  );
}

const failedStates = new Set([
  APITypesV1.JobState.Failed,
  APITypesV1.JobState.CancellationRequested,
  APITypesV1.JobState.Cancelled,
  APITypesV1.JobState.EmergencyStopRequested,
  APITypesV1.JobState.Stopped,
  // the Backend job may fail entirely before even setting JobState,
  // therefore nullish values also represent failed states
  undefined,
  null,
]);
