import { Patient } from "@cur8/rich-entity";
import { localize } from "render/context/InternationalizationContext";

const Greeting = localize<{ patient: Patient }>({
  en_GB: ({ patient }) => {
    const name = patient.name?.firstName ? ` ${patient.name.firstName}` : "";
    return <>Hi{name}, we look forward to meeting you soon.</>;
  },
  sv_SE: ({ patient }) => {
    const name = patient.name?.firstName ? ` ${patient.name.firstName}` : "";
    return <>Hej{name}, vi ser fram emot att träffa dig snart.</>;
  },
});

const ScanBooked = localize({
  en_GB: <>Scan Booked</>,
  sv_SE: <>Scan bokad</>,
});

export const Trans = { Greeting, ScanBooked };
