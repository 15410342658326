import * as THREE from "three";
import { AvatarGeometry } from "../geometry/AvatarGeometry";
import { AvatarPointCloudMaterial } from "../materials/AvatarPointCloudMaterial";

export class AvatarPoints extends THREE.Points {
  geometry: AvatarGeometry;
  material: AvatarPointCloudMaterial;

  constructor({
    pointTexture,
    pointCount,
  }: {
    pointTexture: THREE.Texture;
    pointCount: number;
  }) {
    const geometry = new AvatarGeometry(pointCount);
    const material = new AvatarPointCloudMaterial({ pointTexture });

    super(geometry, material);

    this.geometry = geometry;
    this.material = material;
  }
}
