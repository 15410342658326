import { APITypesV1 } from "@cur8/api-client";
import { DiscountCodeAvailable } from "./components/DiscountCodeAvailable";
import { DiscountCodeExpired } from "./components/DiscountCodeExpired";
import { DiscountCodeRedeemed } from "./components/DiscountCodeRedeemed";
import { isExpired } from "render/hooks/api/queries/useInvitationCodesQuery";

interface DiscountTokenProps {
  token: APITypesV1.DiscountToken;
}

export function DiscountCode({ token }: DiscountTokenProps) {
  if (token.isRedeemed) {
    return <DiscountCodeRedeemed token={token} />;
  }
  if (isExpired(token)) {
    return <DiscountCodeExpired token={token} />;
  }

  return <DiscountCodeAvailable token={token} />;
}
