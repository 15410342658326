import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { Accordion } from "render/ui/presentation/AccordionV2";
import {
  RangeChart,
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import { capToMaxRanges } from "render/ui/presentation/RangeChart/lib/risk";
import Tabs from "render/ui/presentation/Tabs";
import { MetricSection } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details/MetricSection";
import {
  MetricViewProps,
  Values,
} from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details/types";
import { FAQTitles, Trans } from "../trans";
import styles from "./styles.module.sass";

type MetricDetailHeaderProps = MetricViewProps & {
  values: Values | undefined;
  FAQText?: {
    Why: () => JSX.Element;
    High?: () => JSX.Element;
    Low?: () => JSX.Element;
    Trend: () => JSX.Element;
    Aim?: () => JSX.Element;
  };
};

function FAQSection({
  FAQText,
}: Required<Pick<MetricDetailHeaderProps, "FAQText">>) {
  return (
    <Accordion>
      <Accordion.Item
        header={<FAQTitles.Why />}
        text={<FAQText.Why />}
        id="why"
      />
      {FAQText.High ? (
        <Accordion.Item
          header={<FAQTitles.High />}
          text={<FAQText.High />}
          id="high"
        />
      ) : null}
      {FAQText.Low ? (
        <Accordion.Item
          header={<FAQTitles.Low />}
          text={<FAQText.Low />}
          id="low"
        />
      ) : null}
      <Accordion.Item
        header={<FAQTitles.Trend />}
        text={<FAQText.Trend />}
        id="trend"
      />
      {FAQText.Aim ? (
        <Accordion.Item
          header={<FAQTitles.Aim />}
          text={<FAQText.Aim />}
          id="aim"
        />
      ) : null}
    </Accordion>
  );
}

export function MetricDetails({
  label,
  values,
  scanNum,
  setScanNum,
  side,
  setSide,
  numOfScans,
  isClinicalStudy,
  FAQText,
  hideChange,
}: MetricDetailHeaderProps) {
  if (!values) {
    return null;
  }

  const { ranges, ...restOfValues } = values;
  const chartRanges =
    ranges !== undefined
      ? capToMaxRanges({
          ranges: ranges,
          current: values.value,
          previous: values.previousValue,
        })
      : null;

  const hasSideMetrics =
    values.value !== undefined && side !== undefined && setSide !== undefined;

  return (
    <FullScreenPageLayout>
      <MetricSection
        label={label}
        values={restOfValues}
        scanNum={scanNum}
        setScanNum={setScanNum}
        numOfScans={numOfScans}
        isClinicalStudy={isClinicalStudy}
        tabs={
          hasSideMetrics ? (
            <Tabs
              tabs={[
                { label: <Trans.Left />, key: "left" },
                { label: <Trans.Right />, key: "right" },
              ]}
              selected={side}
              setSelected={(side: "left" | "right") => setSide(side)}
              ariaLabel="Select side"
            />
          ) : null
        }
        hideChange={hideChange}
      >
        <div className={styles.bottomHalf}>
          {chartRanges ? (
            <RangeChartDataComposer
              ranges={chartRanges}
              value={values.value}
              previousValue={hideChange ? undefined : values.previousValue}
              scanNum={scanNum}
            >
              {({ ranges, values }) => (
                <RangeChart ranges={ranges} values={values} />
              )}
            </RangeChartDataComposer>
          ) : (
            <div />
          )}
          {FAQText !== undefined ? <FAQSection FAQText={FAQText} /> : null}
        </div>
      </MetricSection>
    </FullScreenPageLayout>
  );
}
