import { RecordingURI } from "@cur8/uri";
import { createPoints, parsePointCloud } from "lib/meshuga/pointcloud";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import * as THREE from "three";

type Success<T> = {
  data: T;
  error?: never;
};

type Fail = {
  data?: never;
  error: Error;
};

export const ANTICIPATED_POINT_COUNT = 60000;

export type AsyncResult<T> = Success<T> | Fail;

export function usePointCloud(
  pose: "front" | "back",
  recordingURI?: RecordingURI,
  downSampleRatio: string = "1:6"
) {
  const { meshuga } = useAPIClient();

  const [result, setResult] = useState<AsyncResult<THREE.Points>>();

  useEffect(() => {
    if (!recordingURI) {
      return;
    }

    const { deviceId, recordingId } = recordingURI;

    const request = meshuga.fetchPointCloud({
      deviceId,
      recordingId,
      pose,
      nocolor: true,
      downsample: `patient-ui-dashboard/${downSampleRatio}`,
    });

    request.result
      .then(parsePointCloud)
      .then(createPoints)
      .then((points) => {
        setResult({ data: points });
      })
      .catch((error) => {
        setResult({ error });
      });

    return () => {
      request.abandon();
      setResult(undefined);
    };
  }, [meshuga, recordingURI, pose, downSampleRatio]);

  return result;
}
