import { APITypesV2 } from "@cur8/api-client";
import { ReactComponent as Error } from "assets/icons/16x16/16x16_alert.svg";
import { ReactComponent as Checkmark } from "assets/icons/16x16/16x16_checkmark.svg";
import { ReactComponent as Chevron } from "assets/icons/16x16/16x16_chevron-right.svg";
import { ReactComponent as Clock } from "assets/icons/16x16/16x16_clock.svg";

import { Typography } from "@cur8/maneki";
import { ReactComponent as Squircle } from "assets/squircle.svg";
import { useLocalisedMetadataDocumentUri } from "lib/consents/documentUri";
import { parseDocument } from "lib/legal/parseDocument";
import { DateTime } from "luxon";
import { useLegalDocumentQuery } from "render/hooks/api/queries/useLegalDocumentQuery";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { Card } from "render/ui/trigger/Card";
import { Trans } from "../../trans";
import styles from "./styles.module.sass";

type ConsentCardProps = {
  consent: APITypesV2.ConsentWithSignature;
  onOpenConsentClick: (consentId: string) => void;
};

export function ConsentCard({ consent, onOpenConsentClick }: ConsentCardProps) {
  const documentUri = useLocalisedMetadataDocumentUri(consent);
  const { data: legalDoc } = useLegalDocumentQuery(documentUri);

  if (consent.relevantSignature == null || legalDoc == null) {
    return;
  }

  const { relevantSignature } = consent;
  const { consentId, expired, revoked, optedOut } = relevantSignature;
  const verb = getVerb(relevantSignature);
  const date = getDate(relevantSignature);
  const { title, description } = parseDocument(legalDoc);
  const warning = expired || revoked || optedOut;

  const iconVariant = expired
    ? "expired"
    : revoked || optedOut
      ? "revoked"
      : "agreed";

  const Icon = getIcon(iconVariant);

  return (
    <Card onClick={() => onOpenConsentClick(consentId)}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div
            className={styles.squircle}
            data-variant={warning ? "warning" : "subtle"}
          >
            <Squircle />
            {Icon}
          </div>
          <Typography variant="label-m">{title}</Typography>
          <Chevron />
        </div>
        <Typography variant="body-s" color="subtle">
          {description}
        </Typography>
      </div>
      {verb && date ? (
        <Typography variant="eyebrow-m" color={warning ? "warning" : "subtle"}>
          <Trans.Status verb={verb} date={date} />
        </Typography>
      ) : (
        <div className={styles.skeleton}>
          <Skeleton />
        </div>
      )}
    </Card>
  );
}

function getIcon(statusState: "expired" | "revoked" | "agreed") {
  switch (statusState) {
    case "expired":
      return (
        <Clock data-variant="expired" className={styles.icon} aria-hidden />
      );
    case "revoked":
      return (
        <Error data-variant="revoked" className={styles.icon} aria-hidden />
      );
    default:
      return (
        <Checkmark data-variant="agreed" className={styles.icon} aria-hidden />
      );
  }
}

function getVerb({ expired, revoked, optedOut }: APITypesV2.ConsentSummary) {
  if (expired) return <Trans.Expired />;
  if (revoked) return <Trans.Revoked />;
  if (optedOut) return <Trans.OptedOut />;
  return <Trans.Agreed />;
}

function getDate({
  expired,
  expiresAt,
  revokedAt,
  createdAt,
}: APITypesV2.ConsentSummary) {
  const date = expired ? expiresAt : (revokedAt ?? createdAt);
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
}
