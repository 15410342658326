import { localize } from "render/context/InternationalizationContext";

const Icons = {
  Close: localize({
    en_GB: "Close",
    sv_SE: "Stänga",
  }),
  ContactUs: localize({
    en_GB: "Contact us",
    sv_SE: "Kontakta oss",
  }),
};

const Title = localize({
  en_GB: "Health report",
  sv_SE: "Hälsorapport",
});

const Tabs = {
  Summary: localize({
    en_GB: "Summary",
    sv_SE: "Summering",
  }),
  ScanData: localize({
    en_GB: "Scan Data",
    sv_SE: "Scan Data",
  }),
};

export const Trans = { Title, Tabs, Icons };
