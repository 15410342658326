import { APITypesV1 } from "@cur8/api-client";
import { Visit } from "@cur8/rich-entity";
import { ViewStack } from "@pomle/react-viewstack";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isRelevantVisit } from "render/context/PostScanShareContext/libs";
import { useLatestAttendedVisitQuery } from "render/hooks/api/queries/useLatestAttendedVisitQuery";
import { LogoView } from "render/views/LogoView";
import {
  PostScanShareView,
  Variant as PostScanShareViewVariant,
} from "render/views/PostScanShareView";
import { useLocalStorage } from "usehooks-ts";
import {
  PostScanShareStorageRecord,
  defaultStorageRecord,
  storageRecordDeserializer,
} from "./storage";

type PostScanShareConfig = { variant: PostScanShareViewVariant };

type PostScanShareContextValue = {
  visit: Visit | undefined | null;
  isOpen: boolean | undefined;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  markAsSeen: () => void;
  config: PostScanShareConfig;
  setConfig: (config: PostScanShareConfig) => void;
};

export const Context = createContext<PostScanShareContextValue | null>(null);

interface PostScanShareContextProps {
  children: React.ReactNode;
}

export function PostScanShareContext({ children }: PostScanShareContextProps) {
  const [storageRecord, setStorageRecord] =
    useLocalStorage<PostScanShareStorageRecord>(
      "PostScanIntro",
      defaultStorageRecord,
      {
        deserializer: storageRecordDeserializer,
      }
    );

  const latestAttendedVisitQuery = useLatestAttendedVisitQuery();

  const shouldShowIntro = useMemo(() => {
    if (!latestAttendedVisitQuery.isFetched) {
      return undefined;
    }

    const visit = latestAttendedVisitQuery.data;

    if (!visit) {
      return false;
    }

    if (visit.status !== APITypesV1.VisitStatus.Completed) {
      return false;
    }

    if (!isRelevantVisit(visit)) {
      return false;
    }

    if (storageRecord.lastSeenVisitId === visit.visitId) {
      return false;
    }

    return true;
  }, [
    latestAttendedVisitQuery.isFetched,
    latestAttendedVisitQuery.data,
    storageRecord.lastSeenVisitId,
  ]);

  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isOpen !== undefined) {
      return;
    }

    if (shouldShowIntro === undefined) {
      return;
    }

    setIsOpen(shouldShowIntro);
  }, [isOpen, shouldShowIntro]);

  const markAsSeen = useCallback(() => {
    const visit = latestAttendedVisitQuery.data;
    setStorageRecord((value) => {
      if (visit) {
        value.lastSeenVisitId = visit.visitId;
      }

      return value;
    });
  }, [latestAttendedVisitQuery.data, setStorageRecord]);

  const [config, setConfig] = useState<PostScanShareConfig>({
    variant: "page",
  });

  const value = {
    visit: latestAttendedVisitQuery.data,
    isOpen,
    setIsOpen,
    markAsSeen,
    config,
    setConfig,
  };

  /**
   * note(miha): when the share view variant is a "modal", we always render the children, so the modal appears on top of the current page content
   * when the share view variant is a standalone page, the view acts as an intro before the rest of the page flow
   * in that case, we render the children only when the share view is closed (= isOpen === false)
   * to make sure all the mount logic and animations are executed correctly
   */
  const renderChildren = config.variant === "modal" ? true : isOpen === false;

  return (
    <Context.Provider value={value}>
      <ViewStack>
        {renderChildren ? children : null}

        <PostScanShareView.FullscreenView
          isActive={config.variant === "page" && isOpen === true}
        >
          <PostScanShareView variant="page" />
        </PostScanShareView.FullscreenView>

        <PostScanShareView.Modal
          isActive={config.variant === "modal" && isOpen === true}
        >
          <PostScanShareView variant="modal" />
        </PostScanShareView.Modal>

        {isOpen === undefined && <LogoView />}
      </ViewStack>
    </Context.Provider>
  );
}

export function usePostScanShareContext() {
  const context = useContext(Context);

  if (!context) {
    throw new Error("usePostScanShareContext without PostScanShareContext");
  }

  return context;
}
