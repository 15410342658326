import { ReactElement } from "react";
import styles from "./styles.module.sass";

interface LegalDocumentLayoutProps {
  Description: ReactElement;
  Content: ReactElement;
  Actions: ReactElement;
  stickyActions?: boolean;
}

export function LegalDocumentLayout({
  Content,
  Description,
  Actions,
  stickyActions,
}: LegalDocumentLayoutProps) {
  return (
    <div className={styles.LegalDocumentLayout}>
      <div className={styles.description}>
        <div className={styles.container}>{Description}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.container}>{Content}</div>
      </div>
      <div className={styles.actions} data-sticky={stickyActions}>
        <div className={styles.container}>
          <div className={styles.actionContainer}>{Actions}</div>
        </div>
      </div>
    </div>
  );
}
