import {
  Answer,
  TextResponse,
  createTextAnswer,
  isTextResponse,
} from "@cur8/questionnaire";
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
} from "react";
import styles from "./styles.module.sass";
function stop(event: KeyboardEvent<HTMLTextAreaElement>) {
  event.stopPropagation();
}

interface TextInputProps {
  answer?: Answer;
  onAnswer: (answer: Answer<TextResponse>) => void;
  placeholder?: string;
}

export function TextInput({ answer, onAnswer, placeholder }: TextInputProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const value =
    answer && isTextResponse(answer.response) ? answer.response.content : "";

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const answer = createTextAnswer(event.target.value);
      onAnswer(answer);
    },
    [onAnswer]
  );

  useEffect(() => {
    if (!textAreaRef.current) {
      return;
    }
    textAreaRef.current.style.height = "0px";
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  });

  return (
    <div data-hj-suppress className={styles.TextInput}>
      <textarea
        placeholder={placeholder}
        ref={textAreaRef}
        value={value}
        onChange={handleChange}
        onKeyDown={stop}
        onKeyUp={stop}
      />
    </div>
  );
}
