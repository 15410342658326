import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Basophils</>,
  sv_SE: <>Basofiler</>,
});

const MetricUnit = localize({
  en_GB: <>mmol/L</>,
  sv_SE: <>mmol/L</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        Basophils, a type of white blood cell, are involved in inflammatory
        reactions, especially those related to allergies and parasitic
        infections. Basophils release histamine and other chemical messengers,
        contributing to the body's defence mechanisms. 
      </>
    ),
    sv_SE: (
      <>
        Basofiler, en typ av vita blodkroppar, är involverade i inflammatoriska
        reaktioner. Särskilt de som är relaterade till allergier och
        parasitinfektioner. Basofiler frigör histamin och andra kemiska
        signalämnen som bidrar till kroppens försvarsmekanismer.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        An elevated basophil count, known as basophilia, may indicate an
        allergic reaction or a chronic inflammatory condition. Persistently high
        levels can be a sign of more serious problems with blood cell production
        and should be discussed with your doctor. In allergic responses,
        basophils release histamine, leading to symptoms like itching, swelling,
        and redness. For parasitic infections, they are part of the body's
        response to fight off the parasites.
      </>
    ),
    sv_SE: (
      <>
        Ett förhöjt basofilantal kan bero på en allergisk reaktion eller ett
        kroniskt inflammatoriskt tillstånd. Ihållande höga nivåer kan vara ett
        tecken på allvarligare problem med blodcellsproduktionen och bör
        diskuteras med din läkare. Vid allergiska reaktioner frisätter basofiler
        histamin, vilket leder till symtom som klåda, svullnad och rodnad.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        A consistent increase in basophil count can be a sign of an ongoing
        allergic or inflammatory condition. A consistent decrease in basophil
        count, although rare, might be seen in certain conditions or as a
        reaction to specific medications. If your basophil count is persistently
        climbing or falling over time, then it may need further investigation.
      </>
    ),
    sv_SE: (
      <>
        En konsekvent ökning av basofilantal kan vara ett tecken på ett pågående
        allergiskt eller inflammatoriskt tillstånd och kan behöva ytterligare
        utredning.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        Your basophil count should usually be between 0 and 0.1, our doctors
        will advise you if you need to look into an abnormal basophil count.
      </>
    ),
    sv_SE: (
      <>
        Ditt basofilantal bör vanligtvis vara mellan 0 och 0,1, våra läkare
        kommer att ge dig råd om du behöver undersöka ett onormalt basofilantal.
      </>
    ),
  }),
};

export const Trans = { MetricName, MetricUnit, FAQ };
