import { useCallback, useState, useRef } from "react";

/*
Returns a handle for a callback that keeps track of busy state
and prevents hammering while busy in a consistent way.
*/
export function useAsyncHandle<T, A extends unknown[], P extends Promise<T>>(
  resolver: (...args: A) => P
) {
  const [busy, setBusy] = useState<boolean>(false);
  const inFlight = useRef<boolean>(false);

  const callback = useCallback(
    (...args: A): P | undefined => {
      if (inFlight.current === true) {
        return;
      }

      inFlight.current = true;
      setBusy(true);

      return resolver(...args).finally(() => {
        inFlight.current = false;
        setBusy(false);
      }) as P;
    },
    [resolver]
  );

  return {
    busy,
    callback,
  };
}
