import { clamp } from "lib/math";
import styles from "./styles.module.sass";

interface TransitionProps {
  offset: number;
  children: React.ReactNode;
}

export function Transition({ offset, children }: TransitionProps) {
  const realOffset = clamp(offset, -1, 1);

  return (
    <div
      className={styles.Transition}
      data-active={realOffset === 0}
      style={{
        transform: `translateX(${realOffset * 100}%`,
      }}
    >
      {children}
    </div>
  );
}
