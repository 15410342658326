import { ChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum SunburnFrequencyEnum {
  No = "No",
  OneToTwo = "One to two times",
  ThreeToFive = "Three to five times",
  SixToTen = "Six to ten times",
  MoreThanTen = "More than ten times",
}

const SunburnFrequencyChoices: Array<[JSX.Element, SunburnFrequencyEnum]> = [
  [<Trans.Sunburn.No />, SunburnFrequencyEnum.No],
  [<Trans.Sunburn.FrequencyOneToTwo />, SunburnFrequencyEnum.OneToTwo],
  [<Trans.Sunburn.FrequencyThreeToFive />, SunburnFrequencyEnum.ThreeToFive],
  [<Trans.Sunburn.FrequencySixToEight />, SunburnFrequencyEnum.SixToTen],
  [<Trans.Sunburn.FrequencyMoreThanTen />, SunburnFrequencyEnum.MoreThanTen],
];

export const SunburnFrequency = new ChoiceQuestion(
  <Trans.Sunburn.Question />,
  SunburnFrequencyChoices
);
