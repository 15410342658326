import { ReactComponent as ArrowIcon } from "assets/icons/24x24/24x24_arrow-right.svg";
import { ReactComponent as ApplePayIcon } from "assets/wallet/apple-pay.svg";
import { ReactComponent as GooglePayIcon } from "assets/wallet/google-pay.svg";
import { PropsWithChildren } from "react";
import styles from "./styles.module.sass";

type Variant = "primary" | "danger" | "secondary" | "suggestion" | "wallet";
interface ActionButtonProps {
  variant?: Variant;
  walletVariant?: string;
  onClick: () => void;
  hideIcon?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  form?: string;
}

export function ActionButton({
  variant = "primary",
  walletVariant,
  onClick,
  children,
  hideIcon,
  disabled,
  form,
}: PropsWithChildren<ActionButtonProps>) {
  return (
    <button
      disabled={disabled}
      className={styles.button}
      data-variant={variant}
      onClick={onClick}
      form={form}
    >
      {children}

      {hideIcon || variant === "wallet" ? undefined : (
        <div className={styles.arrowIcon}>
          <ArrowIcon className={styles.arrowIconLeft} />
          <ArrowIcon className={styles.arrowIconRight} />
        </div>
      )}

      {variant !== "wallet" ? undefined : (
        <>
          {walletVariant === "apple" ? <ApplePayIcon /> : undefined}
          {walletVariant === "google" ? <GooglePayIcon /> : undefined}
        </>
      )}
    </button>
  );
}
