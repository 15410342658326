import * as THREE from "three";

export function sortPointBuffer(
  buffer: THREE.BufferAttribute,
  sortFn: (vec: THREE.Vector3) => number
) {
  const points: { point: THREE.Vector3; distance: number }[] = [];
  for (let i = 0; i < buffer.count; i++) {
    const vec = new THREE.Vector3(
      buffer.getX(i),
      buffer.getY(i),
      buffer.getZ(i)
    );

    const distance = sortFn(vec);

    points.push({ point: vec, distance });
  }

  points.sort((a, b) => {
    return b.distance - a.distance;
  });

  for (let i = 0; i < buffer.count; i++) {
    const { x, y, z } = points[i].point;
    buffer.setXYZ(i, x, y, z);
  }
}

export function normalizePCDBuffers(
  size: number,
  front: THREE.BufferGeometry,
  back: THREE.BufferGeometry,
  heart: THREE.BufferGeometry
) {
  const frontSource = front.getAttribute("position");
  const backSource = back.getAttribute("position");
  const heartSource = heart.getAttribute("position");

  const array = new Float32Array(size);
  const frontBuffer = new THREE.Float32BufferAttribute(array, 3);
  const backBuffer = frontBuffer.clone();
  const heartBuffer = frontBuffer.clone();

  // Copy points from both front and back and duplicate points for whichever is shorter
  const sourceLength = Math.max(frontSource.count, backSource.count);
  for (let index = 0; index < sourceLength; index++) {
    {
      const sourceIndex = index % frontSource.count;
      const x = frontSource.getX(sourceIndex);
      const y = frontSource.getY(sourceIndex);
      const z = frontSource.getZ(sourceIndex);
      frontBuffer.setXYZ(index, x, y, z);
    }

    {
      const sourceIndex = index % backSource.count;
      const x = backSource.getX(sourceIndex);
      const y = backSource.getY(sourceIndex);
      const z = backSource.getZ(sourceIndex);
      backBuffer.setXYZ(index, x, y, z);
    }

    if (index < heartSource.count) {
      const x = heartSource.getX(index);
      const y = heartSource.getY(index);
      const z = heartSource.getZ(index);
      heartBuffer.setXYZ(index, x, y, z);
    }
  }

  const anchor = new THREE.Vector3(0, 0, 2500);

  sortPointBuffer(frontBuffer, (vec) => anchor.distanceToSquared(vec));
  sortPointBuffer(backBuffer, (vec) => anchor.distanceToSquared(vec));
  sortPointBuffer(heartBuffer, () => Math.random());

  return { front: frontBuffer, back: backBuffer, heart: heartBuffer };
}
