/* eslint no-extend-native: 0 */

if (Array.prototype.toSorted == null) {
  Array.prototype.toSorted = function toSorted<T>(
    compareFn?: (a: T, b: T) => number
  ): T[] {
    return this.slice().sort(compareFn);
  };
}

export {};
