import { localize } from "render/context/InternationalizationContext";

const Baseline = localize({
  en_GB: <>Baseline</>,
  sv_SE: <>Baseline</>,
});

const FollowUp = localize({
  en_GB: <>Follow up</>,
  sv_SE: <>Uppföljning</>,
});

const WrittenOn = localize({
  en_GB: "Written on ",
  sv_SE: "Skriven den ",
});

const ReportHistory = localize({
  en_GB: <>Report History</>,
  sv_SE: <>Rapporthistorik</>,
});

export const Trans = {
  ReportHistory,
  Baseline,
  FollowUp,
  WrittenOn,
};
