import { useAPIClient } from "render/context/APIContext";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { APITypesV1 } from "@cur8/api-client";
import { useSession } from "render/context/MSALContext";

class TokenRedeemedByPatientException extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, TokenRedeemedByPatientException.prototype);
  }
}

class TokenRefererIsRedeemedException extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, TokenRedeemedByPatientException.prototype);
  }
}

class TokenInvalidException extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, TokenRedeemedByPatientException.prototype);
  }
}

function assertToken(token: APITypesV1.DiscountToken, patientId: string) {
  const { isRedeemed, referrer, redeemerId } = token;
  if (isRedeemed && referrer != null && redeemerId === patientId) {
    throw new TokenRedeemedByPatientException(
      "Token has already been redeemed by this patient"
    );
  }
  if (referrer === patientId) {
    throw new TokenRefererIsRedeemedException(
      "token can not be redeemed by the referer"
    );
  }
  if (!token.isValid) {
    throw new TokenInvalidException("discount token invalid");
  }

  return true;
}

export function useValidateTokenMutation() {
  const apiClient = useAPIClient().billing;
  const { patientId } = useSession();

  const mutationFn = useCallback(
    async (code: string) => {
      const token = await apiClient.getDiscountTokenCode(code).result;
      assertToken(token, patientId);
      return token;
    },
    [apiClient, patientId]
  );

  return useMutation({
    mutationFn,
  });
}
