import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Haemoglobin</>,
  sv_SE: <>Hemoglobin</>,
});

const MetricUnit = localize({
  en_GB: <>g/L</>,
  sv_SE: <>g/L</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        Your haemoglobin level can help in understanding how well your blood can
        transport oxygen to your body's organs and tissues. Haemoglobin is a
        large iron-containing protein which gives your blood its red colour.
        Assessing haemoglobin levels helps identify conditions like anaemia,
        which affects your body's oxygenation, and can also highlight other
        health concerns.
      </>
    ),
    sv_SE: (
      <>
        Ditt hemoglobinvärde kan hjälpa till att förstå hur väl ditt blod kan
        transportera syre till kroppens organ och vävnader. Hemoglobin är ett
        stort järninnehållande protein som ger ditt blod dess röda färg. Att
        bedöma hemoglobinhalten hjälper till att identifiera tillstånd som
        blodbrist, vilket påverkar syresättningen av din kropp, och kan också
        belysa andra hälsobekymmer.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        A common cause of high haemoglobin levels is dehydration, which
        decreases the amount of liquid in your blood. This makes it look like
        there are more red cells and more haemoglobin than there usually is. It
        can also be a sign of polycythaemia. This happens when your body
        produces too many red blood cells. There are many causes for this,
        including smoking, high exercise levels, and genetic problems. Another
        blood test is usually needed to understand a high haemoglobin level.
      </>
    ),
    sv_SE: (
      <>
        En vanlig orsak till höga hemoglobinnivåer är uttorkning, vilket minskar
        mängden vätska i ditt blod. Detta gör att koncentrationen av hemoglobin
        stiger. Det kan också vara ett tecken på polycytemi, vilket inträffar
        när din kropp producerar för många röda blodkroppar. Några orsaker
        inkluderar rökning, hög fysisk aktivitetsnivå och sömnapné.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low haemoglobin level is called anaemia, which means your blood does
        not have enough haemoglobin to carry oxygen to your body's tissues. This
        can cause fatigue, dizziness and breathlessness. It is commonly caused
        by nutritional deficiencies such as low levels of iron or vitamin B12,
        as well as by chronic diseases, inherited conditions, or bone marrow
        issues.
      </>
    ),
    sv_SE: (
      <>
        Ett lågt hemoglobinvärde kallas anemi, vilket innebär att ditt blod inte
        har tillräckligt med hemoglobin för att transportera syre till kroppens
        vävnader. Detta kan orsaka trötthet, yrsel och andfåddhet. Det orsakas
        vanligtvis av näringsbrister såsom låga nivåer av järn eller vitamin
        B12. Andra orsaker är kroniska sjukdomar, ärftliga tillstånd eller
        problem med benmärgen.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        The trend in haemoglobin levels over time can help you to understand
        your overall health, the effects of lifestyle changes, and whether there
        is an underlying condition affecting your red blood cells. A haemoglobin
        level that is falling over time could be a sign that you are developing
        anaemia. When haemoglobin keeps falling you should see your doctor.
        Making healthy lifestyle choices like eating more green leafy vegetables
        or exercising more can make your haemoglobin rise over time. The trend
        can be useful to monitor these. If your haemoglobin keeps rising it may
        be a sign of an underlying condition.
      </>
    ),
    sv_SE: (
      <>
        Trenden i hemoglobinhalten över tid kan hjälpa dig att förstå din
        övergripande hälsa, effekterna av livsstilsförändringar och om det finns
        ett underliggande tillstånd som påverkar dina röda blodkroppar. Ett
        värde som sjunker över tid kan vara ett tecken på att du utvecklar
        anemi. Om hemoglobinet fortsätter att sjunka bör du kontakta din läkare.
        Att göra hälsosamma livsstilsval som att äta mer gröna bladgrönsaker
        eller motionera mer kan få ditt hemoglobin att stiga över tid. Trenden
        kan vara användbar för att övervaka detta. Om ditt hemoglobin fortsätter
        att stiga kan det vara ett tecken på en underliggande sjukdom.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        If your haemoglobin is within the normal range, and not changing over
        time there’s usually no cause for concern. This means your blood is
        effectively transporting oxygen, and your lifestyle and health measures
        are on track.
      </>
    ),
    sv_SE: (
      <>
        Om ditt hemoglobin ligger inom det normala intervallet och inte
        förändras över tid finns det vanligtvis ingen anledning till oro. Det
        innebär att ditt blod effektivt transporterar syre och att din kropp har
        de byggstenar som behövs.
      </>
    ),
  }),
};

export const Trans = { MetricName, MetricUnit, FAQ };
