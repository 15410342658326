import { Typography } from "@cur8/maneki";
import { BookableSlot } from "@cur8/rich-entity";
import { ReactComponent as CheckIcon } from "assets/icons/24x24/24x24_checkmark.svg";
import { useCallback } from "react";
import { useSitesQuery } from "render/views/booking/MultiSiteMonthView/hooks/useSitesQuery";
import styles from "./styles.module.sass";

interface BookableTimeSlotProps {
  slot: BookableSlot;
  isSelected: boolean;
  onClick: (slot: BookableSlot) => void;
}

export function BookableTimeSlot({
  onClick,
  slot,
  isSelected,
}: BookableTimeSlotProps) {
  const sitesQuery = useSitesQuery();
  const siteInfo = sitesQuery.data?.find((site) => {
    return site.siteId === slot.siteId;
  });
  const timeZoneId = siteInfo?.timeZoneId;

  const onClickHandler = useCallback(() => {
    onClick(slot);
  }, [slot, onClick]);

  return (
    <button
      onClick={onClickHandler}
      data-selected={isSelected}
      className={styles.BookableTimeSlot}
    >
      <div className={styles.content}>
        <div className={styles.time}>
          <Typography variant="label-m" color="default">
            {slot.startTime.setZone(timeZoneId).toFormat("HH:mm")}
          </Typography>
        </div>
        <div className={styles.location}>
          <div className={styles.name}>
            <Typography variant="body-s" color="default">
              {siteInfo?.siteName}
            </Typography>
          </div>

          <div className={styles.address}>
            <Typography color="subtle" variant="body-xs">
              {siteInfo?.address.street}
            </Typography>
          </div>
        </div>
        <div className={styles.checkMark}>
          <CheckIcon />
        </div>
      </div>
    </button>
  );
}
