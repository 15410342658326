import {
  Answer,
  ChoiceQuestion,
  ChoiceResponse,
  createChoiceAnswer,
  isChoiceResponse,
} from "@cur8/questionnaire";
import React, { useCallback } from "react";
import { Radio } from "../Radio";
import styles from "./styles.module.sass";

interface ChoiceInputProps<T> {
  question: ChoiceQuestion<T, React.ReactNode, React.ReactNode>;
  answer?: Answer;
  onAnswer: (answer: Answer<ChoiceResponse>) => void;
}

export function ChoiceInput<T>({
  question,
  answer,
  onAnswer,
}: ChoiceInputProps<T>) {
  let pickedIndex: number;

  if (answer && isChoiceResponse(answer.response)) {
    pickedIndex = answer.response.choiceIndex;
  }

  const handleChoice = useCallback(
    (choiceIndex: number) => {
      const answer = createChoiceAnswer(choiceIndex);
      onAnswer(answer);
    },
    [onAnswer]
  );
  return (
    <fieldset data-hj-suppress className={styles.ChoiceInput}>
      {question.choices.map((choice, choiceIndex) => {
        return (
          <Radio
            key={choiceIndex}
            choiceIndex={choiceIndex}
            pickedIndex={pickedIndex}
            choice={choice}
            handleChoice={handleChoice}
          />
        );
      })}
    </fieldset>
  );
}
