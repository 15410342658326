import { Typography } from "@cur8/maneki";
import { useNav } from "@pomle/react-router-paths";
import { useConfig } from "render/context/ConfigContext";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { profileEvent } from "render/views/ProfileView/tracking";
import { EditPage } from "../EditPage/EditPage";
import { Trans } from "./Trans";

export function EditPhoneNumberPage() {
  const patientQuery = usePatientQuery();

  const nav = {
    profileCredentials: useNav(paths.profileCredentials),
  };

  const { appConfig } = useConfig();
  const { trackEvent } = useTracking();
  const accountServiceEditPhoneNumberPage = new URL(
    "phone-number-update",
    appConfig.msal.auth.authority
  ).toString();

  return (
    <EditPage
      title={<Trans.Title />}
      subTitle={patientQuery.data?.contactDetails.phoneNumbers.at(0)}
      onBack={nav.profileCredentials.on({})}
      cta={
        <a href={accountServiceEditPhoneNumberPage} tabIndex={-1}>
          <ActionButton
            variant="suggestion"
            onClick={() => {
              trackEvent(profileEvent.changeNumber());
            }}
          >
            <Typography variant="label-m">
              <Trans.Link />
            </Typography>
          </ActionButton>
        </a>
      }
    />
  );
}
