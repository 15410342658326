import { DateTime, Interval } from "luxon";
import { useCallback, useMemo } from "react";
import { AnimationTime } from "../../utils/animationConstants";
import { MeasuredBloodPressureDataLookUpValue } from "../../utils/createMeasurementsLookUpTable";
import { AnimationDelayer } from "../AnimationDelayer";
import { ReactComponent as ShevronIcon } from "./assets/shevron.svg";
import { ProgressDayCheckBox } from "./components/ProgressDayCheckBox";
import styles from "./styles.module.sass";

export interface ProgressDaysProps {
  assignmentInterval: Interval;
  selectedInterval: Interval;
  measurements: Map<string, MeasuredBloodPressureDataLookUpValue>;
  onSlotClick?: (day: DateTime) => void;
  onNav: (dateTime: DateTime) => void;
}

export function ProgressDays({
  onSlotClick,
  measurements,
  assignmentInterval,
  selectedInterval,
  onNav,
}: ProgressDaysProps) {
  const range = useMemo(
    () => assignmentInterval.splitBy({ day: 1 }).map(({ start }) => start),
    [assignmentInterval]
  );
  const findMeasurementAt = useCallback(
    (target: DateTime) => measurements.get(target.toISODate()),
    [measurements]
  );
  const isDateInFuture = useCallback(
    (target: DateTime) => target.startOf("day") > DateTime.now().startOf("day"),
    []
  );

  const canNavBack = useMemo(
    () =>
      selectedInterval.start.startOf("day") >
      assignmentInterval.start.startOf("day"),
    [assignmentInterval, selectedInterval]
  );
  const canNavForward = useMemo(
    () =>
      selectedInterval.end.endOf("day") < assignmentInterval.end.endOf("day"),
    [assignmentInterval, selectedInterval]
  );

  return (
    <div className={styles.ProgressDays}>
      <div className={styles.values}>
        <button
          disabled={!canNavBack}
          className={styles.navButton}
          onClick={() => onNav(selectedInterval.start.minus({ day: 1 }))}
        >
          <ShevronIcon />
        </button>
        <div className={styles.data}>
          {range.map((day) => {
            const data = findMeasurementAt(day);
            const isWithinSelectedRange = selectedInterval.contains(day);
            const initialAnimationDelay =
              isWithinSelectedRange && data
                ? data.orderPosition * AnimationTime.DelayBetweenSteps
                : 0;

            return (
              <AnimationDelayer
                key={day.toUnixInteger()}
                defaultDelay={AnimationTime.DelayForCheckBox}
                initialDelay={
                  initialAnimationDelay + AnimationTime.DelayForCheckBox
                }
                isActive={!!isWithinSelectedRange}
              >
                {({ delay, isActive }) => (
                  <ProgressDayCheckBox
                    delay={delay}
                    day={day}
                    disabled={isDateInFuture(day) || !data}
                    onClick={onSlotClick}
                    hidden={!isActive}
                    status={!!data ? "completed" : "empty"}
                  />
                )}
              </AnimationDelayer>
            );
          })}
        </div>
        <button
          disabled={!canNavForward}
          className={styles.navButton}
          onClick={() => onNav(selectedInterval.start.plus({ day: 1 }))}
        >
          <ShevronIcon style={{ transform: "rotate(180deg)" }} />
        </button>
      </div>
    </div>
  );
}
