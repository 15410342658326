import { localize } from "render/context/InternationalizationContext";

export const Message = {
  NoConsentState: localize({
    en_GB: <>No data</>,
    sv_SE: <>Ingen information</>,
  }),
};

export const Trans = { Message };
