/**
 * Get a matching locale based on navigator languages or fallback
 */

import { FALLBACK_LOCALE } from "render/context/InternationalizationContext/InternationalizationContext";

export function getNavigatorLang(): string {
  let lang = FALLBACK_LOCALE.toString();
  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0];
  } else {
    lang =
      navigator.language ||
      navigator.userLanguage ||
      navigator.browserLanguage ||
      FALLBACK_LOCALE;
  }
  return lang.replace("-", "_");
}
