import { BrachialPressure, Systolic } from "@cur8/health-risks-calc";
import { calcAge } from "lib/age";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useBrachialPressureMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

type useBloodPressureValuesType = {
  scanNum?: number;
  side: "left" | "right";
};

const EMPTY = {
  subLabel: undefined,
  previousValue: undefined,
  value: undefined,
  metricRating: undefined,
  scanDate: undefined,
  ranges: undefined,
};

export function useBloodPressureValues({
  scanNum,
  side,
}: useBloodPressureValuesType) {
  const { data: patient, isLoading: isPatientLoading } = usePatientQuery();

  const { data, isLoading: isDataLoading } = useBrachialPressureMetric();

  if (isDataLoading || isPatientLoading) {
    return "loading";
  }

  if (!data || !patient) {
    return EMPTY;
  }

  const { cur: curLeft, prev: prevLeft } = getMetricHistory(data.left, scanNum);
  const { cur: curRight, prev: prevRight } = getMetricHistory(
    data.right,
    scanNum
  );

  const age = patient.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;

  const subLabelLeft = curLeft ? (
    <Trans.MetricUnit unit={`/ ${curLeft.unit.diastolic.mmHg}`} />
  ) : undefined;
  const subLabelRight = curRight ? (
    <Trans.MetricUnit unit={`/ ${curRight.unit.diastolic.mmHg}`} />
  ) : undefined;

  const prevBloodPressureLeft = prevLeft?.unit.systolic.mmHg;
  const prevBloodPressureRight = prevRight?.unit.systolic.mmHg;

  const valueLeft = curLeft?.unit.systolic.mmHg;
  const valueRight = curRight?.unit.systolic.mmHg;

  const metricRatingLeft =
    curLeft && BrachialPressure.riskFor({ ...curLeft.unit, age });
  const metricRatingRight =
    curRight && BrachialPressure.riskFor({ ...curRight.unit, age });

  const curScanDateLeft = curLeft?.measurement.timestampStart;
  const curScanDateRight = curRight?.measurement.timestampStart;
  // currently we are displaying only Systolic values
  const riskRanges = Systolic.rangesFor({ age });

  if (side === "left") {
    return {
      subLabel: subLabelLeft,
      previousValue: prevBloodPressureLeft,
      value: valueLeft,
      metricRating: metricRatingLeft,
      scanDate: curScanDateLeft,
      ranges: toChartRanges(riskRanges.entries),
    };
  }

  if (side === "right") {
    return {
      subLabel: subLabelRight,
      previousValue: prevBloodPressureRight,
      value: valueRight,
      metricRating: metricRatingRight,
      scanDate: curScanDateRight,
      ranges: toChartRanges(riskRanges.entries),
    };
  }

  return EMPTY;
}
