import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  MoreInfo: localize({
    en_GB: <>More information</>,
    sv_SE: <>Mer information</>,
  }),
  ConsentHistory: localize({
    en_GB: <>Download consent history</>,
    sv_SE: <>Ladda ner samtyckeshistorik</>,
  }),
  LatestVersion: localize<{ link: string }>({
    // TODO: wrap anchor tags in link Typography once the updated Typography component is merged
    en_GB: ({ link }) => (
      <>
        This is the latest version of the consent. It has changed from the copy
        that you signed. To download your signature history for this consent,{" "}
        <a href={link} target="_blank" rel="noreferrer">
          click here
        </a>
        .
      </>
    ),
    sv_SE: ({ link }) => (
      <>
        Detta är den senaste versionen av samtycket. Det har ändrats från den
        kopia som du undertecknade. För att ladda ner din signaturhistorik för
        detta samtycke,{" "}
        <a href={link} target="_blank" rel="noreferrer">
          klicka här
        </a>
        .
      </>
    ),
  }),
};
