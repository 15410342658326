import { record, string } from "@pomle/shapes";

type PostScanShareStorageRecord = {
  lastSeenVisitId: string | undefined;
};

const validateStorageRecord = record<PostScanShareStorageRecord>({
  lastSeenVisitId: string(undefined),
});

const defaultStorageRecord = validateStorageRecord({});

function storageRecordDeserializer(
  rawValue: string
): PostScanShareStorageRecord {
  try {
    const value = JSON.parse(rawValue);

    return validateStorageRecord(value);
  } catch (e) {
    console.warn(
      "failed to deserialize PostScanShareStorage from local storage"
    );
    return defaultStorageRecord;
  }
}

export {
  defaultStorageRecord,
  storageRecordDeserializer,
  validateStorageRecord,
};
export type { PostScanShareStorageRecord };
