import { FileSize } from "render/ui/format/FileSize";
import { Badge } from "render/ui/presentation/Badge";
import { Skeleton } from "render/ui/presentation/Skeleton";
import styles from "./styles.module.sass";

type FileSizeBadgeProps = {
  size: string | undefined | null;
};

export function FileSizeBadge({ size }: FileSizeBadgeProps) {
  if (!size) {
    return (
      <div className={styles.fileSizeSkeleton}>
        <Skeleton />
      </div>
    );
  }

  return (
    <Badge variant="noData">
      <FileSize size={Number(size)} />
    </Badge>
  );
}
