import { Slot } from "@cur8/rich-entity";
import {
  useVisitPrerequisites,
  VisitPrerequisiteStep,
} from "render/hooks/api/useVisitPrerequisites";
import { useTracking } from "render/hooks/useTracking";
import { appointmentEvents } from "../../tracking";
import { CompleteProfileButton } from "../CompleteProfileButton/";
import { StepButton } from "./components/StepButton/StepButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export interface StepsSectionProps {
  slot: Slot;
  visitId: string;
}

function PrerequisiteStepText({ step }: { step: VisitPrerequisiteStep }) {
  switch (step.id) {
    case "idv":
      return step.completed ? (
        <Trans.TodoList.Done.IdentityVerification />
      ) : (
        <Trans.TodoList.Todo.IdentityVerification />
      );
    case "passkey":
      return step.completed ? (
        <Trans.TodoList.Done.Passkey />
      ) : (
        <Trans.TodoList.Todo.Passkey />
      );
    case "questionnaire":
      return step.completed ? (
        <Trans.TodoList.Done.CompleteOnboarding />
      ) : (
        <Trans.TodoList.Todo.CompleteOnboarding />
      );
    case "studies":
      return step.completed ? (
        <Trans.TodoList.Done.RenewStudies />
      ) : (
        <Trans.TodoList.Todo.RenewStudies />
      );
    default:
      return null;
  }
}

export function StepsSection({ slot, visitId }: StepsSectionProps) {
  const { trackEvent } = useTracking();
  const { isReady, steps } = useVisitPrerequisites({
    origin: "appointment",
    visitId,
  });

  return (
    <div className={styles.StepsSection}>
      <div>
        <div className={styles.stepsHeader}>
          {isReady ? (
            <Trans.Title.Ready.Header />
          ) : (
            <Trans.Title.NotReady.Header />
          )}
        </div>
        <div className={styles.stepsDescription}>
          {isReady ? (
            <Trans.Title.Ready.SubHeader />
          ) : (
            <Trans.Title.NotReady.SubHeader />
          )}
        </div>
        <ul className={styles.todoList}>
          {steps.map((step) => {
            return (
              <li key={step.id}>
                <StepButton
                  disabled={step.disabled}
                  completed={step.completed}
                  onClick={() => {
                    trackEvent(
                      appointmentEvents.completeProfileStepClick(step.id)
                    );
                    step.goTo(slot.slotId);
                  }}
                >
                  <PrerequisiteStepText step={step} />
                </StepButton>
              </li>
            );
          })}
        </ul>

        <div data-completed={isReady} className={styles.profileButton}>
          <CompleteProfileButton slot={slot} visitId={visitId} />
        </div>
      </div>
    </div>
  );
}
