import { Slot } from "@cur8/rich-entity";

export enum AppointmentValidity {
  NoAppointment = "no-appointment",
  EarlyArrival = "early-arrival",
  LateArrival = "late-arrival",
  Valid = "valid",
}

const GracePeriodMinutes = 20;

export function getAppointmentSlotValidity(slot?: Slot) {
  if (!slot) {
    return AppointmentValidity.NoAppointment;
  }
  if (slot.startTime.diffNow("minutes").minutes > GracePeriodMinutes) {
    return AppointmentValidity.EarlyArrival;
  }
  if (slot.startTime.diffNow("minutes").minutes < -GracePeriodMinutes) {
    return AppointmentValidity.LateArrival;
  }
  return null;
}
