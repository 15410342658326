import { APITypesV2 } from "@cur8/api-client";

export type MappedConsent = {
  /** Description of a consent */
  consentMetadata: APITypesV2.ConsentMetadata;
  /** A summary of a signed consent */
  relevantSignature: APITypesV2.ConsentSummary | undefined;
};

// this function maps over the consents that need to be signed and adds the signature if they are already signed
export function mapConsents({
  requiredConsents,
  previouslySignedConsents,
}: {
  requiredConsents: APITypesV2.ConsentMetadata[] | undefined;
  previouslySignedConsents: APITypesV2.ConsentWithSignature[] | undefined;
}): MappedConsent[] | undefined {
  const signatures = new Map(
    previouslySignedConsents?.map((c) => [
      c.consentMetadata.consentId,
      c.relevantSignature,
    ])
  );

  return requiredConsents?.map((requiredConsent) => {
    const signature = signatures.get(requiredConsent.consentId);

    if (
      !requiredConsent.askConsentEveryTime &&
      signature &&
      !signature.expired &&
      !signature.revoked
    ) {
      return {
        consentMetadata: requiredConsent,
        relevantSignature: signature,
      };
    }

    return {
      consentMetadata: requiredConsent,
      relevantSignature: undefined,
    };
  });
}
