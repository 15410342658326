import { Visit } from "@cur8/rich-entity";

export function isRelevantVisit(visit: Visit) {
  const diffNow = visit.checkinStartTimestamp?.diffNow("days").days;
  if (diffNow == null) {
    return false;
  }

  return diffNow >= -14 && diffNow <= 0;
}
