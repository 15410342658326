export function listen<K extends keyof WindowEventMap>(
  window: Window,
  type: K,
  listener: (this: Element, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): void;
export function listen(
  window: Window,
  type: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions
): void;
export function listen<K extends keyof DocumentEventMap>(
  document: Document,
  type: K,
  listener: (this: Document, ev: DocumentEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): void;
export function listen(
  document: Document,
  type: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions
): void;
export function listen<K extends keyof HTMLElementEventMap>(
  element: HTMLElement,
  type: K,
  listener: (this: HTMLAnchorElement, ev: HTMLElementEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): void;
export function listen(
  element: HTMLElement,
  type: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions
): void;
export function listen<K extends keyof ElementEventMap>(
  element: Element,
  type: K,
  listener: (this: Element, ev: ElementEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): void;
export function listen(
  element: Element,
  type: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions
): void;
export function listen<K extends keyof MediaQueryListEventMap>(
  mediaQueryList: MediaQueryList,
  type: K,
  listener: (this: MediaQueryList, ev: MediaQueryListEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): void;
export function listen(
  mediaQueryList: MediaQueryList,
  type: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions
): void;
export function listen(
  eventTarget: EventTarget,
  type: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions
) {
  eventTarget.addEventListener(type, listener, options);
  return () => eventTarget.removeEventListener(type, listener, options);
}
