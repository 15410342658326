/* eslint-disable @typescript-eslint/no-explicit-any */
export function isInTestMode(config: any) {
  const url = new URL(window.location.href);

  const hasTestModeParam = (window as any)["neko"]?.isTestMode === true;
  const isLocalHost = url.host === "localhost:3001";
  const hasTestModeInConfig = config.isTestMode === true;

  const result = hasTestModeParam && isLocalHost && hasTestModeInConfig;

  return result;
}
