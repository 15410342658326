import { localize } from "render/context/InternationalizationContext";

const FailToConnect = {
  Header: localize({
    en_GB: "Oh no...",
    sv_SE: "Åh nej...",
  }),
  Description: localize({
    en_GB: "We could not connect to the concierge, please try again",
    sv_SE: "----",
  }),
};

const FailToFetchAppointment = {
  Header: localize({
    en_GB: "Oh no...",
    sv_SE: "Åh nej...",
  }),
  Description: localize({
    en_GB: "We could not get the appointment information, please try again.",
    sv_SE: "Vi kunde inte få besöksinformationen, försök igen.",
  }),
};

const NoAppointment = {
  Header: localize({
    en_GB: "Oh no...",
    sv_SE: "Åh nej...",
  }),
  Description: localize({
    en_GB: "We could not find your appointment. The concierge will help you.",
    sv_SE: "Vi kunde inte hitta din bokade tid. Conciergen hjälper dig.",
  }),
};

const EarlyArrival = {
  Header: localize({
    en_GB: "Almost there...",
    sv_SE: "Nästan där...",
  }),
  Description: localize({
    en_GB: "Looks like you're a bit early. The concierge will help you.",
    sv_SE: "Det verkar som om du är lite tidig. Conciergen hjälper dig.",
  }),
};

const LateArrival = {
  Header: localize({
    en_GB: "Almost there...",
    sv_SE: "Nästan där...",
  }),
  Description: localize({
    en_GB:
      "Looks like you're too late for your appointment. The concierge will help you.",
    sv_SE:
      "Det verkar som om du är sen till ditt besök. Conciergen hjälper dig.",
  }),
};

const UnableToCheckIn = {
  Header: localize({
    en_GB: "Oh no...",
    sv_SE: "Åh nej...",
  }),
  Description: localize({
    en_GB: "We were unable to check you in. The concierge will help you.",
    sv_SE: "Vi kunde inte checka in dig. Conciergen hjälper dig.",
  }),
};

export const Trans = {
  UnableToCheckIn,
  FailToConnect,
  NoAppointment,
  FailToFetchAppointment,
  EarlyArrival,
  LateArrival,
};
