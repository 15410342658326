import { DateTime } from "luxon";

interface DayDateProps {
  date: DateTime;
}

export function DayDate({ date }: DayDateProps) {
  return (
    <time role="time" dateTime={date.toISO()}>
      {date.toFormat("yyyy-LL-dd")}
    </time>
  );
}
