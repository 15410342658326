import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: <>Add an extra level of security with Passkey</>,
  sv_SE: <>Gör ditt konto säkrare</>,
});

const Paragraph1 = localize({
  en_GB: (
    <>
      Passkeys are an easier and more secure alternative to passwords. They let
      you sign in with just your fingerprint, face scan or screen lock.
    </>
  ),
  sv_SE: (
    <>
      Passkeys är ett enklare och säkrare alternativ till lösenord. De låter dig
      logga in med ditt fingeravtryck, ansiktsskanning eller skärmlås.
    </>
  ),
});

const SubParagraph1 = localize({
  en_GB: <>It takes about 1 minute to complete</>,
  sv_SE: <>Det tar ca 1 minut</>,
});

const AddPasskey = localize({
  en_GB: <>Add Passkey</>,
  sv_SE: <>Lägg till Passkey</>,
});

const SetupLater = localize({
  en_GB: <>Set up later</>,
  sv_SE: <>Lägg till senare</>,
});

export const Trans = {
  Title,
  Paragraph1,
  SubParagraph1,
  AddPasskey,
  SetupLater,
};
