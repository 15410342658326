import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { queryKey as patientConsentsQueryKey } from "render/hooks/api/queries/usePatientConsentsQuery";
import { queryKey as validPatientConsentsQueryKey } from "render/hooks/api/queries/useValidPatientConsentsQuery";

type OptOutPayload = {
  documentUri: string | undefined;
  consentMetadataId: string | undefined;
};

function mutationFunction(
  apiClient: APIClient,
  patientId: string,
  payload: OptOutPayload
) {
  const { documentUri, consentMetadataId } = payload;
  if (documentUri == null) {
    return Promise.reject(
      new Error("No documentUri provided for the opt out consent mutation ")
    );
  }
  return apiClient.consent.optOutFromConsentFor({
    patientId,
    body: {
      documentUri,
      consentMetadataId,
      consentSignature: {
        $type: "login",
      },
    },
  }).result;
}

export function useOptOutConsentMutation() {
  const queryClient = useQueryClient();
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useMutation({
    mutationFn: (payload: OptOutPayload) =>
      mutationFunction(apiClient, patientId, payload),
    onSuccess: () => {
      const currentPatientConsentsQueryKey = patientConsentsQueryKey(patientId);
      const currentPatientValidConsentQueryKey =
        validPatientConsentsQueryKey(patientId);
      return queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === currentPatientConsentsQueryKey[0] &&
            query.queryKey[1] === currentPatientConsentsQueryKey[1]) ||
          (query.queryKey[0] === currentPatientValidConsentQueryKey[0] &&
            query.queryKey[1] === currentPatientValidConsentQueryKey[1]),
      });
    },
  });
}
