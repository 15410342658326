import { FreeText } from "render/ui/presentation/FreeText";
import { DoctorCredentials } from "render/ui/presentation/doctor/DoctorCredentials";
import { ActionButton } from "render/ui/trigger/ActionButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface SummaryProps {
  text: string;
  doctorURI?: string | null;
  onClick?: () => void;
}

export function Summary({ doctorURI, text, onClick }: SummaryProps) {
  return (
    <div className={styles.Summary}>
      <div className={styles.latestSummary}>
        <FreeText text={text} />
        {onClick ? (
          <ActionButton onClick={onClick}>
            <Trans.Button />
          </ActionButton>
        ) : undefined}
      </div>
      {doctorURI && <DoctorCredentials doctorURI={doctorURI} />}
    </div>
  );
}
