import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Ankle pressure</>,
  sv_SE: <>Ankeltryck</>,
});

const MetricUnit = localize({
  en_GB: <>mmHg</>,
  sv_SE: <>mmHg</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        We measure your ankle pressure because it tells us whether you are
        developing stiffness and narrowing in the arteries that supply your
        legs. This is further done by working out your ankle brachial index
        (ABI) by dividing your ankle’s blood pressure by your arm’s highest
        blood pressure.
      </>
    ),
    sv_SE: (
      <>
        Vi mäter ditt ankeltryck för att kontrollera om du utvecklar stelhet
        eller förträngning i blodkärlen som förser dina ben med syrerikt blod.
        Vi kan bedöma det genom att beräkna ditt Ankle Brachial Index (ABI)
        genom att dela ditt ankeltryck med din arms högsta blodtryck.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        An ankle pressure can only be considered high when comparing it to your
        arm pressure which leads to an increased ABI. A high result can be
        caused by stiffness in the walls of blood vessels, which can be caused
        by diabetes and by inflammatory conditions such as rheumatoid arthritis.
      </>
    ),
    sv_SE: (
      <>
        Ett ankeltryck kan endast betraktas som högt när det jämförs med ditt
        armtryck, vilket leder till ett ökat ABI. Ett högt resultat kan orsakas
        av stelhet i blodkärlens väggar, vilket kan bero på diabetes och
        inflammatoriska tillstånd som reumatisk artrit.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        An ankle pressure can only be considered low when comparing it to your
        arm pressure which leads to a decreased ABI. A low result can be caused
        by narrowing of the arteries in your lower limbs. This is usually caused
        by cholesterol deposits building up in the walls of the arteries.
        Narrowing decreases the amount of blood that can flow to the limbs,
        which can starve the tissues of nutrients and oxygen. A very low ankle
        pressure in combination with signs of ischemia such as pain, pallor and
        paraesthesia need to be acutely managed.
      </>
    ),
    sv_SE: (
      <>
        Ett ankeltryck kan endast betraktas som lågt när det jämförs med ditt
        armtryck, vilket leder till ett minskat ABI. Ett lågt resultat kan bero
        på förträngning av artärerna i dina ben. Detta orsakas vanligtvis av
        kolesterolavlagringar som byggs upp i blodkärlens väggar. Förträngningen
        minskar mängden blod som kan flöda till benen, vilket kan leda till
        närings- och syrebrist till vävnader. Ett mycket lågt ankeltryck i
        kombination med tecken på ischemi såsom smärta, blekhet och parestesi
        måste hanteras akut.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Our arteries and veins accumulate damage that can cause stiffening and
        narrowing as we age, also known as peripheral arterial disease. Keeping
        an eye on your ankle pressure through calculating ABI over time tells
        you how quickly this is happening. The good news is that you can slow
        this process down by doing regular cardiovascular exercise, taking care
        to eat a diet rich in healthy fats, fruit and vegetables, and low GI
        carbohydrates.
      </>
    ),
    sv_SE: (
      <>
        Våra artärer och vener samlar på sig skador som kan orsaka stelhet och
        förträngning när vi åldras, även känd som perifer kärlsjukdom. Att hålla
        koll på ditt ankeltryck och beräkna ABI över tid berättar hur snabbt
        detta sker. Den goda nyheten är att du kan sakta ner denna process genom
        att regelbundet utföra konditionsträning, äta en kost rik på hälsosamma
        fetter, frukt och grönsaker samt långsamma kolhydrater.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        Ankle pressure should always be assessed in correlation to arm blood
        pressure and using ABI to assess vascular health. However, ankle
        pressure should not be lower than 70 mmHg. By maintaining a normal ABI,
        it is very unlikely that there is significant narrowing or stiffening
        affecting the blood vessels to the legs.
      </>
    ),
    sv_SE: (
      <>
        Ankeltrycket bör alltid bedömas i förhållande till armblodtrycket och
        användning av ABI för att bedöma kärlhälsan. Dock bör ankeltrycket inte
        vara lägre än 70 mmHg. Genom att upprätthålla ett normalt ABI är det
        mycket osannolikt att det finns en betydande förträngning eller stelhet
        som påverkar blodkärlen i benen.
      </>
    ),
  }),
};

export const Trans = { MetricName, MetricUnit, FAQ };
