import { useEffect, useState } from "react";

interface RequiredProps {}

interface AnimationDelayerProps extends RequiredProps {
  defaultDelay: number;
  initialDelay: number;
  isActive: boolean;
  children: (props: { delay: number; isActive: boolean }) => JSX.Element;
}

export function AnimationDelayer({
  children,
  defaultDelay,
  initialDelay,
  isActive,
}: AnimationDelayerProps) {
  const [delay, setDelay] = useState(isActive ? initialDelay : defaultDelay);
  useEffect(() => {
    if (!isActive && delay !== defaultDelay) {
      setDelay(defaultDelay);
    }
  }, [delay, isActive, defaultDelay]);

  return children({ delay, isActive });
}
