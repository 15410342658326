export enum COLORS {
  RUBBER_BLACK = "#263032",
  DARK_STEEL_GREY = "#3a4c50",
  DARK_STEEL_GREYER = "#4c6a70",
  ALUMINUM_GREY = "#e3e5e5",
  PORCELAIN_WHITE = "#fbfbfa",
  INTELLIGENT_BLUE = "#019dc9",
  OPTIMISTIC_RED = "#f96452",
  LIGHT_INTELLIGENT_BLUE = "#e2eff3",
  DARK_INTELLIGENT_BLUE = "#0274a2",
  STEEL_GREY = "#9ebcc3",
  LIGHT_STEEL_GREY = "#c5d9dd",
  LIGHT_OPTIMISTIC_RED = "#faedeb",
  DARK_OPTIMISTIC_RED = "#c02c1a",
  LIGHT_PLAIN_ORANGE = "#fff5e5",
  DARK_PLAIN_ORANGE = "#986416",
  STEEL_GREY_200 = "#d7e8eb",
  STEEL_GREY_800 = "#415a60",
  ALERT_ORANGE_100 = "#ffdfb0",
  ALERT_ORANGE_900 = "#c44a0e",
  INTELLIGENT_BLUE_50 = "#e2eff3",
  INTELLIGENT_BLUE_100_40 = "rgba(180, 230, 239, 0.4)",
  INTELLIGENT_BLUE_200 = "#84D5E6",
  ALERT_ORANGE = "#FFCB7C",
  OPTIMISTIC_RED_200 = "#F6A29D",
}
