import { classNames } from "@pomle/classnames";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { AnimationTime } from "render/views/AssignmentView/utils/animationConstants";
import { ReactComponent as DoneIcon } from "../../assets/done.svg";
import styles from "./styles.module.sass";

interface ProgressDayCheckBoxProps {
  day: DateTime;
  hidden: boolean;
  disabled: boolean;
  onClick?: (day: DateTime) => void;
  status: "completed" | "empty";
  delay: number;
}

export function ProgressDayCheckBox({
  day,
  hidden,
  disabled,
  onClick,
  status,
  delay,
}: ProgressDayCheckBoxProps) {
  const label = day.weekdayShort.slice(0, 1);
  const onButtonClick = useCallback(() => onClick?.(day), [day, onClick]);
  const isComplete = useMemo(() => status === "completed", [status]);

  return (
    <div
      className={classNames(styles.ProgressDayCheckBox, {
        [styles.hidden]: hidden,
      })}
    >
      <span>{label}</span>
      <button
        style={{
          animationDelay: `${delay}s`,
          animationDuration: `${AnimationTime.CheckboxAnimation}s`,
        }}
        disabled={disabled}
        onClick={onButtonClick}
        className={classNames({
          [styles.completed]: isComplete,
          [styles.empty]: !isComplete,
        })}
      >
        {isComplete && (
          <div
            className={styles.icon}
            style={{
              animationDelay: `${delay}s`,
              animationDuration: `${AnimationTime.CheckboxAnimation}s`,
            }}
          >
            <DoneIcon />
          </div>
        )}
      </button>
    </div>
  );
}
