import { Event, Action } from "render/hooks/useTracking";

const category = "Invite_Codes";

export const discounTokenEvent = {
  copyToClipboardClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "copy_to_clipboard",
    };
  },
};
