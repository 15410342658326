import { localize } from "render/context/InternationalizationContext";

export const Label = {
  Cellphone: localize({
    en_GB: "Mobile number",
    sv_SE: "Mobil",
  }),
  Name: localize({
    en_GB: "Name",
    sv_SE: "Namn",
  }),
  Email: localize({
    en_GB: "Email",
    sv_SE: "Email",
  }),
  Language: localize({
    en_GB: "Preferred Language",
    sv_SE: "Önskat språk",
  }),
  PersonalNumber: localize({
    en_GB: "Personal ID Number",
    sv_SE: "Personnummer",
  }),
  DateOfBirth: localize({
    en_GB: "Date of Birth",
    sv_SE: "Födelsedatum",
  }),
  Location: localize({
    en_GB: "Your Location",
    sv_SE: "Din plats",
  }),
  Measurements: localize({
    en_GB: "Unit of measurement",
    sv_SE: "Måttenhet",
  }),
};

export const Country = {
  Sweden: localize({
    en_GB: "Sweden",
    sv_SE: "Sverige",
  }),
  UK: localize({
    en_GB: "United Kingdom",
    sv_SE: "Storbritannien",
  }),
};

export const PlaceHolder = {
  AddName: localize({
    en_GB: "Add Name",
    sv_SE: "Lägg till namn",
  }),
  AddPhone: localize({
    en_GB: "Add Phone Number",
    sv_SE: "Lägg till telefonnummer",
  }),
  AddEmail: localize({
    en_GB: "Add email",
    sv_SE: "Lägg till email",
  }),
};

export const Error = {
  FailedToSetLanguage: localize({
    en_GB: "An error occurred saving preferred language",
    sv_SE: "Ett fel uppstod när språk skulle sparas",
  }),
  FailedToSaveInformation: localize({
    en_GB: "An error occurred saving your information",
    sv_SE: "Ett fel uppstod när din information sparades",
  }),
};

export const Trans = { Error, Label, PlaceHolder, Country };
