import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { ReactComponent as NekoLogo } from "./assets/logo.svg";
import styles from "./styles.module.sass";

export function LogoView() {
  return (
    <FullScreenPageLayout>
      <div className={styles.LogoView}>
        <NekoLogo className={styles.logo} />
      </div>
    </FullScreenPageLayout>
  );
}
