import * as THREE from "three";

type PointGeometry = {
  position: Float32Array;
  color: Float32Array;
};

export function parsePointCloud(buffer: ArrayBuffer): Promise<PointGeometry> {
  return new Promise((resolve) => {
    const worker = new Worker(
      new URL("./pcd-loader.worker.ts", import.meta.url)
    );
    worker.postMessage(buffer);

    worker.addEventListener("message", (event: { data: PointGeometry }) => {
      resolve(event.data);
    });
  });
}

function createBufferGeometry(positions: Float32Array, colors: Float32Array) {
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
  if (colors.length) {
    geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
  }

  return geometry;
}

export function createPoints(geo: PointGeometry) {
  const geometry = createBufferGeometry(geo.position, geo.color);

  const material = new THREE.PointsMaterial({ color: 0xff0000 });
  const points = new THREE.Points(geometry, material);
  return points;
}
