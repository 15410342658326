import { localize } from "render/context/InternationalizationContext";

const Name = localize<{ name: string }>({
  en_GB: ({ name }) => <>Dr {name}</>,
  sv_SE: ({ name }) => <>Dr {name}</>,
});

const Subtitle = localize({
  en_GB: <>and your Neko Medical Team</>,
  sv_SE: <>och ditt Neko Medical Team</>,
});

export const Trans = {
  Name,
  Subtitle,
};
