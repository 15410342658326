import { Locales } from "render/context/InternationalizationContext";

export function language2Locale(lang: string) {
  return Locales.find((loc) => {
    if (loc.startsWith(lang)) {
      return loc;
    }
    return undefined;
  });
}
