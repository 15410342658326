import { localize } from "render/context/InternationalizationContext";

export const Validation = {
  FirstName: {
    Required: localize({
      en_GB: "First name is required",
      sv_SE: "Förnamn krävs",
    }),
  },
  DisplayName: {
    Required: localize({
      en_GB: "Preferred name is required",
      sv_SE: "Tilltalsnamn krävs",
    }),
  },
  LastName: {
    Required: localize({
      en_GB: "Last name is required",
      sv_SE: "Efternamn krävs",
    }),
  },
};

const Title = localize({
  en_GB: "Name",
  sv_SE: "Namn",
});

const Label = {
  DisplayName: localize({
    en_GB: "Preferred name",
    sv_SE: "Tilltalsnamn",
  }),
  FirstName: localize({
    en_GB: "First Name/s",
    sv_SE: "Förnamn",
  }),
  LastName: localize({
    en_GB: "Last name",
    sv_SE: "Efternamn",
  }),
};

const Actions = {
  Save: localize({
    en_GB: "Save",
    sv_SE: "Spara",
  }),
  Saving: localize({
    en_GB: "Saving...",
    sv_SE: "Sparar...",
  }),
};

export const Trans = {
  Title,
  Label,
  Validation,
  Actions,
};
