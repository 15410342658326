import { useQueryParams } from "@pomle/react-router-paths";
import { queries } from "render/routes/paths";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import {
  useBMIValues,
  useNumOfBMIScans,
} from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBMI/useBMIValues";
import { Trans } from "./trans";

export function MetricBMIDetails() {
  const [params, setParams] = useQueryParams(queries.metric);
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });
  const numOfScans = useNumOfBMIScans();
  const scanNum = params.scan[0] || numOfScans;
  const values = useBMIValues({ scanNum });

  if (values == null || values === "loading") {
    return null;
  }

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
    />
  );
}
