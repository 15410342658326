import { HbA1c } from "@cur8/health-risks-calc";
import { PathLink } from "@pomle/react-router-paths";
import { calcAge } from "lib/age";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { paths } from "render/routes/paths";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useHba1cMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricHBA1C() {
  const { data: patient, isLoading: isPatientLoading } = usePatientQuery();
  const { data, isLoading: isDataLoading } = useHba1cMetric();

  if (isPatientLoading || isDataLoading) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!data || !patient) {
    return null;
  }

  const { cur, prev } = getMetricHistory(data);
  const age = patient.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;
  const riskRanges = HbA1c.rangesFor({ age });
  const metricRating = cur && riskRanges.findRisk(cur.unit);
  const value = cur?.unit["mmol/mol"];
  const prevValue = prev?.unit["mmol/mol"];

  return (
    <PathLink to={paths.hbA1cMetric.url({})}>
      <Metric
        label={<Trans.MetricName />}
        description={<Trans.MetricLabel />}
        metricRating={metricRating}
        value={value}
        previousValue={prevValue}
      />
    </PathLink>
  );
}
