import { createContext, PropsWithChildren, useContext } from "react";
import { Config } from "auth/config/getConfig";

const Context = createContext<Config | null>(null);

export function ConfigContext({
  children,
  config,
}: PropsWithChildren<{ config: Config }>) {
  return <Context.Provider value={config}>{children}</Context.Provider>;
}

export function useConfig() {
  const config = useContext(Context);
  if (!config) {
    throw new Error("useConfig without ConfigContext");
  }
  return config;
}
