import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

const queryKey = (patientId: string, visitId: string | undefined) =>
  ["scan-booked-asset-details", patientId, visitId] as const;

const queryFn = (
  apiClient: APIClient,
  patientId: string,
  visitId: string | undefined
) => {
  if (visitId === undefined) {
    return Promise.reject(new Error("Invalid visitId"));
  }

  return apiClient.visit.fetchScanBookedAssetDetails({
    patientId: patientId,
    visitId: visitId,
  }).result;
};

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useScanBookedAssetDetailsQuery<T = Body>(
  visitId: string | undefined,
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    queryFn: () => queryFn(apiClient, patientId, visitId),
    queryKey: queryKey(patientId, visitId),
    staleTime: Infinity,
    ...options,
  });
}
