import { ChoiceQuestion, TextQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum CardiovascularEnum {
  Yes = "Yes",
  No = "No",
}

export const Cardiovascular = new ChoiceQuestion(
  <Trans.Cardiovascular.Question />,
  [
    [<Trans.Cardiovascular.No />, CardiovascularEnum.No],
    [<Trans.Cardiovascular.Yes />, CardiovascularEnum.Yes],
  ]
);

export const CardiovascularDetailsQuestion = new TextQuestion(
  <Trans.Cardiovascular.Details />
);
