import { useMemo } from "react";

interface BackgroundStripesProps {
  height: string;
  stripeCount: number;
}

export function BackgroundStripes({
  height,
  stripeCount,
}: BackgroundStripesProps) {
  const stroke = "#fbfbfa";
  const viewBox = 100;

  const stripes = useMemo(
    () =>
      Array.from({ length: stripeCount }).map((_, index) => ({
        x: 0,
        y: (index * viewBox) / (stripeCount - 1),
      })),
    [stripeCount]
  );

  return (
    <svg
      overflow="visible"
      width="100%"
      height={height}
      xmlSpace="preserve"
      style={{ display: "block" }}
    >
      {stripes.map(({ y }, index) => (
        <line
          key={y}
          x1="0"
          y1={`${y}%`}
          x2="100%"
          y2={`${y}%`}
          stroke={stroke}
          strokeWidth="1px"
        />
      ))}
    </svg>
  );
}
