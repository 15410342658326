import { Typography } from "@cur8/maneki";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as DownloadIcon } from "assets/icons/24x24/24x24_download.svg";
import { ReactComponent as ShareIcon } from "assets/icons/24x24/24x24_share.svg";
import { useCallback, useState } from "react";
import {
  SeverityLevel,
  useAppInsights,
} from "render/context/AppInsightsContext";
import { useScanBookedAssetDetailsQuery } from "render/hooks/api/queries/useScanBookedAssetDetailsQuery";
import { useScanBookedAssetQuery } from "render/hooks/api/queries/useScanBookedAssetQuery";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { ActionButton } from "render/ui/trigger/ActionButton";
import ScanBookedAsset from "render/views/Report/AppointmentDataView/ShareBookingView/components/ScanBookedAsset/ScanBookedAsset";
import { shareBookingEvent } from "render/views/Report/AppointmentDataView/ShareBookingView/tracking";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface ShareBookingViewProps {
  slotId: string;
  visitId: string;
}

export function ShareBookingView({ visitId, slotId }: ShareBookingViewProps) {
  const appInsights = useAppInsights();
  const { trackEvent } = useTracking();

  const appointment = useNav(paths.appointment);

  const shareableAssetDetailsQuery = useScanBookedAssetDetailsQuery(visitId);
  const shareableAssetQuery = useScanBookedAssetQuery(visitId);

  const nativeShareSupported = !!navigator.share;

  const handleNativeShare = useCallback(async () => {
    if (!shareableAssetQuery.data) {
      return;
    }

    try {
      await navigator.share({
        files: [
          new File(
            [shareableAssetQuery.data],
            "Neko Health - Scan Booked.png",
            {
              type: "image/png",
            }
          ),
        ],
      });
    } catch (error) {
      if (error instanceof Error) {
        appInsights.trackException({
          exception: error,
          properties: { description: "Error sharing the image" },
          severityLevel: SeverityLevel.Error,
        });
      }
    }
  }, [shareableAssetQuery.data, appInsights]);

  const handleFallbackShare = useCallback(async () => {
    if (!shareableAssetQuery.data) {
      return;
    }

    const objectUrl = URL.createObjectURL(shareableAssetQuery.data);

    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = "Neko Health - Scan Booked.png";
    a.click();
  }, [shareableAssetQuery.data]);

  const handleShare = useCallback(async () => {
    trackEvent(shareBookingEvent.shareClick());

    if (!shareableAssetQuery.data) {
      return;
    }

    if (nativeShareSupported) {
      await handleNativeShare();
    } else {
      await handleFallbackShare();
    }
  }, [
    shareableAssetQuery.data,
    nativeShareSupported,
    handleNativeShare,
    handleFallbackShare,
    trackEvent,
  ]);

  const handleContinue = useCallback(() => {
    trackEvent(shareBookingEvent.continueClick());

    appointment.go({ slotId: slotId });
  }, [appointment, slotId, trackEvent]);

  const [htmlAssetReady, setHtmlAssetReady] = useState(false);

  const handleAssetReady = () => {
    setHtmlAssetReady(true);
  };

  const buttonsDisabled = !htmlAssetReady || !shareableAssetQuery.data;

  return (
    <FullScreenPageLayout disableBackground>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            {shareableAssetDetailsQuery.data && (
              <ScanBookedAsset
                name={shareableAssetDetailsQuery.data.parameters.name}
                date={shareableAssetDetailsQuery.data.parameters.date}
                scan={shareableAssetDetailsQuery.data.parameters.scan}
                siteName={shareableAssetDetailsQuery.data.parameters.siteName}
                onReady={handleAssetReady}
              />
            )}
            <div className={styles.loader} data-loading={!htmlAssetReady}>
              <Skeleton />
            </div>
          </div>
          <div className={styles.buttons}>
            <ActionButton
              hideIcon
              onClick={handleShare}
              disabled={buttonsDisabled}
            >
              <Typography variant="label-m">
                <Trans.Share />
              </Typography>
              {nativeShareSupported ? <ShareIcon /> : <DownloadIcon />}
            </ActionButton>

            <ActionButton
              hideIcon
              variant="suggestion"
              onClick={handleContinue}
              disabled={buttonsDisabled}
            >
              <div className={styles.fullWidth}>
                <Typography variant="label-m">
                  <Trans.Continue />
                </Typography>
              </div>
            </ActionButton>
          </div>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
