import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>ABI</>,
  sv_SE: <>ABI</>,
});

const FAQ = {
  Why: localize({
    en_GB: (
      <>
        We measure your Ankle Brachial Index (ABI) because it tells us whether
        you are developing stiffness and narrowing in the arteries that supply
        your arms and legs. We work out your ABI by dividing your ankle’s blood
        pressure by your arm's blood pressure.
      </>
    ),
    sv_SE: (
      <>
        Vi mäter ditt Ankle Brachial Index (ABI) eftersom det ger oss en
        uppfattning om graden av åderförkalkning i dina blodkärl som försörjer
        benen med syrerikt blod. Åderförkalkning är en inlagring av fett i
        blodkärlsväggar som leder till stelare och trängre blodkärl över tid. Vi
        räknar ut ditt ABI genom att dividera blodtrycket i fotleden med det
        högsta blodtrycket i armen.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        A high result (greater than 1.4) can be caused by stiffness in the walls
        of blood vessels, which can be caused by diabetes, and by inflammatory
        conditions such as rheumatoid arthritis. If the ABI is very high, then
        this might mean that the blood vessels is too stiff for the blood
        pressure machine to compress.
      </>
    ),
    sv_SE: (
      <>
        Ett högt resultat (högre än 1,4) kan orsakas av stelhet i blodkärlens
        väggar som oftast ses i samband med svårare diabetes och olika
        inflammatoriska tillstånd som reumatisk sjukdom.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low result (below 0.9) can be caused by narrowing of the arteries in
        your lower limbs. This is usually caused by cholesterol deposits
        building up in the walls of the arteries. Narrowing decreases the amount
        of blood that can flow to the limbs, which can starve the tissues of
        nutrients and oxygen.
      </>
    ),
    sv_SE: (
      <>
        Ett lågt resultat (lägre än 0,9) talar för en kärlsjuka som kallas för
        perifer artärsjukdom.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Our arteries and veins accumulate damage that can cause stiffening and
        narrowing as we age, also known as peripheral arterial disease. Keeping
        an eye on your ABI over time tells you how quickly this is happening.
        The good news is that you can slow this process down by doing regular
        cardiovascular exercise, taking care to eat a diet rich in healthy fats,
        fruit and vegetables, and low GI carbohydrates that release energy
        slowly.
      </>
    ),
    sv_SE: (
      <>
        Våra artärer och vener kan samla på sig skador som kan orsaka stelhet
        och förträngning när vi åldras. Genom att följa utvecklingen av ditt ABI
        kan du se hur detta utvecklas. Den goda nyheten är att du kan bromsa
        denna process genom att träna regelbundet, äta en kost rik på nyttiga
        omättade fetter och avstå rökning.
      </>
    ),
  }),
  Aim: localize({
    en_GB: (
      <>
        Ideally your ABI should be between 0.9 and 1.4. This means that it is
        very unlikely that there is significant narrowing or stiffening
        affecting the blood vessels to the arms and legs.
      </>
    ),
    sv_SE: (
      <>
        Helst bör ditt ABI vara mellan 0,9 och 1,4. Detta innebär att det är
        mycket osannolikt att det finns en betydande förträngning eller stelning
        som påverkar blodkärlen till armar och ben.
      </>
    ),
  }),
};

export const Trans = { MetricName, FAQ };
