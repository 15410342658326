import { type ReactNode } from "react";
import styles from "./styles.module.sass";

type CardProps = {
  children?: ReactNode;
  elevation?: 1;
  onClick?: () => void;
};

export function Card({ children, elevation, onClick }: CardProps) {
  const Component = onClick ? "button" : "div";

  return (
    <Component
      className={styles.card}
      data-elevation={elevation}
      onClick={onClick}
    >
      {children}
    </Component>
  );
}
