import { Weight } from "@cur8/measurements";
import { Patient, UnitSystem } from "@cur8/rich-entity";
import { toStone } from "lib/units/converters/toStone";
import { Trans } from "./trans";
import { ReactNode } from "react";

export function weightValue({
  patient,
  value,
}: {
  value?: Weight;
  patient: Patient;
}): [ReactNode, ReactNode][] {
  if (value == null) {
    return [];
  }
  if (patient.preferredUnitSystem === UnitSystem.Imperial) {
    const weight = toStone(value);
    return [
      [weight.stone, <Trans.Units.Stones />],
      [weight.pounds.toFixed(1), <Trans.Units.Pounds />],
    ];
  }
  if (patient.preferredUnitSystem === UnitSystem.Metric) {
    return [[value.kilograms, <Trans.Units.Kilograms />]];
  }
  return [];
}

export function changeLabel({ diff }: { diff?: Weight }) {
  if (!diff) {
    return null;
  }
  if (diff.kilograms < 0) {
    return <Trans.Decreased />;
  }
  return <Trans.Increased />;
}
