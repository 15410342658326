import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: <>Summary</>,
  sv_SE: <>Summering</>,
});

const WrittenOn = localize({
  en_GB: "Written on ",
  sv_SE: "Skriven den ",
});

const FollowUpBeingPrepared = localize<{ onClick: () => void }>({
  en_GB: ({ onClick }) => (
    <>
      Your doctor is preparing your visit summary, we will send you a text
      message as soon as it's done. In the meantime you can view your scan data{" "}
      <button onClick={onClick}>here.</button>
    </>
  ),
  sv_SE: ({ onClick }) => (
    <>
      Din läkare förbereder din besökssammanfattning, vi skickar ett sms till
      dig så fort det är klart. Under tiden kan du se dina scan data{" "}
      <button onClick={onClick}>här.</button>
    </>
  ),
});

export const Trans = {
  Title,
  WrittenOn,
  FollowUpBeingPrepared,
};
