import { useState, useEffect } from "react";

export function useCountDown(ms: number | undefined) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!ms) {
      return;
    }

    const timer = setTimeout(setReady, ms, true);

    return () => {
      setReady(false);
      clearTimeout(timer);
    };
  }, [ms]);

  return ready;
}
