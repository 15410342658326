import { DateTime } from "luxon";
import { useCallback } from "react";
import { Step } from "../../shared";
import { ReactComponent as MoonIcon } from "./assets/moon.svg";
import { ReactComponent as SunIcon } from "./assets/sun.svg";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface OverviewStepProps {
  onGoToStepClick: (step: Step) => void;
  day: DateTime;
  data: {
    sysDay: string;
    diaDay: string;
    sysNight: string;
    diaNight: string;
  };
}

export function OverviewStep({
  data,
  day,
  onGoToStepClick,
}: OverviewStepProps) {
  const { diaDay, diaNight, sysDay, sysNight } = data;

  const goToStep = useCallback(
    (target: Step) => {
      return () => onGoToStepClick(target);
    },
    [onGoToStepClick]
  );

  return (
    <div className={styles.OverviewStep}>
      <div className={styles.day}>{day.weekdayLong}</div>
      <div className={styles.item}>
        <div className={styles.data}>
          <button disabled onClick={goToStep(Step.EditSystolicDay)}>
            {sysDay}
          </button>
          <div className={styles.sepparator}>/</div>
          <button disabled onClick={goToStep(Step.EditDiastolicDay)}>
            {diaDay}
          </button>
        </div>
        <div className={styles.time}>
          <SunIcon />
          <Trans.DayTimeAvg />
        </div>
      </div>

      <div className={styles.item}>
        <div className={styles.data}>
          <button disabled onClick={goToStep(Step.EditSystolicNight)}>
            {sysNight}
          </button>
          <div className={styles.sepparator}>/</div>
          <button disabled onClick={goToStep(Step.EditDiastolicNight)}>
            {diaNight}
          </button>
        </div>
        <div className={styles.time}>
          <MoonIcon />
          <Trans.NightTimeAvg />
        </div>
      </div>
    </div>
  );
}
