import { createTimer } from "lib/timer";
import { useEffect, useMemo } from "react";
import * as THREE from "three";
import { useCountDown } from "../../../../hooks/useCountDown";
import { lerp, sawtooth } from "../../../../lib/anim";
import { createTween, createVectorTween } from "../../../../lib/tween";
import { AvatarPoints } from "../../objects/AvatarPointCloud";

const ANTICIPATED_POINT_COUNT = 60000;
const POINT_SPRING_SLOW = {
  stiffness: 15,
  mass: 1000,
  friction: 35,
  precision: 0.000001,
};

const POINT_SPRING_FAST = {
  stiffness: 40,
  mass: 100,
  friction: 25,
  precision: 0.000001,
};

const POINT_SIZE_NORMAL = 2;

const FALLOFF_HIDE = new THREE.Vector3(0, 0, -2000);
const FALLOFF_SHOW = new THREE.Vector3(0, 0, 1400);

type Mix = {
  front: 0 | 1;
  back: 0 | 1;
  scatter: 0 | 1;
  heart: 0 | 1;
};

interface AvatarPointCloudProps {
  podium: THREE.Object3D;
  pointTexture: THREE.Texture;
  pointBuffers?: {
    front: THREE.Float32BufferAttribute;
  };
}

export function AvatarPointCloud({
  podium,
  pointTexture,
  pointBuffers,
}: AvatarPointCloudProps) {
  const fadeReady = useCountDown(pointTexture && 500);

  const constructReady = useCountDown(pointTexture && pointBuffers && 5000);

  const tweens = useMemo(() => {
    return {
      pointSize: createTween(
        { value: 0 },
        { stiffness: 15, mass: 150, friction: 35 }
      ),

      falloff: createVectorTween(FALLOFF_HIDE.clone(), {
        stiffness: 15,
        mass: 20,
        friction: 10,
      }),

      mix: createTween<Mix>(
        { front: 0, back: 0, scatter: 1, heart: 0 },
        POINT_SPRING_SLOW
      ),
    };
  }, []);

  const worldPCD = useMemo(() => {
    const points = new AvatarPoints({
      pointTexture,
      pointCount: ANTICIPATED_POINT_COUNT,
    });

    const material = points.material;
    material.color.setStyle("#075C7E", THREE.LinearSRGBColorSpace);
    material.points.mix.position = 0;
    material.points.size = 0;
    material.wave.direction.y = 1;
    material.falloff.center.copy(FALLOFF_HIDE);
    material.falloff.near = 3000;
    material.falloff.far = 10000;

    return points;
  }, [pointTexture]);

  useEffect(() => {
    if (fadeReady) {
      tweens.pointSize.to({ value: POINT_SIZE_NORMAL });
      tweens.falloff.to(FALLOFF_SHOW);
    }
  }, [tweens, fadeReady]);

  useEffect(() => {
    if (!pointBuffers) {
      return;
    }

    worldPCD.geometry.setPointPositions("front", pointBuffers.front);
    worldPCD.geometry.setPointPositions("back", pointBuffers.front);
    worldPCD.geometry.setPointPositions("heart", pointBuffers.front);
  }, [worldPCD, pointBuffers]);

  useEffect(() => {
    if (!constructReady) {
      tweens.mix.to({ front: 0, back: 0, scatter: 1, heart: 0 });
      return;
    }

    tweens.pointSize.reconfigure(POINT_SPRING_SLOW);
    tweens.mix.reconfigure(POINT_SPRING_FAST);

    tweens.pointSize.to({ value: POINT_SIZE_NORMAL });
    tweens.mix.to({
      front: 1,
      back: 0,
      scatter: 0,
      heart: 0,
    });
  }, [constructReady, tweens]);

  useEffect(() => {
    tweens.mix.reconfigure(POINT_SPRING_SLOW);
  }, [constructReady, tweens]);

  useEffect(() => {
    podium.add(worldPCD);

    return () => {
      podium.remove(worldPCD);
      worldPCD.geometry.dispose();
      worldPCD.material.dispose();
    };
  }, [podium, worldPCD]);

  useEffect(() => {
    let acc = 0;

    const materials = [worldPCD.material];

    return createTimer((time) => {
      acc += time;

      const s = acc / 1000;

      tweens.mix.update(time / 1000);
      tweens.falloff.update(time / 1000);
      tweens.pointSize.update(time / 1000);

      const mix = tweens.mix.value;
      const falloff = tweens.falloff.value;
      const pointSize = tweens.pointSize.value;

      for (const material of materials) {
        material.points.size = pointSize.value;
        material.points.mix.scatter = mix.scatter;
        material.points.mix.front = mix.front;
        material.points.mix.back = mix.back;
        material.points.mix.heart = mix.heart;

        material.falloff.center.copy(falloff);

        material.shimmer.origin = lerp(2500, -500, sawtooth(s * 0.5, 3));
        material.shimmer.width = 800; //lerp(200, 1000, (Math.sin(s * 1.4) + 1) / 2);

        material.wave.progression = s * 2;
        material.time.value = s;
      }
    });
  }, [tweens, worldPCD]);

  return null;
}
